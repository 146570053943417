<template>
  <CarouselFrame class="music-carousel" :title="title" :carousel="$refs.carousel">
    <TileCarousel ref="carousel" :items="items" :groupSize="groupSize" :groupRows="2">
      <MusicTile slot-scope="item" :artist="item.item" />
    </TileCarousel>
  </CarouselFrame>
</template>

<script>
import TileCarousel from '../TileCarousel'
import CarouselFrame from '../CarouselFrame'
import MusicTile from './MusicTile'
import { keyed } from '../../utils'

export default {
  name: 'MusicCarousel',
  components: { TileCarousel, CarouselFrame, MusicTile },
  props: {
    title: {
      type: String,
      default: 'Music'
    },
    artists: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      items: [],
      groupSize: 8,
      groupRows: 2
    }
  },
  watch: {
    artists: {
      immediate: true,
      handler: function () {
        // Delay setting the items so the frame component recieves the
        // updated carousel ref... Not an issue elsewhere as the data is
        // loaded asynchronous.
        this.$nextTick(() => {
          this.items = keyed(this.artists)
        })
      }
    }
  }
}
</script>
