<template>
  <a class="logo" href="/" :style="elStyle">
    <svg v-if="!small" width="296" height="44" viewBox="0 0 296 44" xmlns="http://www.w3.org/2000/svg">
      <path d="M41.66 20.5H24.56C23.66 20.5 23.12 20.98 23.12 21.94V26.38C23.12 27.28 23.66 27.76 24.56 27.76H34.82C33.5 33.7 28.22 36.22 22.76 36.22C14.84 36.22 9.20004 30.52 9.20004 22C9.20004 13.96 14.6 8.26 22.76 8.26C27.68 8.26 32 10.72 33.92 14.08C34.34 14.8 34.82 15.1 35.6 15.1H41.42C42.44 15.1 42.98 14.5 42.62 13.54C40.04 5.92 32.06 0.220001 22.76 0.220001C10.1 0.220001 0.920044 9.76 0.920044 22C0.920044 34.3 9.92004 43.84 21.86 43.84C27.62 43.84 32.3601 41.74 35.3 37.72V41.62C35.3 42.52 35.78 43 36.74 43H41.66C42.56 43 43.1 42.52 43.1 41.62V27.76V21.94C43.1 20.98 42.56 20.5 41.66 20.5Z" />
      <path d="M56.9694 1H51.2694C50.3694 1 49.8294 1.54 49.8294 2.44V8.14C49.8294 9.04 50.3694 9.58 51.2694 9.58H53.8494C53.8494 12.58 52.8894 13.9 50.9094 14.32C50.1894 14.44 49.8294 14.8 49.8294 15.46V16.78C49.8294 17.5 50.2494 17.92 51.0294 17.8C55.6494 17.26 58.4094 14.26 58.4094 9.58V2.44C58.4094 1.54 57.8694 1 56.9694 1Z" />
      <path d="M83.8841 1H74.2841H67.4441C66.5441 1 66.0641 1.54 66.0641 2.44V41.62C66.0641 42.52 66.5441 43 67.4441 43H68.7641H83.8841C95.4641 43 104.164 33.82 104.164 22C104.164 10.24 95.4641 1 83.8841 1ZM83.8841 35.08H74.2841V8.92H83.8841C91.2041 8.92 96.1841 14.26 96.1841 22C96.1841 29.8 91.2041 35.08 83.8841 35.08Z" />
      <path d="M142.828 41.44L129.448 2.2C129.208 1.42 128.608 1 127.828 1H120.028C119.248 1 118.648 1.42 118.408 2.2L105.028 41.44C104.668 42.46 105.208 43 106.228 43H112.228C113.008 43 113.608 42.64 113.848 41.86L115.828 35.5H131.968L134.008 41.86C134.248 42.64 134.788 43 135.628 43H141.628C142.648 43 143.128 42.46 142.828 41.44ZM118.348 27.76L123.928 10.18L129.508 27.76H118.348Z" />
      <path d="M170.327 1H164.027C163.247 1 162.707 1.36 162.347 2.02L154.367 16.42L146.387 2.02C146.027 1.36 145.487 1 144.707 1H138.467C137.327 1 136.907 1.72 137.507 2.74L150.227 24.34V41.62C150.227 42.52 150.767 43 151.667 43H157.067C157.967 43 158.507 42.52 158.507 41.62V24.4L171.227 2.74C171.827 1.72 171.407 1 170.267 1H170.327Z" />
      <path d="M206.002 43.84C214.462 43.84 221.302 39.1 221.302 30.16V2.44C221.302 1.54 220.762 1 219.862 1H214.462C213.562 1 213.022 1.54 213.022 2.44V29.56C213.022 33.46 210.862 35.8 206.002 35.8C201.082 35.8 198.922 33.46 198.922 29.56V2.44C198.922 1.54 198.442 1 197.482 1H192.082C191.182 1 190.702 1.54 190.702 2.44V30.16C190.702 39.1 197.482 43.84 206.002 43.84Z" />
      <path d="M241.409 43.84C249.209 43.84 255.869 39.58 255.869 31.54C255.869 22.66 247.949 20.5 241.829 18.28C237.989 16.84 235.109 15.52 235.109 12.4C235.109 10 237.089 8.26 240.509 8.26C243.749 8.26 245.789 9.58 246.329 12.4C246.449 13.3 246.929 13.72 247.769 13.72H253.169C254.129 13.72 254.669 13.24 254.609 12.28C253.889 4 247.409 0.220001 240.509 0.220001C233.489 0.220001 226.829 4.36 226.829 12.4C226.829 21.34 234.209 23.74 240.269 25.84C244.229 27.28 247.589 28.54 247.589 31.54C247.589 34 245.609 35.8 241.409 35.8C237.329 35.8 234.929 34.3 234.449 31.48C234.269 30.52 233.849 30.1 232.949 30.1H227.549C226.649 30.1 226.109 30.58 226.169 31.54C226.829 39.94 233.609 43.84 241.409 43.84Z" />
      <path d="M295.179 41.44L281.799 2.2C281.559 1.42 280.959 1 280.179 1H272.379C271.599 1 270.999 1.42 270.759 2.2L257.379 41.44C257.019 42.46 257.559 43 258.579 43H264.579C265.359 43 265.959 42.64 266.199 41.86L268.179 35.5H284.319L286.359 41.86C286.599 42.64 287.139 43 287.979 43H293.979C294.999 43 295.479 42.46 295.179 41.44ZM270.699 27.76L276.279 10.18L281.859 27.76H270.699Z" />
    </svg>
    <svg v-if="small" width="24" height="19" viewBox="0 0 24 19" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.0077 8.83356L9.86895 8.83356C9.49323 8.83356 9.2678 9.04264 9.2678 9.4608V11.3948C9.2678 11.7868 9.49323 11.9959 9.86895 11.9959H14.1522C13.6011 14.5832 11.3969 15.6809 9.11751 15.6809C5.81116 15.6809 3.45664 13.1981 3.45664 9.48693C3.45664 5.98487 5.71097 3.50206 9.11751 3.50206C11.1715 3.50206 12.9749 4.57359 13.7765 6.03714C13.9518 6.35076 14.1522 6.48143 14.4778 6.48143H16.9075C17.3333 6.48143 17.5587 6.22008 17.4084 5.80193C16.3314 2.48281 13 0 9.11751 0C3.83236 0 0 4.15543 0 9.48693C0 14.8446 3.75722 19 8.74179 19C11.1464 19 13.1252 18.0853 14.3526 16.3342L14.3526 18.033C14.3526 18.425 14.5529 18.6341 14.9537 18.6341H17.0077C17.3834 18.6341 17.6088 18.425 17.6088 18.033V11.9959L17.6088 9.4608C17.6088 9.04264 17.3834 8.83356 17.0077 8.83356Z" />
      <path d="M23.3988 0.339752L21.0193 0.339752C20.6436 0.339752 20.4181 0.574966 20.4181 0.966987V3.44979C20.4181 3.84182 20.6436 4.07703 21.0193 4.07703H22.0963C22.0963 5.38377 21.6956 5.95874 20.869 6.14168C20.5684 6.19395 20.4181 6.35076 20.4181 6.63824V7.2132C20.4181 7.52682 20.5935 7.70977 20.9191 7.6575C22.8478 7.42228 24 6.11554 24 4.07703V0.966987C24 0.574966 23.7746 0.339752 23.3988 0.339752Z" />
    </svg>
  </a>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    color: {
      type: [String, Boolean],
      default: '#212121'
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    elStyle () {
      if (this.color) {
        return {
          fill: this.color
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  display: inline-block;
  fill: transparent;
}

.logo svg {
  width: 100%;
  height: auto;
  fill: inherit;
  transition: fill 0.2s;
}
</style>
