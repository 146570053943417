import { DateTime } from 'luxon'
import { get } from '../utils'
import { Api } from '../api'


function thumbnail (post, size = 'post-thumbnail') {
  return (
    get(post, `_embedded.wp:featuredmedia.0.media_details.sizes.${size}.source_url`) ||
    // If we don't have the correct size the image is probably too small.
    // So we can safely just return the full size image.
    get(post, `_embedded.wp:featuredmedia.0.media_details.sizes.full.source_url`)
  )
}

export default {
  install: (Vue) => {
    const wp = {

      api: Api,

      thumbnail (post, size = 'post-thumbnail') {
        return thumbnail(post, size)
      },

      thumbnailDimens (post) {
        return {
          width: get(post, `_embedded.wp:featuredmedia.0.media_details.width`),
          height: get(post, `_embedded.wp:featuredmedia.0.media_details.height`)
        }
      },

      image (attachment, size = 'large') {
        return (
          get(attachment, `sizes.${size}`) ||
          get(attachment, `media_details.sizes.${size}.source_url`) ||
          get(attachment, 'url') ||
          get(attachment, `media_details.sizes.full.source_url`)
        )
      },

      video (attachment) {
        return (
          get(attachment, 'url') ||
          get(attachment, 'source_url')
        )
      },

      isImage (attachment) {
        return attachment.mime_type.indexOf('image/') === 0
      },

      isVideo (attachment) {
        return attachment.mime_type.indexOf('video/') === 0
      },

      terms (post, taxonomy) {
        let terms = get(post, `_embedded.wp:term`)
        if (!terms) {
          return []
        }
        terms = [].concat(terms)
        return terms.filter(function (t) {return !taxonomy || t.taxonomy === taxonomy})
      },

      displayDate (date, format = '') {
        if (date) {
          const time = DateTime.fromISO(date.replace(' ', 'T'))
          return time.toFormat(format)
        }
      },

    /*
    * Alow existing featured images to remain or get replaced by the new one that is part of the
    * sponsor form
    */
    partnerLogo (partner) {
      if (partner.acf.logo !== undefined && partner.acf.logo !== false) {
        // console.log('logo', partner.title, partner)
        return partner.acf.logo.url
      } else if (partner.image) {
        // console.log('featured', partner.title, partner)
        return partner.image
      } else {
        return ""
      }
    },

    hasPartnerLogo (partner) {
      if (
        (partner.acf.logo !== undefined && partner.acf.logo !== false)
        ||
        (partner.image !== undefined && partner.image !== false)
      ) {
        return true
      } else {
        return false
      }
    },
    /**
     * In the case we need to look for the image imbeded into the ACF
     * then cheack the back up (original legacy part of post image)
     * as a faill back.
     * Because partners posts don't have an image (original image post)
     * as part of the json we need to fetch it manually because we
     * return '' if not found we can remove any dose image exists cheacking.
     * @size - full || post-thumbnail
     */
    partnerThumbnail (partner, size = 'full') {
      if (partner.acf.logo !== undefined && partner.acf.logo !== false) {
        return partner.acf.logo.url
      } else {
        let image = thumbnail(partner,size)
        if (image !== undefined && image !== false) {
          return image
        } else {
          return ''
        }
      }
    }
    // end of class
  }
    Vue.mixin({
      beforeCreate () {
        this.$wp = wp
      }
    })
  }
}
