import Home from './Home'
import About from './About'
import Events from './Events'
import Media from './Media'
import Champions from './Champions'
import Honourees from './Honourees'
import Sponsors from './Sponsors'
import Contact from './Contact'
import Terms from './Terms'

export default {
  home: Home,
  about: About,
  events: Events,
  media: Media,
  champions: Champions,
  honourees: Honourees,
  sponsors: Sponsors,
  contact: Contact,
  terms: Terms
}
