<template>
  <div class="youtube-video">
    <div class="embed-container">
      <iframe
        :src="embedUrl"
        allow="autoplay"
        frameborder="0"
        allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YouTubeVideo',
  props: {
    videoUrl: [String, Number],
    autoplay: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    embedUrl () {
      const qs = new URLSearchParams(Object.entries({
        autoplay: this.autoplay ? 1 : 0
      }))
      return [`https://www.youtube.com/embed/${this.getIdFromUrl(this.videoUrl)}`, qs].join('?')
    }
  },
  methods: {
    getIdFromUrl (url) {
      if (url.indexOf('http') !== 0) {
        return url
      }

      let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      let match = url.match(regExp)
      return (match && match[7].length === 11) ? match[7] : false
    }
  }
}
</script>

<style lang="scss" scoped>
.youtube-video {
  width: 100%;
}

.embed-container { position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
