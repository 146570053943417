<template>
  <article v-if="page" class="contact-page">
    <div class="container">
      <div class="row">
        <div class="content">
          <div class="headline" v-html="page.acf.headline"></div>

          <div class="emails">
            <a
              v-for="email in page.acf.emails"
              :key="email.email"
              :href="`mailto:${email.email}`"
              target="_blank"
              v-html="email.email.replace('@', '@<br/>')"></a>
          </div>

          <div class="body" v-html="page.acf.content"></div>
        </div>

        <ContactForm :formId="page.acf.form" :defaults="formDefaults" />
      </div>
    </div>
  </article>
</template>

<script>
import PageMixin from './PageMixin'
import ContactForm from '../components/ContactForm'

export default {
  name: 'ContactPage',
  mixins: [PageMixin],
  components: { ContactForm },
  computed: {
    formDefaults () {
      return this.$route.query
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-page {
  padding-top: 140px;
  padding-bottom: 140px;

  @include media-breakpoint-up(md) {
    padding-top: $page-margin-top;
    padding-bottom: 240px;
  }
}

.content {
  @include make-col-ready();

  @include media-breakpoint-up(md) {
    @include make-col(6);
  }

  @include media-breakpoint-up(xl) {
    @include make-col(4);
    @include make-col-offset(2);
  }
}

.contact-form {
  @include make-col-ready();
  margin-top: 64px;

  @include media-breakpoint-up(md) {
    @include make-col(6);
    margin-top: 0;
  }

  @include media-breakpoint-up(xl) {
    @include make-col(4);
  }
}

.headline {
  @include headline();
  max-width: 400px;
  margin-bottom: 40px;
}

.emails {
  @include make-row();
  max-width: 360px;
  margin-top: -2rem;
  margin-bottom: 40px;

  a {
    @include make-col-ready();
    @include make-col(6);
    margin-top: 2rem;
    line-height: 1.2;
    font-weight: 600;
    color: $sea;
    text-decoration: underline;
    transition: color 0.25s;

    &:hover {
      color: rgba($sea, 0.7);
    }
  }
}

.body {
  max-width: 300px;

  /deep/ {

    h1, h2, h3, h4 {
      margin: 2.5rem 0 0.5rem;
      font-size: 1rem;
      font-weight: 600;
    }

    a {
      font-weight: 600;
      text-decoration: underline;
      color: $sea;
    }
  }
}
</style>
