<template>
  <div id="app">
    <PageHeader
      :showLogo="showLogo"
      :menu="menus.primary"
      :socials="menus.social" />

    <div class="page-wrap" ref="page">
      <transition
        @enter="enter"
        @leave="leave"
        :css="false"
        mode="out-in"
        appear>
        <router-view :key="routeKey" />
      </transition>
    </div>

    <PageFooter
      :menu="menus.footer"
      :socials="menus.social"
      :subscribeTitle="global.subscribe_title"
      :attribution="global.attribution"
      :copyright="global.copyright"
      :foundingPartners="global.founding_partners"
      :strategicPartners="global.strategic_partners" />

    <GalleryModal
      :show="$route.query.modal === 'gallery'"
      :galleryId="$route.query.galleryId"
      :attachmentId="$route.query.attachmentId"
      :attachmentIds="$route.query.attachmentIds" />

    <YoutubeModal
      :show="$route.query.modal === 'youtube'"
      :videoUrl="$route.query.videoUrl" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TimelineLite from 'gsap/TimelineLite'
import PageHeader from './components/PageHeader'
import PageFooter from './components/PageFooter'
import GalleryModal from './components/galleries/GalleryModal'
import YoutubeModal from './components/galleries/YoutubeModal'
import { guardEvent, findParentLink, linkPath, sameRoute } from './utils'

export default {
  name: 'app',
  components: { PageHeader, PageFooter, GalleryModal, YoutubeModal },
  data () {
    return {
      pathChanged: false
    }
  },
  watch: {
    '$route': function (to, from) {
      this.pathChanged = !sameRoute(to, from)
    }
  },
  computed: {

    ...mapState(['global', 'menus']),

    showLogo () {
      return !!this.$route.path.replace('/', '')
    },

    routeKey () {
      return this.$route.path.replace(/^\/|\/$/g, '')
    }
  },
  methods: {
    leave(el, done) {
      const { page } = this.$refs
      const { height } = page.getBoundingClientRect()
      const isScrolledPast = window.scrollY > (height - window.innerHeight)

      if (!this.pathChanged) {
        return done()
      }

      // So the scroll animation isn't as long if the user is scrolled down the page
      // Otherwise set the page height to prevent the footer jumping up
      const truncateStyle = isScrolledPast ? {
        height: window.innerHeight,
        width: window.innerWidth,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end'
      } : {
        height
      }

      new TimelineLite({ onComplete: () => done() })
        .set(page, truncateStyle)
        .to(el, 0.25, { autoAlpha: 0 })
        .to(window, isScrolledPast ? 0.5 : 0, { scrollTo: { y: 0 } })
    },

    enter(el, done) {
      const { page } = this.$refs

      if (!this.pathChanged) {
        return done()
      }

      new TimelineLite({ onComplete: () => done() })
        .set(page, { clearProps: 'all' })
        .fromTo(el, 0.5, { autoAlpha: 0, y: 64 }, { autoAlpha: 1, y: 0 })
        .set(el, { clearProps: 'all' })
    },

    handleClick (e) {
      let target = findParentLink(e.target)
      let path = linkPath(target)

      if (!path) {
        return
      }

      if (path.indexOf('#') === 0) {
        e.preventDefault()
        let target = document.getElementById(path.slice(1))
        window.scrollTo({
          top: document.body.scrollTop + target.getBoundingClientRect().top - 60,
          behavior: 'smooth'
        })
        return
      }

      if (target.getAttribute('target') === '_blank') {
        return
      }

      if (guardEvent(e)) {
        this.$router.push(path)
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClick)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClick)
  }
}
</script>

<style lang="scss">
@import "./styles/main";

#app {
  width: 100vw;
  overflow-x: hidden;
}

.page-wrap {
  min-height: 100vh;
  width: 100vw;
}
</style>
