<template>
  <CarouselFrame class="gallery-carousel" :title="title" :carousel="$refs.carousel">
    <TileCarousel ref="carousel" :items="media" :groupSize="groupSize" :groupRows="groupRows" :loading="isLoading">
      <PhotoTile
        slot-scope="item"
        :attachment="item.item"
        :gallery="galleryObject"
        :aspectRatio="aspectRatio" />
    </TileCarousel>
  </CarouselFrame>
</template>

<script>
import TileCarousel from '../TileCarousel'
import CarouselFrame from '../CarouselFrame'
import PhotoTile from './PhotoTile'

export default {
  name: 'GalleryCarousel',
  components: { TileCarousel, CarouselFrame, PhotoTile },
  props: {
    title: {
      type: String,
      default: 'Photo gallery'
    },
    gallery: {
      type: [Object, Number, String],
      required: true
    },
    aspectRatio: {
      type: Number
    },
    groupSize: {
      type: Number,
      default: 3
    },
    groupRows: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      isLoading: false,
      galleryObject: {},
      media: []
    }
  },
  watch: {
    galleries: 'fetch'
  },
  methods: {
    async fetch () {
      this.isLoading = true
      this.galleryObject = await this.$store.dispatch('hydratePosts', {
        type: 'galleries',
        ids: this.gallery
      })
      this.media = this.galleryObject.acf.media
      this.isLoading = false
    }
  },
  created () {
    this.fetch()
  }
}
</script>
