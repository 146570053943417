<template>
  <a @click.stop.prevent="$emit('click', $event)" class="cursor-button">
    <slot />
    <div ref="button" class="button">
      <span class="text">
        <img class="icon" src="../assets/play.svg" alt="Play" />
        {{ text }}
      </span>
    </div>
  </a>
</template>

<script>
export default {
  name: 'CursorButton',
  props: {
    text: {
      type: String,
      default: 'WATCH THE VIDEO'
    },
    onClick: Function
  },
  methods: {
    handleMove (e) {
      const { button } = this.$refs
      const dimens = this.$el.getBoundingClientRect()
      let left = (e.pageX - window.scrollX) - dimens.left
      let top = (e.pageY - window.scrollY)  - dimens.top
      button.style.opacity = 1
      button.style.left = Math.min(Math.max(left, 0), this.$el.offsetWidth) + 'px'
      button.style.top = Math.min(Math.max(top, 0), this.$el.offsetHeight) + 'px'
    },

    /**
     * Center the button on mobile
     */
    centerButton () {
      const { button } = this.$refs
      button.style.opacity = 1
      button.style.left = '50%'
      button.style.top = '50%'
    }
  },
  mounted () {
    if (!this.$mq.md) {
      this.centerButton()
    } else {
      window.addEventListener('mousemove', this.handleMove)
    }
  },
  beforeDestroy () {
    window.removeEventListener('mousemove', this.handleMove)
  }
}
</script>

<style lang="scss" scoped>
.cursor-button {
  display: block;
}

.button {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(-50%, -50%, 0);
  padding: 1rem;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: url('../assets/no-cursor.png'), none;

  .text {
    position: relative;
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 500;
    white-space: nowrap;
    color: $body-color;
    z-index: 10;
    transform: scale(0.75);
    transition: all 0.15s;
  }

  .icon {
    display: inline-block;
    position: relative;
    top: -2px;
    margin-right: 0.5rem;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(0.75);
    transition: all 0.2s;
  }

  &:before {
    top: 6px;
    left: 6px;
    background-color: $body-color;
  }

  &:after {
    top: 0;
    left: 0;
    background-color: #fff;
  }

  &:hover {
    text-decoration: none;
  }
}

.cursor-button:hover {
  .button {
    &:before,
    &:after,
    .text {
      transform: scale(1);
    }
  }
}
</style>
