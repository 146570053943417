<template>
  <a :href="attachment.url" @click="handleClick" target="_blank" class="photo photo-tile">
    <div class="media">
      <div class="ratio" :style="ratioStyle"></div>
      <img
        v-if="thumbnail"
        :src="thumbnail"
        :alt="attachment.alt"
        class="image"
        v-preload />
      <div v-if="isVideo" class="play">
        <i class="icon-play" />
      </div>
    </div>

    <header v-if="isVideo" class="header">
      <h4 class="title" v-html="attachment.title"></h4>
    </header>
  </a>
</template>

<script>
import { get } from '../../utils'

export default {
  name: 'PhotoTile',
  props: {
    attachment: {
      type: Object,
      required: true
    },
    gallery: {
      type: Object
    },
    aspectRatio: {
      type: Number
    },
    onClick: {
      type: Function
    }
  },
  computed: {
    thumbnail () {
      if (this.$wp.isVideo(this.attachment)) {
        return this.$wp.image(get(this.attachment, 'acf.poster_image'), 'post-thumbnail')
      }
      return this.$wp.image(this.attachment, 'post-thumbnail')
    },

    ratioStyle () {
      if (this.aspectRatio) {
        return {
          paddingTop: this.aspectRatio * 100 + '%'
        }
      }
    },

    isVideo () {
      return (
        this.$wp.isVideo(this.attachment) ||
        get(this.attachment, 'acf.youtube_url')
      )
    }
  },
  methods: {
    handleClick (e) {
      if (this.onClick) {
        e.preventDefault()
        this.onClick(this.attachment)
      } else if (this.gallery) {
        e.preventDefault()
        this.$router.push({
          ...this.$route,
          query: {
            modal: 'gallery',
            galleryId: this.gallery.id,
            attachmentId: this.attachment.id
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.photo-tile {
  @include tile-hover-shadow($desert, '.image, .play', '.media');

  display: block;

  &:hover {
    .media {
      background-color: rgba(#000, 1);
    }

    .image {
      opacity: 0.75;
    }

    .icon-play {
      transform: translate3d(-50%, -50%, 0) scale(1.1);
    }
  }
}

.media {
  @include image-ratio();
  position: relative;
  margin-bottom: 1rem;
  background-color: rgba(#000, 0.1);
  transition: background 0.35s;
}

.image {
  transition: opacity 0.35s;
}

.play {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.icon-play {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 2rem;
  color: #fff;
  transition: transform 0.35s;

  @include media-breakpoint-up(md) {
    font-size: 4rem;
  }
}

.title {
  font-size: 1rem;
  font-weight: 500;

  @include media-breakpoint-up(md) {
    font-size: 1.625rem;
  }
}
</style>
