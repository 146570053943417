import urlJoin from 'url-join'

const { root, nonce } = window.wpApiSettings || {}

function generateIeAlwasyGetNewId(){
  let time = (new Date).getTime() * 10000
  return time
}
export class Api {
  static get (url, options = {}) {
    return Api.send(url, {
      method: 'get',
      ...options
    })
  }

  static post (url, options = {}) {
    return Api.send(url, {
      method: 'post',
      ...options
    })
  }

  static put (url, options = {}) {
    return Api.send(url, {
      method: 'put',
      ...options
    })
  }

  static delete (url, options = {}) {
    return Api.send(url, {
      method: 'delete',
      ...options
    })
  }

  static async send (url, options = {}) {
    let body = false
    let method = (options.method || 'get').toUpperCase()
    let headers = options.headers || {}
    url = urlJoin((root || '/wp-json/'), url)

    if (options.data) {
      body = JSON.stringify(options.data)
    } else if (options.form) {
      body = options.form
    }

    if (options.params) {
      const glue = url.indexOf('?') !== -1 ? '&' : '?'
      let qs = ''
      for (let key in options.params) {
        qs = `${qs}${key}=${options.params[key]}&`
      }
      qs = `${qs}$ieNC=${generateIeAlwasyGetNewId()}`
      url = [url, qs].join(glue)
    }

    if (nonce) {
      headers['X-WP-Nonce'] = nonce
    }

    const response = await fetch(url, {
      method,
      headers,
      ...(body ? { body } : {})
    })

    if (response.status >= 200 && response.status < 300) {
      return await response.json()
    } else {
      var error = new Error(response.statusText)
      error.response = response
      throw error
    }
  }
}
