<template>
  <div class="loader" :class="`theme-${color}`">
    <div class="dots">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    color: {
      type: String,
      default: 'grey'
    }
  }
}
</script>

<style lang="scss" scoped>
.dots {
  text-align: center;
}

.dot {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  animation: dot-delay 1.4s infinite ease-in-out both;
}

.theme-grey .dot {
  background-color: rgba(33, 33, 33, 0.35);
}

.theme-white .dot {
  background-color: rgba(255, 255, 255, 0.5);
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  margin: 0 0.5rem;
  animation-delay: -0.16s;
}

@keyframes dot-delay {
  0%, 80%, 100% { transform: scale(0) }
  40% { transform: scale(1) }
}
</style>
