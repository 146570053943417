<template>
  <nav class="header-menu">
    <div @click="close" class="backdrop"></div>

    <div class="links">
      <div
        v-for="item in menu"
        :key="item.ID"
        class="link-wrap">
        <a
          :href="item.url_relative"
          :class="linkClass(item)"
          v-html="item.title"
          @click="handleClick" />
      </div>
    </div>

    <a @click.prevent="close" class="close">
      Close
    </a>
  </nav>
</template>

<script>
import TimelineLite from 'gsap/TimelineLite'
import { Power2 } from 'gsap/EasePack'

export default {
  name: 'HeaderMenu',
  props: {
    menu: {
      type: Array,
      default: () => []
    },
    onClose: {
      type: Function,
      default: () => () => {}
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {

    toggle () {
      if (this.isOpen) {
        this.close()
      } else {
        this.open()
      }
    },

    open () {
      if (this.isOpen) {
        return
      }

      const links = [...this.$el.querySelectorAll('.link-wrap')]
      const ease = Power2.easeOut
      let linkOffset = 20 / links.length
      let xTranslate = '100%'

      if (this.$mq.md) {
        linkOffset = 64 / links.length
        xTranslate = '50%'
      }

      document.body.style.overflow = 'hidden'

      const tl = new TimelineLite()
        .set(this.$el, { display: 'block', x: xTranslate })
        .to(this.$el, 0.75, { x: '0%', ease })

      for (const idx in links) {
        tl.fromTo(links[idx], 0.75, { x: (idx + 1) * linkOffset }, { x: 0, ease }, 0)
      }

      this.isOpen = true
    },

    close () {
      if (!this.isOpen) {
        return
      }

      let xTranslate = '100%'
      if (this.$mq.md) {
        xTranslate = '50%'
      }

      document.body.style.overflow = ''

      new TimelineLite()
        .to(this.$el, 0.5, { x: xTranslate, ease: Power2.easeInOut })
        .set(this.$el, { clearProps: 'all' })

      this.isOpen = false
    },

    handleClick () {
      this.close()
    },

    linkClass (item) {
      return {
        link: true,
        active: this.$route.path === item.url_relative
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-menu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 600;
  color: $pearl;
}

.close {
  position: absolute;
  top: 27px;
  right: 29px;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  z-index: 100;

  @include media-breakpoint-up(md) {
    top: 52px;
    right: 64px;
    font-size: 1.375rem;
  }

  &, &:hover {
    color: #fff;
  }
}

.backdrop,
.links {
  position: absolute;
  top: 0;
  bottom: 0;
}

.backdrop {
  width: 0;
  left: 0;

  @include media-breakpoint-up(md) {
    width: 50vw;
  }
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  right: 0;
  padding-left: 50px;
  background-color: $eucalyptus;

  @include media-breakpoint-up(md) {
    width: 50vw;
    padding-left: 90px;
  }
}

.link {
  display: block;
  padding: 0.625rem 0;
  line-height: 1.1;
  font-size: 2.5rem;
  font-weight: 500;
  color: #fff;
  transition: all 0.4s ease-in-out;

  @include media-breakpoint-up(md) {
    padding: 1rem 0;
    font-size: 3.125rem;
  }

  &:hover {
    text-decoration: none;
    color: $charcoal;
    transform: translate(1.5rem, 0);
  }
}

.active {
  color: rgba(#fff, 0.3);
}
</style>
