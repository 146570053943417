<template>
  <form :class="elClass" @submit.prevent="submit">
    <div v-if="isSuccess" class="form-success">
      <h5 class="success-title">Thank you for your message.</h5>
      <p class="success-body">It has been received by our team and we’ll get back to you as soon as we can.</p>
    </div>

    <div v-else-if="form" class="form form--no-labels">
      <div v-for="field in form.fields" :key="field.id" class="form-group">
        <label :for="`input-${field.id}`" class="control-label">{{ field.label }}</label>

        <input
          v-if="field.type === 'text'"
          type="text"
          :id="`input-${field.id}`"
          :placeholder="field.placeholder"
          v-model="formData[fieldId(field.id)]"
          @keyup="onChange(field.id)"
          :class="controlClass(field.id)" />

        <input
          v-if="field.type === 'email'"
          type="email"
          :id="`input-${field.id}`"
          :placeholder="field.placeholder"
          v-model="formData[fieldId(field.id)]"
          @keyup="onChange(field.id)"
          :class="controlClass(field.id)" />

        <select
          v-if="field.type === 'select'"
          :id="`input-${field.id}`"
          :placeholder="field.placeholder"
          v-model="formData[fieldId(field.id)]"
          @change="onChange(field.id)"
          class="custom-select"
          :class="controlClass(field.id)">
          <option
            v-for="choice in field.choices"
            :key="choice.value"
            :value="choice.value"
            :selected="isSelected(field, choice)">
            {{ choice.text }}
          </option>
        </select>

        <textarea
          v-if="field.type === 'textarea'"
          :id="`input-${field.id}`"
          :placeholder="field.placeholder"
          v-model="formData[fieldId(field.id)]"
          @keyup="onChange(field.id)"
          :class="controlClass(field.id)" />

        <div v-if="fieldError(field.id)" class="invalid-feedback" v-html="fieldError(field.id)" />
      </div>

      <div class="form-footer">
        <button type="submit" class="submit-button" :disabled="this.isSubmitting">Submit</button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'ContactForm',
  props: {
    formId: {
      type: Number,
      required: true
    },
    defaults: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      form: null,
      formData: {},
      formErrors: {},
      isSubmitting: false,
      isSuccess: false,
      canSubmit: false
    }
  },
  computed: {
    elClass () {
      return {
        'contact-form': true,
        'can-submit': this.canSubmit,
        'is-submitting': this.isSubmitting,
        'loaded': !!this.form
      }
    }
  },
  methods: {

    fieldId (id) {
      return `input_${id}`
    },

    fieldError (id) {
      return this.formErrors[id]
    },

    controlClass (id) {
      return {
        'form-control': true,
        'is-invalid': !!this.fieldError(id)
      }
    },

    isSelected (field, choice) {
      return this.formData[this.fieldId(field.id)] === choice.value
    },

    onChange (id) {
      if (this.formErrors[id]) {
        const errors = { ...this.formErrors }
        delete errors[id]
        this.formErrors = errors
      }

      this.canSubmit = Object.values(this.formData).filter(e => !!e).length > 2
    },

    async fetch () {
      const res = await this.$wp.api.get(`/gday/v1/forms/${this.formId}`)
      this.form = res.form
      this.setFormDefaults()
    },

    setFormDefaults () {
      const defaults = {}

      // Defaults set in the CMS
      for (const field of this.form.fields) {
        const id = this.fieldId(field.id)

        if (field.choices) {
          const selected = field.choices.find(c => c.isSelected)
          defaults[id] = selected ? selected.value : ''
        } else {
          defaults[id] = field.defaultValue
        }
      }

      // Defaults passed as props
      for (const key in this.defaults) {
        // Find a field with a matching label
        const field = this.form.fields.find(f => f.label.toLowerCase() === key.toLowerCase())
        if (field) {
          const id = this.fieldId(field.id)
          defaults[id] = this.defaults[key]
        }
      }

      this.formData = { ...defaults }
    },

    async submit () {
      if (this.isSubmitting) {
        return
      }

      this.isSubmitting = true
      this.formErrors = {}

      const result = await this.$wp.api.post(`/gday/v1/forms/${this.formId}/entries`, {
        data: {
          ...this.formData
        }
      })

      this.isSubmitting = false

      if (result.is_valid) {
        this.isSuccess = true
      } else {
        this.formErrors = { ...result.validation_messages }
      }
    }
  },
  created () {
    this.fetch()
  }
}
</script>

<style lang="scss" scoped>
.contact-form {
  height: 100%;
  opacity: 0;
  transform: translate(0, 30px);
  transition: all 0.3s ease-out;

  &.loaded {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.form-success {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 260px;
  height: 100%;
  line-height: 1.2;
}

.success-title {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: $desert;
}

.success-body {
  font-size: 1rem;
  font-weight: 600;
}

.form {
  transition: opacity 0.5s;
}

.submit-button {
  @include arrow-button(rgba($body-color, 0.3));
}

.is-submitting {
  .form {
    opacity: 0.65;
    pointer-events: none;
  }
}

.can-submit {
  .submit-button {
    @include arrow-button($desert);
  }
}
</style>
