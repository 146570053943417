<template>
  <a @click.prevent="handleClick" target="_blank" class="gallery gallery-tile">
    <div class="media">
      <div class="ratio" :style="ratioStyle"></div>
      <!-- v-if="thumbnail" -->
      <img
        :src="thumbnail"
        :alt="gallery.title.rendered"
        class="image"
        v-preload />
    </div>

    <header class="header">
      <p class="themes">
        <span
          class="theme"
          v-for="term in $wp.terms(gallery, 'theme')"
          :key="term.id"
          v-html="term.name" />
      </p>

      <h4 class="title" v-html="gallery.title.rendered"></h4>
    </header>
  </a>
</template>

<script>
import { get } from '../../utils'

export default {
  name: 'GalleryTile',
  props: {
    gallery: {
      type: Object
    },
    aspectRatio: {
      type: Number
    }
  },
  computed: {
    thumbnail () {
      // const videoPoster = this.$wp.image(get(this.gallery, 'acf.media.0.acf.poster_image'), 'post-thumbnail')
      // return (
      //   this.gallery.featured_media ||
      //   this.$wp.thumbnail(this.gallery, 'post-thumbnail') ||
      //   videoPoster ||
      //   this.$wp.image(get(this.gallery, 'acf.media.0'), 'post-thumbnail')
      // )
      const noLog = true
      const videoPoster = this.$wp.image(get(this.gallery, 'acf.media.0.acf.poster_image'), 'post-thumbnail')
      if (noImageState(videoPoster) && noLog) {return videoPoster}

      const featuredMedia = this.gallery.featured_media
      if (noImageState(featuredMedia) && noLog) {return featuredMedia}

      const galleryImage0 = this.$wp.image(get(this.gallery, 'acf.media.0'), 'post-thumbnail')
      if (noImageState(galleryImage0) && noLog) {return galleryImage0}

      const postThumbnail = this.$wp.thumbnail(this.gallery, 'post-thumbnail')
      if (noImageState(postThumbnail) && noLog) {return postThumbnail}

      console.log(`
        ${this.gallery.title.rendered} \n
        videoPoster:   '${noImageState(videoPoster)}' ${videoPoster} \n
        featuredMedia: '${noImageState(featuredMedia)}' ${featuredMedia} \n
        postThumbnail: '${noImageState(postThumbnail)}' ${postThumbnail} \n
        galleryImage0: '${noImageState(galleryImage0)}' ${galleryImage0}
      `)

      if (noImageState(videoPoster) === true) {return videoPoster}
      if (noImageState(featuredMedia) === true) {return featuredMedia}
      if (noImageState(galleryImage0) === true) {return galleryImage0}
      if (noImageState(postThumbnail) === true) {return postThumbnail}
      return ''

    },

    ratioStyle () {
      if (this.aspectRatio) {
        return {
          paddingTop: this.aspectRatio * 100 + '%'
        }
      }
    }
  },
  methods: {
    handleClick () {
      this.$router.push({
        ...this.$route,
        query: {
          modal: 'gallery',
          galleryId: this.gallery.id
        }
      })
    }
  }
}

function noImageState(image) {
  if (image === undefined || typeof image !== 'string' ){ return false }
  return true
}

</script>

<style lang="scss" scoped>
.gallery-tile {
  @include tile-hover-shadow($desert, '.image', '.media');
  display: block;
  cursor: pointer;
}

.media {
  @include image-ratio(percentage(567/453));
  margin-bottom: 1rem;
  background-color: rgba(#000, 0.1);
}

.themes {
  @include taxonomy();
}

.title {
  font-size: 1rem;
  font-weight: 500;

  @include media-breakpoint-up(md) {
    font-size: 1.625rem;
  }
}
</style>
