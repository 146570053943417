<template>
  <a :href="artist.link" target="_blank" class="music music-tile">
    <div class="media">
      <div class="ratio"></div>
      <img
        v-if="$wp.image(artist.image)"
        :src="$wp.image(artist.image, 'post-thumbnail')"
        :alt="artist.name"
        class="image"
        v-preload />
    </div>

    <h4 class="name" v-html="artist.artist"></h4>
  </a>
</template>

<script>
export default {
  name: 'MusicTile',
  props: {
    artist: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.music-tile {
  @include tile-hover-shadow($desert, 'img', '.media');
  display: block;
}

.media {
  @include image-ratio();
  background-color: rgba(#000, 0.1);
}

.name {
  display: inline-block;
  margin: 0.75rem 0 0;
  font-size: 1rem;
  font-weight: 600;
  border-bottom: 1px solid $body-color;
}
</style>
