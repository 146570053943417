<template>

  <!-- Frame -->
  <CarouselFrame
    class="events-carousel"
    :title="title"
    :buttonText="buttonText"
    :onButtonClick="onButtonClick"
    :carousel="$refs.carousel"
    :hideControls="true">

    <!-- Tile List -->
    <TileList ref="carousel" :items="items" :groupSize="groupSize">

      <!-- Event Tile -->
      <EventTile slot-scope="item" :event="item.item"/>
      
    </TileList>

  </CarouselFrame>

</template>

<script>
import CarouselFrame from '../CarouselFrame'
import EventTile from './EventTile'
import TileList from '../TileList'

export default {
  name: 'EventsCarousel',
  components: { CarouselFrame, TileList, EventTile },
  props: {
    title: {
      type: String,
      default: 'Events'
    },
    events: {
      type: Array,
      default: () => []
    },
    buttonText: String,
    onButtonClick: Function
  },
  data () {
    return {groupSize: 3, items: []}
  },
  watch: {
    events: 'fetch'
  },
  methods: {
    async fetch () {
      const items = await this.$store.dispatch('hydratePosts', {type: 'events', ids: this.events})
      this.items = items
      console.log('ITEMS', this.items)
    }
  },
  created () {
    this.fetch()
  }
}
</script>