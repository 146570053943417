<template>
  <div class="flexible-content">
    <component
      v-for="(block, idx) in content.blocks"
      :key="idx"
      :is="component(block)"
      :block="block" />
  </div>
</template>

<script>
import blocks from './blocks'

export default {
  name: 'FlexibleContent',
  props: {
    content: Object
  },
  methods: {
    component (block) {
      return blocks[block.acf_fc_layout]
    }
  }
}
</script>

<style lang="scss" scoped>
.flexible-content {}
</style>
