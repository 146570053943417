<template>
  <div class="gallery-modal-carousel">
    <a v-if="showControls" @click.prevent="previous" href class="previous">
      <i class="icon-chevron-left"></i>
    </a>

    <a v-if="showControls" @click.prevent="next" href class="next">
      <i class="icon-chevron-right"></i>
    </a>

    <transition :name="`media-${direction}`" mode="out-in">
      <div v-if="attachment" class="media" :key="attachment.id">
        <YoutubeVideo
          v-if="youtubeUrl"
          :videoUrl="youtubeUrl"
          :autoplay="true"
        />

        <img
          v-else-if="$wp.isImage(attachment)"
          :src="$wp.image(attachment)"
          :alt="attachment.alt || attachment.alt_text"
          class="image"
        />

        <video
          class="video-player"
          v-else-if="$wp.isVideo(attachment)"
          :src="$wp.video(attachment)"
          :alt="attachment.alt || attachment.alt_text"
          controls
          autoplay="true"
          playsinline
          oncontextmenu="return false;"
          controlsList="nodownload"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import YoutubeVideo from '../YoutubeVideo'

export default {
  name: 'GalleryModalCarousel',
  components: { YoutubeVideo },
  props: {
    attachment: {
      type: Object,
      required: false
    },
    gallery: {
      type: Object,
      required: false
    }
  },
  watch: {
    attachment: {
      handler: function () {
        if (this.media && this.media.length > 0) {
          this.currentIdx = this.media.map(m => m.id).indexOf(this.attachment.id)
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      currentIdx: 0,
      direction: 'none'
    }
  },
  computed: {

    media () {
      if (this.gallery && this.gallery.acf && this.gallery.acf.media) {
        return this.gallery.acf.media
      } else {
        return []
      }
      // return this.gallery.acf.media || []
    },

    showControls () {
      return this.media && this.media.length > 1
    },

    youtubeUrl () {
      if (this.attachment.acf) {
        return this.attachment.acf.youtube_url
      }
    }
  },
  methods: {
    next () {
      this.direction = 'right'
      this.goTo(this.media[this.currentIdx + 1] || this.media[0])
    },

    previous () {
      this.direction = 'left'
      this.goTo(this.media[this.currentIdx - 1] || this.media[this.media.length - 1])
    },

    goTo (attachment) {
      this.$router.replace({
        ...this.$route,
        query: {
          ...this.$route.query,
          attachmentId: attachment.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.gallery-modal-carousel {
  width: 100%;
  height: 100%;
}

.media {
  position: absolute;
  top: 64px;
  bottom: 64px;
  left: 48px;
  right: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(lg) {
    top: 120px;
    bottom: 120px;
    left: 260px;
    right: 260px;
  }

  &.media-none-enter-active,
  &.media-none-leave-active {
    transition: all 0.35s;
  }

  &.media-left-enter-active,
  &.media-right-enter-active {
    transition: all 0.35s ease-out;
  }

  &.media-left-leave-active,
  &.media-right-leave-active {
    transition: all 0.25s ease-in;
  }

  &.media-none-enter,
  &.media-none-leave-to {
    transform: scale(0.95);
    opacity: 0;
  }

  &.media-right-enter,
  &.media-left-leave-to {
    transform: translate(2rem, 0);
    opacity: 0;
  }

  &.media-left-enter,
  &.media-right-leave-to {
    transform: translate(-2rem, 0);
    opacity: 0;
  }
}

.next,
.previous {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  padding: 3rem 1rem;
  font-size: 1rem;
  color: #fff;
  transition: all 0.2s;

  @include media-breakpoint-up(lg) {
    padding: 2rem;
    font-size: 2rem;
  }

  i {
    display: inline-block;
    transition: all 0.35s;
  }

  &:hover {
    opacity: 0.5;
  }

  &:active {
    i {
      transform: scale(1.25);
    }
  }
}

.next {
  right: 0;

  @include media-breakpoint-up(lg) {
    right: 160px;
  }
}

.previous {
  left: 0;

  @include media-breakpoint-up(lg) {
    left: 160px;
  }
}

.image {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.video-player {
  width: 100%;
  height: auto;
}
</style>
