<template>
  <footer id="footer" class="page-footer">
    <section class="section-subscribe">
      <div class="container">
        <SubscribeForm :title="subscribeTitle" />
      </div>
    </section>

    <!-- <section class="section-partners">
      <div class="container">
        <div class="partners">
          <span class="partners-title">Founding Partners</span>
          <a
            v-for="(partner, idx) in foundingPartners"
            :key="partner.link_relative"
            :href="partnerLink(partner)"
            :target="partnerLinkTarget(partner)"
            class="partner"
            :style="partnerStyle">
            <span v-if="!idx" class="partners-title">Founding<br />Partners</span>
            <div class="partner-logo">
              <div class="ratio"></div>
              <img :src="$wp.partnerLogo(partner)" :alt="partner.title" />
            </div>
          </a>

          <span class="partners-title">Strategic Partners</span>
          <a
            v-for="(partner, idx) in strategicPartners"
            :key="partner.link_relative"
            :href="partnerLink(partner)"
            :target="partnerLinkTarget(partner)"
            class="partner"
            :style="partnerStyle">
            <span v-if="!idx" class="partners-title">Strategic<br />Partners</span>
            <div class="partner-logo">
              <div class="ratio"></div>
              <img :src="$wp.partnerLogo(partner)" :alt="partner.title" />
            </div>
          </a>
        </div>
      </div>
    </section> -->
    <section class="section-partners">
      <div class="container">
        <div class="partners">
          <div class="founding-partners">
            <div class="section-title">Founding Partners</div>
            <div class="section-row">
              <a
                v-for="(partner) in foundingPartners"
                :href="partnerLink(partner)"
                :target="partnerLinkTarget(partner)"
                :key="partner.link_relative"
                class="logo-link"
              >
                <div class="logo-box">
                  <img :src="$wp.partnerLogo(partner)" :alt="partner.title" />
                  <div class="logo-spacer"/>
                </div>
              </a>
            </div>
          </div>


          <div class="strategic-partners">
            <div class="section-title">Strategic Partners</div>
              <div class="section-row">
                <a
                  v-for="(partner, idx) in strategicPartners"
                  :href="partnerLink(partner)"
                  :target="partnerLinkTarget(partner)"
                  :key="partner.link_relative"
                  class="logo-link"
                >
                  <div class="logo-box">
                    <div v-if="idx > 0" class="logo-spacer"/>
                    <img :src="$wp.partnerLogo(partner)" :alt="partner.title" />
                  </div>
                </a>
              </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container">
      <hr />
    </div>

    <section class="baseplate">
      <div class="container">
        <div class="row">
          <div class="brand">
            <Logo color="#CA5421" />
          </div>

          <nav class="footer-menu">
            <ul>
              <li v-for="item in menu" :key="item.ID">
                <a :href="item.url_relative" v-html="item.title"></a>
              </li>
            </ul>
          </nav>

          <div class="copyright">
            <span v-html="copyright"></span>
            <br v-if="attribution" />
            <span v-if="attribution" v-html="attribution"></span>
          </div>

          <nav class="social-menu">
            <ul>
              <li v-for="item in socials" :key="item.ID">
                <a :href="item.url" v-html="item.title"></a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
import Logo from './Logo'
import SubscribeForm from './SubscribeForm'

export default {
  name: 'PageFooter',
  components: { Logo, SubscribeForm },
  props: {
    menu: {
      type: Array,
      default: () => []
    },
    socials: {
      type: Array,
      default: () => []
    },
    subscribeTitle: String,
    attribution: String,
    copyright: String,
    foundingPartners: {
      type: Array,
      default: () => []
    },
    strategicPartners: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    partnerStyle () {
      const count = this.foundingPartners.length + this.strategicPartners.length
      if (this.$mq.lg) {
        return {
          width: `${100 / count}%`
        }
      }
    }
  },
  methods: {

    /*
    * alow existing featured images to remain or get replaced by the new one that is part of the
    * sponsor form
    */
    partnerLogo (partner) {
      // console.log(partner.title, partner)
      if (partner.acf.logo !== undefined && partner.acf.logo !== false) {
        // console.log('logo', partner.title, partner)
        return partner.acf.logo.url
      } else if (partner.image) {
        // console.log('featured', partner.title, partner)
        return partner.image
      } else {
        return ""
      }
    },

    partnerLink (partner) {
      if (partner.template) {
        return partner.link_relative
      }

      if (partner.acf.website) {
        return partner.acf.website
      }

      return null
    },

    partnerLinkTarget (partner) {
      return partner.template ? null : '_blank'
    }
  }
}
</script>

<style lang="scss" scoped>
.page-footer {
  background-color: #fff;
}

a,
/deep/ a {
  transition: all 0.2s;

  &:hover {
    opacity: 0.75;
  }
}

hr {
  margin: 0;
  padding: 1px 0;
  border-top: 1px solid rgba($body-color, 0.2);
}

.section-subscribe {
  padding: 90px 0 120px;
  background-color: $desert;
  color: $pearl;

  @include media-breakpoint-up(smd) {
    padding: 140px 0;
  }
}

.subscribe-form {
  max-width: 460px;
  margin: 0 auto;
}

.section-partners {
  margin: 50px 0;

  @include media-breakpoint-up(smd) {
    margin: 80px 0;
  }
}


.section-partners {
  .section-title {
    @include taxonomy();
    margin-bottom: 3rem;
    margin-top: 1.5rem;
    width: 100px;

    /* font-size: 0.875rem; */
    /* font-family: "QanelasSoft", sans-serif, "Apple Color Emoji", "Noto Color Emoji"; */
    text-transform: uppercase;
    /* line-height: 1.1; */

    font-weight: bold;
    color: rgba(33, 33, 33, 0.4);
    @media (max-width: 1400px) {
      text-align: center;
      width: 100%;
    }
  }


  .section-row {
    display:flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .logo-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: auto;
    position: relative;
    img {
      width: calc(100% - 1px);
      @media (max-width: 1400px) { width: 100%; }
    }
    .logo-spacer {
      width: 1px;
      background-color: rgba($charcoal, 0.2);
      @media (max-width: 1400px) { display: none; }
    }
  }



  .founding-partners {
    $width: (100 / 7 * 4) + '%';
    width: #{$width};
    @media (max-width: 1400px) {
      width: 100%;
    }
    .logo-link {
      width: 25%;
      height: auto;
      margin-bottom: 1.5rem;
      @media (max-width: 900px) { width: calc(100% / 2);}
      @media (max-width: 500px) { width: 100%; }
    }

  }
  .strategic-partners {
    $width: 100 / 7 * 3 + '%';
    width: #{$width};
    @media (max-width: 1400px) {
      width: 100%;
    }
    .logo-link {
      width: calc(100% / 3);
      height: auto;
      margin-bottom: 1.5rem;
      @media (max-width: 1400px) { width: calc(100% / 4); }
      @media (max-width: 900px) { width: calc(100% / 2);}
      @media (max-width: 500px) { width: 100%; }
    }
  }
}

.partners {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include media-breakpoint-up(sm) {
    margin-top: 140px;
  }

  @include media-breakpoint-up(md) {
    margin-top: 60px;
  }
}

/* .partners-title {
  @include taxonomy();
  display: block;
  width: 100%;
  margin: 1.5rem 0 0;
  text-align: center;

  @include media-breakpoint-up(lg) {
    display: none;
  }
} */

/* .partner { */
  /* position: relative;
  width: percentage(1/2);
  padding: 0 1rem;

  @include media-breakpoint-up(sm) {
    width: percentage(1/4);
  }

  @include media-breakpoint-up(lg) {
    padding: 0 1.75rem;
    margin-top: 80px;
  }

  .partners-title {
    display: none;
    position: absolute;
    left: 0;
    bottom: 100%;
    margin-bottom: 1.75rem;
    text-align: left;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  } */

  /* .partner-logo {
    position: relative;
    cursor: pointer;
    .ratio {
      padding-top: 100%;
      cursor: pointer;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 90%;
      max-height: 65%;
      transform: translate(-50%, -50%);

      @include media-breakpoint-up(lg) {
        max-width: 100%;
        max-height: 70%;
      }
    } */
  /* } */

  /* &:after {
    content: '';
    position: absolute;
    top: 1.75rem;
    bottom: 1.75rem;
    right: 0;
    width: 1px;
    background-color: rgba($charcoal, 0.2);
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  } */

  /* &:last-child:after {
    display: none;
  } */
/* } */

.baseplate {
  padding: 65px 0;
}

.brand {
  @include make-col-ready();

  @include media-breakpoint-up(md) {
    @include make-col(2);
  }
}

/* .logo {
  max-width: 100px;
  margin-bottom: 2rem;

  @include media-breakpoint-up(smd) {
    max-width: 150px;
  }
} */

.footer-menu,
.social-menu {

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    display: block;
    text-transform: uppercase;
    text-decoration: none;
  }
}

.footer-menu {
  @include make-col-ready();
  font-size: 0.875rem;
  font-weight: 600;

  @include media-breakpoint-up(smd) {
    font-size: 1rem;
  }

  @include media-breakpoint-up(md) {
    @include make-col(5);
  }

  @include media-breakpoint-up(xl) {
    @include make-col(3);
  }

  ul {
    @include make-custom-row(20px);
  }

  li {
    @include make-custom-col-ready(20px);
    @include make-col(6);
    margin-bottom: 0.5rem;
  }
}

.social-menu {
  @include make-col-ready();
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-up(md) {
    @include make-col(3);
    margin-top: 0;
    margin-right: 0;
  }

  ul {
    text-align: center;

    @include media-breakpoint-up(md) {
      float: right;
    }
  }

  li {
    display: inline-block;
    margin: 0 1rem;

    @include media-breakpoint-up(md) {
      float: left;
      margin-left: 1.5rem;
      margin-right: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 2rem;
    }
  }

  a {
    font-size: 1.25rem;

    @include media-breakpoint-up(md) {
      font-size: 1.625rem;
    }
  }
}

.copyright {
  @include make-col-ready();
  margin-top: 1.5rem;
  font-size: 0.625rem;
  text-align: center;
  font-weight: 300;
  order: 4;

  @include media-breakpoint-up(md) {
    @include make-col(2);
    margin-top: 0;
    font-size: 1rem;
    text-align: left;
    order: 3;
  }

  span {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
}
</style>
