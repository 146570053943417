<template>
  <Hero class="partner-header">
    <img
      slot="media"
      class="cover-image"
      :src="$wp.image(partner.acf.cover_image)"
      :alt="partner.title.rendered" />

    <div slot class="main">
      <p class="subtitle">Featured Sponsor</p>
      <img
        class="logo"
        :src="$wp.partnerThumbnail(partner)"
        :alt="partner.title.rendered" />
      <div class="excerpt" v-html="partner.excerpt.rendered"></div>
      <a
        v-if="partner.acf.website"
        :href="partner.acf.website"
        target="_blank"
        class="learn-more">Learn more</a>
    </div>
  </Hero>
</template>

<script>
import Hero from '../Hero'

export default {
  name: 'PartnerPrimaryHeader',
  components: { Hero },
  props: {
    partner: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.partner-header {
  background-color: #fff;
}

/deep/ .media {
  height: 500px;

  @include media-breakpoint-up(md) {
    height: 100vh;
  }
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main {
  max-width: 400px;
  margin: 64px 0;

  @include media-breakpoint-up(md) {
    margin: $page-margin-top auto;
  }
}

.subtitle {
  @include taxonomy();
  margin-bottom: 1rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 2rem;
  }
}

.logo {
  /* max-width: 260px;
  max-height: 100px; */
  margin-bottom: 1.75rem;
  width: 100%;

  /* @include media-breakpoint-up(md) {
    max-width: 320px;
    max-height: 120px;
  } */
}

.excerpt {
  line-height: 1.3;
  font-size: 1rem;

  @include media-breakpoint-up(md) {
    font-size: 1.25rem;
  }
}

.learn-more {
  @include arrow-button();
  margin-top: 1rem;
}
</style>
