import { render, staticRenderFns } from "./MusicTile.vue?vue&type=template&id=3a5ea200&scoped=true&"
import script from "./MusicTile.vue?vue&type=script&lang=js&"
export * from "./MusicTile.vue?vue&type=script&lang=js&"
import style0 from "./MusicTile.vue?vue&type=style&index=0&id=3a5ea200&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a5ea200",
  null
  
)

component.options.__file = "MusicTile.vue"
export default component.exports