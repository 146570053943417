<template>
  <CarouselFrame
    class="socials-carousel"
    :title="title"
    :buttonText="buttonText"
    :onButtonClick="onButtonClick"
    :carousel="$refs.carousel">
    <TileCarousel ref="carousel" :items="items" :groupSize="groupSize">
      <SocialPostTile slot-scope="item" :post="item.item" />
    </TileCarousel>
  </CarouselFrame>
</template>

<script>
import TileCarousel from '../TileCarousel'
import CarouselFrame from '../CarouselFrame'
import SocialPostTile from './SocialPostTile'

export default {
  name: 'SocialsCarousel',
  components: { TileCarousel, CarouselFrame, SocialPostTile },
  props: {
    title: {
      type: String,
      default: 'Follow us'
    },
    posts: {
      type: Array,
      default: () => []
    },
    buttonText: String,
    onButtonClick: Function
  },
  data () {
    return {
      groupSize: 4,
      items: []
    }
  },
  watch: {
    posts: 'fetch'
  },
  methods: {
    async fetch () {
      this.items = await this.$store.dispatch('hydratePosts', {
        type: 'social-posts',
        ids: this.posts
      })
    }
  },
  created () {
    this.fetch()
  }
}
</script>
