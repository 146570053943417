<template>
  <article v-if="page" class="champions-page">
    <header class="header">
      <div class="container">
        <div class="headline" v-html="page.acf.headline"></div>
      </div>
    </header>

    <section v-if="champions" class="content">
      <div class="container">
        <div class="champions">
          <div
            v-for="champion in champions"
            :key="champion.id"
            class="champion-item">
            <ChampionTile :champion="champion" />
          </div>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import PageMixin from './PageMixin'
import ChampionTile from '../components/champions/ChampionTile'

export default {
  name: 'ChampionsPage',
  mixins: [PageMixin],
  components: { ChampionTile },
  data () {
    return {
      champions: []
    }
  },
  watch: {
    page: 'fetch'
  },
  methods: {
    async fetch () {
      if (this.page) {
        this.champions = await this.$store.dispatch('hydratePosts', {
          type: 'champions',
          ids: this.page.acf.champions,
          collection: 'champions'
        })
      }
    }
  },
  created () {
    this.fetch()
  }
}
</script>

<style lang="scss" scoped>
.champions-page {
  margin-top: 140px;

  @include media-breakpoint-up(md) {
    margin-top: $page-margin-top;
  }
}

.header {
  margin-bottom: 72px;

  @include media-breakpoint-up(md) {
    margin-bottom: 220px;
  }
}

.headline {
  @include headline();
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.content {
  margin-bottom: 72px;

  @include media-breakpoint-up(md) {
    margin-bottom: 180px;
  }
}

.champions {
  @include make-row();
  margin-top: -72px;

  @include media-breakpoint-up(md) {
    margin-top: -150px;
  }
}

.champion-item {
  @include make-col-ready();
  margin-top: 72px;

  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }

  @include media-breakpoint-up(md) {
    margin-top: 150px;
  }

  @include media-breakpoint-up(lg) {
    @include make-col(4);
  }
}
</style>
