<template>
  <section class="featured-sponsor">
    <div class="container">
      <article v-if="sponsor" class="sponsor">
        <div class="media">
          <img class="image" :src="$wp.image(block.image)" :alt="sponsor.title.rendered" />
          <div class="header">
            <h5 class="label">{{ block.title }}</h5>
            <h3 class="title" v-html="sponsorTitle"></h3>
          </div>
        </div>

        <div class="main">
          <div class="content">
            <div class="header">
              <h5 class="label">{{ block.title }}</h5>
              <h3 class="title" v-html="sponsorName"></h3>
            </div>

            <div class="body" v-html="block.sponsor_description"></div>
            <a :href="sponsor.link_relative" class="content-link">Learn more</a>
            <br />
            <a href="/contact" class="content-link">Become a sponsor</a>
          </div>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeaturedSponsorBlock',
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      sponsor: null
    }
  },
  computed: {

    sponsorName () {
      return (
        this.block.sponsor_name ||
        this.sponsor.title.rendered
      )
    },

    sponsorTitle () {
      return this.sponsorName
        .split('\n')
        .map(t => `<span class="line">${t}</span>`)
        .join('')
    }
  },
  methods: {
    async fetch () {
      this.sponsor = await this.$store.dispatch('hydratePosts', {
        type: 'sponsors',
        ids: this.block.sponsor
      })
    }
  },
  created () {
    this.fetch()
  }
}
</script>

<style lang="scss" scoped>
.featured-sponsor {
  margin: 72px 0;

  @include media-breakpoint-up(md) {
    margin: 180px 0;
  }
}

.sponsor {
  display: flex;
  flex-direction: column;
  background-color: $eucalyptus;
  color: #fff;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: row;
  }
}

.main,
.media {
  position: relative;

  @include media-breakpoint-up(md) {
    width: 50%;
    flex: 1;
    flex-shrink: 0;
  }
}

.image {
  width: 100%;
  height: 260px;
  object-fit: cover;

  @include media-breakpoint-up(sm) {
    height: 320px;
  }

  @include media-breakpoint-up(md) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.header {

}

.media .header {
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  padding: 0 64px;
  transform: translate(0, -50%);

  @include media-breakpoint-up(md) {
    display: block;
  }

  @include media-breakpoint-up(xl) {
    padding: 0 120px;
  }
}

.content .header {
  display: block;
  margin-bottom: 1rem;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.label {
  margin-bottom: 0.5rem;
  font-size: 0.625rem;
  font-weight: bold;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    margin-bottom: 1.25rem;
    font-size: 0.875rem;
  }
}

.title {
  line-height: 1;
  font-size: 1.875rem;

  @include media-breakpoint-up(md) {
    font-size: 4rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 5.625rem;
  }

  /deep/ .line {
    display: block;
  }
}

.main {
  padding: 1.75rem 1.5rem 3rem;

  @include media-breakpoint-up(md) {
    padding: 100px 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 120px 0;
  }

  @include media-breakpoint-up(xl) {
    padding: 170px 0;
  }
}

.content {
  @include media-breakpoint-up(md) {
    width: 400px;
    max-width: 80%;
    margin: 0 auto;
  }
}

.body {

}

.content-link {
  @include arrow-button(rgba($pearl, 0.5), $pearl);
  margin-top: 1.5rem;

  ~ .content-link {
    margin-top: 1rem;
  }
}
</style>
