<template>
  <a :href="link" :target="linkTarget" class="partner partner-tile">
    <div class="media">
      <div class="logo">
        <img
          :src="$wp.partnerThumbnail(partner)"
          :alt="partner.title.rendered"
          v-preload
        />
      </div>
    </div>
    <h4 class="name" v-html="partner.title.rendered"></h4>
  </a>
</template>

<script>
export default {
  name: 'PartnerTile',
  props: {
    partner: {
      type: Object,
      required: true
    }
  },
  computed: {

    link () {

      // Template
      if (this.partner.template) return this.partner.link_relative

      // Website
      if (this.partner.acf.website) return this.partner.acf.website

      // ..
      return null

    },

    linkTarget () {
      return this.partner.template ? null : '_blank'
    }

  }
}
</script>

<style lang="scss" scoped>
.partner-tile {
  @include tile-hover-shadow($desert, '.logo', '.media');
  display: block;

  &, &:hover {
    text-decoration: none;
    color: $body-color;
  }
}

.media {
  position: relative;
  padding-top: 100%;
  margin-bottom: 0.75rem;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;



  img {
    /* position: absolute; */
    width: 100%;
    /* top: 50%;
    left: 50%;
    max-width: 80%;
    max-height: 60%;
    transform: translate(-50%, -50%); */
  }
}

.name {
  font-size: 1rem;
  font-weight: 600;
  text-decoration: underline;
}
</style>
