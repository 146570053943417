<template>

  <article v-if="page" class="events-page">

    <section class="events-filter">

      <div class="container">

        <!-- Headline -->
        <div class="headline" v-html="page.acf.headline"/>

        <!-- Filters -->
        <nav class="filters" v-if="this.page">

          <!-- Current Year + 1 -->
          <a
            :class="filterClass('upcoming')"
            @click.prevent="showYear(page.acf.next_year, 'upcoming')"
            v-if="this.page.acf.show_next_year === true"
          >
            {{page.acf.next_year}} Program
          </a>

          <span
            class="sep"
            v-if="this.page.acf.show_next_year === true"
          >/</span>

          <!-- Current Year -->
          <a :class="filterClass('now')" @click.prevent="showYear(page.acf.this_year, 'now')">
            {{page.acf.this_year}} Program
          </a>
          <span class="sep">/</span>

          <!-- Current Year - X -->
          <a
            :class="filterClass('past')"
            @click.prevent="showPast(page.acf.past_year)"
            v-if="page.acf.show_past_year === true"
          >Past</a>
          <span
            class="sep"
            v-if="page.acf.show_past_year === true"
          >/</span>

          <!-- City -->
          <TermSelect :terms="cities" emptyText="City" @change="filterByCity" />

        </nav>

      </div>

    </section>

    <!-- Events -->
    <section ref="groups" class="event-groups">

      <!-- Featured -->
      <div v-if="showFeatured" class="group-row group-featured">
        <EventsCarousel :title="page.acf.featured_events_title" :events="featured" />
      </div>

      <!-- Regular -->
      <div v-for="group in groups" :key="group.month" class="group-row">
        <EventsCarousel :title="group.month_display" :events="group.posts" />
      </div>

    </section>

    <!-- Loading -->
    <section v-if="loading" class="loading">
      <Loader />
    </section>

  </article>

</template>

<script>
// import { DateTime } from 'luxon'
import moment from 'moment'
import TimelineLite from 'gsap/TimelineLite'
import PageMixin from './PageMixin'
import EventsCarousel from '../components/events/EventsCarousel'
import TermSelect from '../components/TermSelect'
import Loader from '../components/Loader'
import { timelinePromise } from '../utils'


export default {
  name: 'EventsPage',
  mixins: [PageMixin],
  components: { EventsCarousel, TermSelect, Loader },
  data () {
    return {
      loading: false,
      currentFilter: null,
      showingUpcoming: false,
      form: {
        group_by: 'month',
        group_by_order: 'asc',
        _embed: 1
      },
      groups: [],
      featured: [],
      cities: [],
      themes: []
    }
  },
  watch: {
    page: 'fetchFeatued',
    form: 'fetchEvents'
  },
  computed: {
    showFeatured () {
      return this.featured && this.featured.length && this.currentFilter === 'upcoming'
    }
  },
  methods: {
    async fetchFeatued () {
      if (!this.page) {
        return
      }

      if (this.page.acf.featured_events) {
        /**
         * going to split to get values from WP for default calander tab
         * [0] = the currentFilter
         * [1] = the year
         * Were not using JSON because the ":" character is a reserved symbol in Wordpress select box
         */
        const defaultsData = this.page.acf.default_to.split(" ")
        const currentFilter = defaultsData[0]
        const yearNumber = defaultsData[1]
        // console.log(this.page.acf, defaultsData)
        if (currentFilter === "past") {
          this.showPast(this.page.acf.past_year)
        } else {
          this.showYear(this.page.acf[yearNumber], currentFilter)
        }
        this.featured = await this.$store.dispatch('fetchPostsByIds', {
          type: 'events',
          ids: this.page.acf.featured_events,
          collection: 'events-featured-events'
        })
      }
    },

    async fetchEvents () {
      const groups = this.$store.dispatch('fetchPosts', {
        type: 'events',
        query: { ...this.form }
      })
      await this.leaveAnimation()
      this.loading = true
      this.groups = await groups
      this.enterAnimation()
      console.log(this)
      this.loading = false
    },

    async fetchTerms () {
      const [cities, themes] = await Promise.all([
        await this.$store.dispatch('fetchPosts', {
          type: 'cities',
          collection: 'cities'
        }),
        await this.$store.dispatch('fetchPosts', {
          type: 'themes',
          collection: 'themes'
        })
      ])

      this.cities = cities
      this.themes = themes
    },

    // showUpcoming () {
    //   this.currentFilter = 'upcoming' // next year
    //   const jDate = new Date (`01/01/${new Date().getFullYear()+1}`)
    //   var mDate = moment(jDate).subtract(1,'days')
    //   var dateString = mDate.format('YYYY-MM-DD') // return iso 8601 date - formate exmaple ie 2020-08-27
    //   const form = {
    //     ...this.form,
    //     group_by_order: 'asc',
    //     min_start_time: dateString
    //   }
    //   delete form.max_start_time
    //   this.form = form
    // },

    // showThisYears () {
    //   this.currentFilter = 'now'
    //   const max_jDate = new Date (`01/01/${new Date().getFullYear()}`)
    //   let max_mDate = moment(max_jDate).add('year', 1)
    //   let max_dateString = max_mDate.format('YYYY-MM-DD') // return iso 8601 date - formate exmaple ie 2020-08-27

    //   const min_jDate = new Date (`01/01/${new Date().getFullYear()}`)
    //   let min_mDate = moment(min_jDate).subtract(1,'days')
    //   let min_dateString = min_mDate.format('YYYY-MM-DD') // return iso 8601 date - formate exmaple ie 2020-08-27

    //   const form = {
    //     ...this.form,
    //     group_by_order: 'desc',
    //     max_start_time: max_dateString,
    //     min_start_time: min_dateString,
    //   }
    //   this.form = form
    // },

    showYear (yearNumber, currentFilter) {
      this.currentFilter = currentFilter // this year
      const max_jDate = new Date (`01/01/${parseInt(yearNumber)+1}`)
      let max_mDate = moment(max_jDate).subtract(1,'days')
      let max_dateString = max_mDate.format('YYYY-MM-DD') // return iso 8601 date - formate exmaple ie 2020-08-27

      const min_jDate = new Date (`01/01/${parseInt(yearNumber)}`)
      let min_mDate = moment(min_jDate)
      let min_dateString = min_mDate.format('YYYY-MM-DD') // return iso 8601 date - formate exmaple ie 2020-08-27
      // console.log(`01/01/${Number(yearNumber)}`, `01/01/${Number(yearNumber)+1}` )
      // console.log(min_dateString, max_dateString)
      const form = {
        ...this.form,
        group_by_order: 'asc',
        max_start_time: max_dateString,
        min_start_time: min_dateString,
      }
      this.form = form
    },

    showPast (yearNumber) {
      this.currentFilter = 'past' // past events - last year and older
      const jDate = new Date (`01/01/${yearNumber}`)
      var mDate = moment(jDate).subtract(1,'days')
      var dateString = mDate.format('YYYY-MM-DD') // return iso 8601 date - formate exmaple ie 2020-08-27
      const form = {
        ...this.form,
        group_by_order: 'desc',
        max_start_time: dateString
      }
      delete form.min_start_time
      this.form = form
    },

    filterByCity (city) {
      const form = { ...this.form }
      if (city) {
        form.cities = [city.id]
      } else {
        delete form.cities
      }
      this.form = form
    },

    filterClass (filter) {
      return {
        filter: true,
        active: this.currentFilter === filter
      }
    },

    enterAnimation () {
      const el = this.$refs.groups
      const tl = new TimelineLite()
        .set(el, { display: 'block' })
        .to(el, 0.3, { y: 0, autoAlpha: 1 })
      return timelinePromise(tl)
    },

    leaveAnimation () {
      if (!this.$refs.groups) {
        return
      }
      const el = this.$refs.groups
      const tl = new TimelineLite()
        .to(el, 0.3, { y: 24, autoAlpha: 0 })
        .set(el, { display: 'none' })
      return timelinePromise(tl)
    }
  },
  mounted () {
    this.fetchTerms()
    this.fetchFeatued()
  }
}
</script>

<style lang="scss" scoped>
.events-page {
  margin-top: 140px;

  @include media-breakpoint-up(md) {
    margin-top: $page-margin-top;
  }
}

.events-filter {
  margin-bottom: 72px;
  text-align: center;

  @include media-breakpoint-up(md) {
    margin-bottom: 160px;
  }
}

.headline {
  @include headline();
  max-width: 710px;
  margin: 0 auto 2rem;
}

.filters {

  .filter,
  .sep,
  .term-select {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: rgba($body-color, 0.6);
    font-weight: 600;

    @include media-breakpoint-up(sm) {
      font-size: 1rem;
    }
  }

  .filter,
  .term-select {
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      color: rgba($body-color, 0.85);
    }
  }

  .sep {
    display: inline-block;
    margin: 0 0.75rem;
  }

  .active {
    color: $desert;
  }
}

.loading {
  margin: 200px 0 100px;
}

.event-groups {
  margin-top: -48px;

  @include media-breakpoint-up(md) {
    margin-top: -100px;
  }
}

.group-row {
  padding: 48px 0;

  @include media-breakpoint-up(md) {
    padding: 100px 0;
  }

  &:nth-child(even) {
    background-color: $pearl;
  }
}
</style>
