import store from '../store'
import { setPageTitle } from '../utils'
import { sameRoute } from '../utils'

export default {
  created () {
    if (this.page) {
      setPageTitle(this.page)
    }
  },
  data () {
    return {
      page: null
    }
  },
  beforeRouteEnter: async function (to, from, next) {
    next(vm => vm.fetchRouteData.call(vm, to, from))
  },
  async beforeRouteUpdate (to, from, next) {
    if (!sameRoute(to, from)) {
      await this.fetchRouteData(to, from)
    }
    next()
  },
  methods: {
    async fetchRouteData (to) {
      this.page = await store.dispatch('fetchPostBySlug', {
        type: 'pages',
        slug: to.name
      })
    }
  }
}
