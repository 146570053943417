<template>

  <article v-if="page" class="media-page">

    <!-- Loading -->
    <section v-if="loading" class="loading">
      <Loader />
    </section>

    <!-- Featured Galleries -->
    <section v-if="page.acf.featured_galleries" class="section-featured-galleries">
      <GalleriesCarousel
        :title="page.acf.featured_galleries_title"
        :galleries="page.acf.featured_galleries"
        :aspectRatio="1" />
    </section>

    <!-- Featured Videos -->
    <section v-if="!loading && page.acf.featured_videos" class="section-featured-galleries">
      <GalleryCarousel
        :title="page.acf.featured_videos_title"
        :gallery="page.acf.featured_videos"
        :aspectRatio="3/4" />
    </section>

    <!-- Galleries By Year -->
    <section
      v-for="year in yearGalleries"
      :key="year.year"
      class="section-year-galleries">
      <div class="container">
        <GalleriesGroup
          :title="`${year.year} Gallery`"
          :listTitle="`More galleries from ${year.year}`"
          :galleries="year.galleries"
          :aspectRatio="1" />
      </div>
    </section>

    <!-- Archived Galleries -->
    <section v-if="archiveGalleries && archiveGalleries.length" class="section-archived-galleries">
      <div class="container">
        <GalleriesGroup
          :title="page.acf.archive_title"
          :galleries="archiveGalleries"
          :aspectRatio="1" />
      </div>
    </section>

  </article>

</template>

<script>
import { mapActions } from 'vuex'
import PageMixin from './PageMixin'
import GalleriesCarousel from '../components/galleries/GalleriesCarousel'
import GalleryCarousel from '../components/galleries/GalleryCarousel'
import GalleriesGroup from '../components/galleries/GalleriesGroup'
import Loader from '../components/Loader'

export default {
  name: 'MediaPage',
  mixins: [PageMixin],
  components: { GalleriesCarousel, GalleryCarousel, GalleriesGroup, Loader },
  data () {
    return {
      loading: true,
      years: [],
      yearGalleries: [],
      archiveGalleries: []
    }
  },
  watch: {
    page: 'fetch'
  },
  methods: {

    ...mapActions(['getPhotosAndVideos']),

    async fetch () {
      if (!this.page) return
      this.loading = true
      const data = await this.getPhotosAndVideos({ page: this.page })
      this.yearGalleries = data.galleries
      this.archiveGalleries = data.archived
      this.loading = false
    }

  },
  created () {
    this.fetch()
  }
}
</script>

<style lang="scss" scoped>
.media-page {
  margin-top: 140px;

  @include media-breakpoint-up(md) {
    margin-top: $page-margin-top;
  }
}

.loading {
  margin: 100px 0;
}

section {
  margin: 48px 0;

  @include media-breakpoint-up(md) {
    margin: 100px 0;
  }
}

section:nth-child(even) {
  padding: 48px 0;
  background-color: #fff;

  @include media-breakpoint-up(md) {
    padding: 100px 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
