<template>
  <div class="galleries-group">
    <div class="header">
      <h3 v-if="title" class="title" v-html="title"></h3>
      <a v-if="hasMore" @click.prevent="toggle" :class="toggleClass">{{ toggleText }}</a>
    </div>

    <div class="grid">
      <div v-for="gallery in primary" :key="gallery.id" class="grid-item">
        <GalleryTile
          :gallery="gallery"
          :aspectRatio="aspectRatio" />
      </div>
    </div>

    <div ref="list" class="list">
      <h4 class="list-title">{{ listTitle }}</h4>

      <ul class="list-galleries">
        <li v-for="gallery in secondary" :key="gallery.id" class="list-gallery">
          <span class="gallery-title" v-html="gallery.title.rendered"></span>
          <a @click.prevent="openGallery(gallery)" href class="gallery-link">View gallery</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import TimelineLite from 'gsap/TimelineLite'
import GalleryTile from './GalleryTile'

export default {
  name: 'GalleriesGroup',
  components: { GalleryTile },
  props: {
    title: {
      type: String,
      default: 'Photo galleries'
    },
    listTitle: {
      type: String,
      default: 'More galleries'
    },
    galleries: {
      type: Array,
      default: () => []
    },
    aspectRatio: {
      type: Number
    }
  },
  data () {
    return {
      isExpanded: false
    }
  },
  watch: {
    galleries: 'fetch'
  },
  computed: {
    primary () {
      const idx = this.$mq.md ? 3 : 2
      return this.galleries.slice(0, idx)
    },

    secondary () {
      const idx = this.$mq.md ? 3 : 2
      return this.galleries.slice(idx)
    },

    hasMore () {
      return this.secondary.length > 0
    },

    toggleClass () {
      return {
        toggle: true,
        expanded: this.isExpanded
      }
    },

    toggleText () {
      return this.isExpanded ? 'See less' : 'See more'
    }
  },
  methods: {
    async fetch () {
      this.items = await this.$store.dispatch('hydratePosts', {
        type: 'galleries',
        ids: this.galleries
      })
    },

    toggle () {
      if (this.isExpanded) {
        this.collapse()
      } else {
        this.expand()
      }
    },

    expand () {
      if (this.isExpanded) {
        return
      }

      const { list } = this.$refs
      const items = [
        list.querySelector('.list-title'),
        ...list.querySelectorAll('.list-gallery')
      ]

      new TimelineLite()
        .set(list, { display: 'block' })
        .from(list, 0.5, { height: 0, immediateRender: false })
        .staggerFromTo(items, 0.3, { x: 32, autoAlpha: 0 }, { x: 0, autoAlpha: 1 }, 0.05, '-=0.25')

      this.isExpanded = true
    },

    collapse () {
      if (!this.isExpanded) {
        return
      }

      const { list } = this.$refs

      new TimelineLite()
        .to(list, 0.25, { autoAlpha: 0 })
        .to(list, 0.35, { height: 0 }, '-=0.15')
        .set(list, { clearProps: 'all' })

      this.isExpanded = false
    },

    openGallery (gallery) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          modal: 'gallery',
          galleryId: gallery.id
        }
      })
    }
  },
  created () {
    this.fetch()
  }
}
</script>

<style lang="scss" scoped>
.galleries-group {

}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 40px;
  }
}

.title {
  @include headline();
  margin-bottom: 0.125rem;
  font-size: 1rem;
}

.toggle {
  @include arrow-button($body-color, $body-color, 'down');
  margin-left: 1.25rem;
  font-size: 0.75rem;

  @include media-breakpoint-up(md) {
    margin-left: 2rem;
    font-size: 1rem;
  }

  &.expanded {
    @include arrow-button($body-color, $body-color, 'up');
  }
}

.grid {
  @include make-custom-row($grid-gutter-width-sm);

  @include media-breakpoint-up(md) {
    @include make-row();
  }
}

.grid-item {
  @include make-custom-col-ready($grid-gutter-width-sm);
  @include make-col(6);

  @include media-breakpoint-up(md) {
    @include make-col-ready();
    @include make-col(4);
  }
}

.list {
  display: none;
  padding-top: 1px;
}

.list-title {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: bold;
  color: $sun;

  @include media-breakpoint-up(md) {
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 0.875rem;
  }
}

.list-galleries {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-gallery {
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  .gallery-title {
    margin-right: 1.25rem;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      font-size: 1.625rem;
    }
  }

  .gallery-link {
    @include arrow-button($body-color);
    font-size: 0.75rem;
    vertical-align: text-bottom;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }
}
</style>
