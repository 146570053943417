<template>
  <div class="gallery-modal-grid">
    <div class="grid">
      <div class="row">
        <div v-for="attachment in gallery.acf.media" :key="attachment.id" class="grid-item">
          <PhotoTile :gallery="gallery" :attachment="attachment" :aspectRatio="1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhotoTile from './PhotoTile'

export default {
  name: 'GalleryModalGrid',
  components: { PhotoTile },
  props: {
    gallery: {
      type: [Object, Number],
      required: true
    }
  },
  data () {
    return {
      galleryObject: null,
      media: []
    }
  },
  methods: {
    openAttachment (attachment) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          modal: 'gallery',
          galleryId: this.galleryObject.id,
          attachmentId: attachment.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.gallery-modal-grid {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 60px 30px;

  @include media-breakpoint-up(sm) {
    padding: 80px 60px;
  }

  @include media-breakpoint-up(md) {
    padding: 100px 80px;
  }

  @include media-breakpoint-up(lg) {
    padding: 120px;
  }

  @include media-breakpoint-up(xl) {
    padding: 160px 180px;
  }
}

.grid {
  margin-top: $grid-gutter-width * -0.5;
}

.row {
  @include make-custom-row($grid-gutter-width * 0.5);
}

.grid-item {
  @include make-custom-col-ready($grid-gutter-width * 0.5);
  @include make-col(6);
  margin-top: $grid-gutter-width * 0.5;

  @include media-breakpoint-up(md) {
    @include make-col(4);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(3);
  }
}
</style>
