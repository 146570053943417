<template>
  <section :class="elClass">
    <div class="container">
      <div class="row">
        <div class="video-wrapper">
          <PlayableAttachment
            :ratio="9/16"
            :poster="$wp.image(block.poster_image, 'large')"
            :onClick="showVideo" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PlayableAttachment from '../galleries/PlayableAttachment'

export default {
  name: 'PartnerVideoBlock',
  components: { PlayableAttachment },
  props: {
    block: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: 'small'
    }
  },
  computed: {
    elClass () {
      return {
        'partner-video': true,
        [`partner-video--${this.size}`]: true
      }
    }
  },
  methods: {
    showVideo () {
      const { video_source, video_mp4, youtube_url } = this.block
      let query = {}

      if (video_source === 'attachment') {
        query = {
          modal: 'gallery',
          attachmentId: video_mp4.id
        }
      } else if (video_source === 'youtube') {
        query = {
          modal: 'youtube',
          videoUrl: youtube_url
        }
      }

      this.$router.push({ ...this.$route, query })
    }
  }
}
</script>

<style lang="scss" scoped>
.partner-video {}

.video-wrapper {
  @include make-col-ready();
}

.partner-video {

  &--small {
    margin: 48px 0;

    @include media-breakpoint-up(md) {
      margin: 90px 0;
    }

    .video-wrapper {
      @include media-breakpoint-up(md) {
        @include make-col(10);
        @include make-col-offset(1);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(8);
        @include make-col-offset(2);
      }
    }
  }

  &--large {
    margin: 72px 0;

    @include media-breakpoint-up(md) {
      margin: 140px 0;
    }
  }
}
</style>
