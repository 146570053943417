<template>
  <section class="partner-products">
    <div class="container">
      <div class="row">
        <component
          v-for="(product, idx) in block.products"
          :key="idx"
          :is="productComponent(product)"
          :href="product.link"
          target="_blank"
          class="product">
          <img class="image" v-if="product.image" :src="$wp.image(product.image)" :alt="product.title" />
          <h5 class="title" v-html="product.title"></h5>
          <div class="description" v-html="product.description"></div>
        </component>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PartnerProductsBlock',
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  methods: {
    productComponent (product) {
      return product.link ? 'a' : 'div'
    }
  }
}
</script>

<style lang="scss" scoped>
.partner-products {
  margin: 140px 0;
}

.row {
  margin-top: -1.5rem;
}

.product {
  @include make-col-ready();
  display: block;
  margin-top: 1.5rem;
  color: $body-color;

  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }

  @include media-breakpoint-up(md) {
    @include make-col(3);
  }
}

.image {
  width: 100%;
  height: auto;
  margin-bottom: 1.25rem;
}
</style>
