<template>
  <article class="speaker speaker-tile">
    <div class="photo">
      <div class="ratio"></div>
      <img
        v-if="$wp.thumbnail(speaker)"
        :src="$wp.thumbnail(speaker)"
        :alt="speaker.title.rendered"
        v-preload />
    </div>

    <h4 class="name" v-html="speaker.title.rendered"></h4>
    <p class="title" v-html="displayTitle"></p>

    <div
      v-if="speaker.content.rendered"
      ref="bio"
      class="bio"
      v-html="speaker.content.rendered" />

    <a href @click.prevent="toggle" class="toggle">
      <span v-if="isExpanded">Read less</span>
      <span v-else>Read more</span>
    </a>
  </article>
</template>

<script>
import TimelineLite from 'gsap/TimelineLite'

export default {
  name: 'SpeakerTile',
  props: {
    speaker: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isExpanded: false
    }
  },
  computed: {
    displayTitle () {
      return [
        this.speaker.acf.position_title,
        this.speaker.acf.company_name
      ].filter(v => !!v).join(' @ ')
    }
  },
  methods: {
    toggle () {
      if (this.isExpanded) {
        this.collapse()
      } else {
        this.expand()
      }
    },

    expand () {
      if (this.isExpanded) {
        return
      }

      const { bio } = this.$refs

      new TimelineLite()
        .set(bio, { display: 'block' })
        .from(bio, 0.5, { height: 0, immediateRender: false })
        .from(bio, 0.5, { opacity: 0 }, '-=0.25')

      this.isExpanded = true
    },

    collapse () {
      if (!this.isExpanded) {
        return
      }

      const { bio } = this.$refs

      new TimelineLite()
        .to(bio, 0.35, { height: 0, opacity: 0 })
        .set(bio, { clearProps: 'all' })

      this.isExpanded = false
    }
  }
}
</script>

<style lang="scss" scoped>
.speaker-tile {
  @include tile-hover-shadow($desert, 'img', '.photo');
  display: block;

  &, &:hover {
    text-decoration: none;
    color: $body-color;
  }
}

.photo {
  @include image-ratio();
  margin-bottom: 1.125rem;
  background-color: rgba(#000, 0.1);
}

.name {
  margin-bottom: 0.5rem;
  font-size: 1.625rem;
  font-weight: 500;
}

.title {
  margin-bottom: 1.125rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.bio {
  display: none;
  overflow: hidden;
}

.toggle {
  font-size: 0.875rem;
  font-weight: 600;
  border-bottom: 1px solid rgba($body-color, 0.5);
  color: rgba($body-color, 0.5);
  cursor: pointer;
  transition: all 0.2s;

  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }

  &:hover {
    border-bottom-color: rgba($body-color, 0.9);
    color: rgba($body-color, 0.9);
  }
}
</style>
