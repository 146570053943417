<template>
  <div class="schedule">
    <div v-if="post" class="row">
      <div class="header">
        <h3 v-if="title" class="title" v-html="title"></h3>
      </div>

      <nav class="days">
        <a
          v-for="day in post.acf.days"
          :key="day.title"
          @click.prevent="select(day)"
          href
          :class="dayClass(day)">
          {{ day.title }}
        </a>
      </nav>

      <transition name="sessions" mode="out-in">
        <div class="sessions" :key="selected.title">
          <ScheduleSession v-for="(session, idx) in selected.sessions" :key="idx" :session="session" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import ScheduleSession from './ScheduleSession'

export default {
  name: 'Schedule',
  components: { ScheduleSession },
  props: {
    title: {
      type: String,
      default: 'Schedule'
    },
    schedule: {
      type: [Object, Number],
      required: true
    }
  },
  data () {
    return {
      post: null,
      selected: null
    }
  },
  watch: {
    schedule: 'fetch'
  },
  methods: {
    async fetch () {
      if (this.schedule === Object(this.schedule)) {
        this.post = this.schedule
      }
      this.post = await this.$store.dispatch('hydratePosts', {
        type: 'schedules',
        ids: this.schedule
      })
      this.select(this.post.acf.days[0])
    },

    select (day) {
      this.selected = day
    },

    dayClass (day) {
      return {
        day: true,
        active: this.selected === day
      }
    }
  },
  created () {
    this.fetch()
  }
}
</script>

<style lang="scss" scoped>
.schedule {

}

.header {
  @include make-col-ready();

  @include media-breakpoint-up(md) {
    @include make-col(2);
    @include make-col-offset(1);
  }
}

.days {
  @include make-col-ready();
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;

  @include media-breakpoint-up(md) {
    @include make-col(3);
    display: block;
    overflow-x: visible;
  }
}

.sessions {
  @include make-col-ready();

  @include media-breakpoint-up(md) {
    @include make-col(5);
  }
}

.title {
  @include headline();
  margin-bottom: 2.5rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

.day {
  position: relative;
  display: block;
  padding: 0.25rem 0 0.75rem 0;
  margin-right: 1.5rem;
  margin-bottom: 2.5rem;
  line-height: 1;
  font-size: 1rem;
  font-weight: 500;
  color: rgba($charcoal, 0.4);
  transition: color 0.25s;

  @include media-breakpoint-up(md) {
    margin-right: 0;
    font-size: 1.625rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 4px;
    background-color: $sea;
    transition: all 0.25s ease-in-out
  }

  &:hover {
    color: rgba($charcoal, 0.8);
  }

  &.active {
    color: $sea;

    &:after {
      width: 100%;
    }
  }
}

.sessions {

  &.sessions-enter-active {
    transition: all 0.35s;
  }

  &.sessions-leave-active {
    transition: opacity 0.25s;
  }

  &.sessions-enter {
    opacity: 0;
    transform: translate(25px, 0);
  }

  &.sessions-leave-to {
    opacity: 0;
  }
}
</style>
