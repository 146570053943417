<template lang="html">
  <nav v-if="show" class="carousel-controls">
    <div class="buttons">
      <a :class="previousClass" href @click.prevent="carousel.previous()">
        <i class="icon-chevron-left"></i>
      </a>
      <a :class="nextClass" href @click.prevent="carousel.next()">
        <i class="icon-chevron-right"></i>
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'CarouselControls',
  props: {
    carousel: {
      // Carousel component ref
      // (Required interface: next(), previous(), hasNext and hasPrevious)
      type: Object
    }
  },
  computed: {

    show () {
      return (
        this.carousel &&
        (!this.carousel.slideCount || this.carousel.slideCount > 1)
      )
    },

    previousClass () {
      return {
        previous: true,
        disabled: !this.carousel.hasPrevious
      }
    },

    nextClass () {
      return {
        next: true,
        disabled: !this.carousel.hasNext
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.carousel-controls {
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  margin-left: -1rem;
  margin-right: -1rem;
}

.next,
.previous {
  display: block;
  padding: 1rem;
  line-height: 1;
  font-size: 1rem;
  transition: all 0.2s;

  &:hover {
    color: $desert;
  }

  &:active {
    transform: scale(1.25);
  }

  &.disabled {
    color: $body-color;
    opacity: 0.2;

    &:hover {
      opacity: 0.3;
    }
  }
}
</style>
