<template>
  <article v-if="page" class="about-page">
    <Hero>
      <CursorButton slot="media" @click.prevent="showVideo" class="media">
        <Slideshow :images="page.acf.slideshow_images" />
      </CursorButton>

      <div slot class="main">
        <Logo color="#CA5421" />

        <div class="headline" v-html="page.acf.headline"></div>

        <template v-for="(block, idx) in page.acf.blocks.blocks">
          <div v-if="block.acf_fc_layout === 'content'" :key="idx" v-html="block.content"></div>
          <img v-if="block.acf_fc_layout === 'image'" :key="idx" :src="$wp.image(block.image, 'large')" :alt="block.alt" />
          <div v-if="block.acf_fc_layout === 'stats'" :key="idx" class="stats">
            <div v-for="(stat, idx) in block.stats" class="stat" :key="idx">
              <span class="value">{{ stat.value }}</span>
              <span class="label">{{ stat.label }}</span>
            </div>
          </div>
        </template>
      </div>
    </Hero>
  </article>
</template>

<script>
import PageMixin from './PageMixin'
import Hero from '../components/Hero'
import Logo from '../components/Logo'
import Slideshow from '../components/Slideshow'
import CursorButton from '../components/CursorButton'

export default {
  name: 'AboutPage',
  mixins: [PageMixin],
  components: { Hero, Logo, Slideshow, CursorButton },
  methods: {
    showVideo () {
      const { video_source, video_mp4, youtube_url } = this.page.acf
      let query = {}

      if (video_source === 'attachment') {
        query = {
          modal: 'gallery',
          attachmentId: video_mp4.id
        }
      } else if (video_source === 'youtube') {
        query = {
          modal: 'youtube',
          videoUrl: youtube_url
        }
      }

      this.$router.push({ ...this.$route, query })
    }
  }
}
</script>

<style lang="scss" scoped>
.about-page {}

.hero {
  background-color: $body-bg;
}

/deep/ .media {
  height: 500px;

  @include media-breakpoint-up(md) {
    height: 100vh;
  }
}

.media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.logo {
  width: 100%;
  max-width: 200px;
  margin-bottom: 2rem;

  @include media-breakpoint-up(md) {
    max-width: 300px;
  }

  @include media-breakpoint-up(md) {
    max-width: 380px;
    margin-bottom: 3.5rem;
  }
}

.headline {
  @include headline();
  margin-bottom: 3.5rem;
}

.main {
  margin: 64px 0;

  @include media-breakpoint-up(md) {
    max-width: 450px;
    margin: $page-margin-top auto;
  }

  /deep/ {
    line-height: 1.35;
    font-size: 1rem;
    font-weight: 300;
  }

  /deep/ img {
    width: 100%;
    height: auto;
    margin: 3.5rem 0;
  }

  /deep/ h1,
  /deep/ h2,
  /deep/ h3,
  /deep/ h4,
  /deep/ h5 {
    margin-bottom: 0.75rem;
    font-size: 1.25rem;
    font-weight: 500;
    color: $desert;
  }

  /deep/ p > a {
    display: inline-block;
    border-bottom: 1px solid $body-color;
    font-weight: 500;
    color: $body-color;

    &:hover {
      text-decoration: none;
    }
  }
}

.stats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -2rem 0 3.5rem;
}

.stat {
  width: 50%;
  margin-top: 2rem;

  .label,
  .value {
    display: block;
    line-height: 1;
    font-weight: 600;
  }

  .label {
    max-width: 180px;
  }

  .value {
    margin-bottom: 0.35rem;
    font-size: 3rem;
    color: $sun;

    @include media-breakpoint-up(sm) {
      font-size: 4.125rem;
    }
  }
}
</style>
