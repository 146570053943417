import { preload } from '../utils'

export default {
  install (Vue) {
    Vue.directive('preload', async function (el, binding) {
      const url = binding.value || el.src
      el.style.opacity = 0

      if (await preload(url)) {
        const style = window.getComputedStyle(el)
        if (!style.transition && !style.transitionDuration) {
          // Add CSS transition if it isn't set yet
          el.style.transition = 'opacity 0.3s'
        }
        el.style.opacity = 1
      }
    })
  }
}
