<template>

  <article v-if="page" class="partners-page">

    <!-- Header -->
    <header class="header">
      <div class="container">
        <h2 class="title">G'Day USA Partners</h2>
        <div class="headline" v-html="page.acf.headline"></div>
        <a :href="page.acf.button_link" class="button" v-html="page.acf.button_text"></a>
      </div>
    </header>

    <section class="content">

      <div class="container">

        <div class="tiers" :class="tiersClass">

          <!-- Sponsorship Tiers -->
          <div
            v-for="tier in orderedTiers"
            :key="tier.id"
            class="tier"
            :class="`tier--${tier.acf.level}`">
            <h3 class="tier-name" v-html="tier.name"></h3>

            <!-- Sponsors List (Per Tier) -->
            <div class="tier-partners">

              <div
                v-for="partner in tierPartners(tier).filter(partner => !partner.acf.sponsorship_has_ended)"
                :key="partner.if"
                class="partners-item">

                <!-- Sponsor -->
                <PartnerTile
                  :partner="partner"
                />

              </div>

            </div>

          </div>
        </div>
      </div>
    </section>

  </article>
</template>

<script>
import PageMixin from './PageMixin'
import PartnerTile from '../components/partners/PartnerTile'

/**
 * Sponsors page
 *
 * Note:
 *   We refer to sponsors as partners on the fontend. This is because ad blockers
 *   will remove elements have that the word "sponsor" in classnames.
 */
export default {
  name: 'SponsorsPage',
  mixins: [PageMixin],
  components: { PartnerTile },
  data () {
    return {
      tiers: [],
      partners: []
    }
  },
  computed: {
    orderedTiers () {
      return [...this.tiers].sort((a, b) => {
        return parseInt(a.acf.display_order, 10) - parseInt(b.acf.display_order, 10)
      })
    },

    tiersClass () {
      return {
        tiers: true,
        loaded: this.tiers.length && this.partners.length
      }
    }
  },
  methods: {
    async fetch () {
      let [tiers, partners] = await Promise.all([
        this.$store.dispatch('fetchPosts', { type: 'tiers', collection: 'tiers' }),
        this.$store.dispatch('fetchPosts', { type: 'sponsors', collection: 'sponsors' })
      ])

      this.tiers = tiers// await this.$store.dispatch('fetchPosts', { type: 'tiers', collection: 'tiers' })
      this.partners = partners// await this.$store.dispatch('fetchPosts', { type: 'sponsors', collection: 'sponsors' })
      // console.log("tiers", this.tiers, this.tiers.length)
      // console.log("partners",this.partners, this.partners.length)


    },

    tierPartners (tier) {
      /** not working in ie */
      // let that = this
      // const ordered = this.partners
      //   .filter( function (s) {
      //     return that.$wp.terms(s).map(function (s) {
      //       return s.id
      //     }).indexOf(tier.id) !== -1
      //   })
      // return ordered

      /** working in ie */
      let list = []
      const tierId = tier.id
      // for each partner get the ones in the tier
      for (let index = 0; index < this.partners.length; index++) {
        let partner = this.partners[index]
        if (partner.tiers.indexOf(tierId) !== -1) { // is match
          list.push(partner)
        }
      }
      // console.log('list',list)
      return list
    }
  },
  created () {
    this.fetch()
  }
}
</script>

<style lang="scss" scoped>
.partners-page {
  margin-top: 140px;

  @include media-breakpoint-up(md) {
    margin-top: $page-margin-top;
  }
}

.header {
  margin-bottom: 72px;
  text-align: center;

  @include media-breakpoint-up(md) {
    margin-bottom: 220px;
  }
}

.title {
  @include headline();
  font-weight: 600;
  margin-bottom: 2rem;
}

.headline {
  @include headline();
  max-width: 820px;
  margin: 0 auto;
}

.button {
  @include arrow-button();
  margin-top: 2rem;
}

.content {
  margin-bottom: 72px;
  padding-top: 1px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 180px;
  }
}

.tiers {
  margin-top: -72px;
  transform: translate(0, 30px);
  opacity: 0;
  transition: all 0.3s;

  &.loaded {
    transform: translate(0, 0);
    opacity: 1;
  }

  @include media-breakpoint-up(sm) {
    margin-top: -160px;
  }
}

.tier {
  margin-top: 72px;

  @include media-breakpoint-up(sm) {
    margin-top: 160px;
  }
}

.tier-name {
  margin: 0 0 3rem;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: $sea;

  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }
}

.tier-partners {
  @include make-custom-row($grid-gutter-width-sm);
  margin-top: -$grid-gutter-width-sm;

  @include media-breakpoint-up(sm) {
    @include make-row();
    margin-top: -90px;
  }
}

.partners-item {
  @include make-custom-col-ready($grid-gutter-width-sm);
  margin-top: $grid-gutter-width-sm;

  @include media-breakpoint-up(sm) {
    @include make-col-ready();
    margin-top: 90px;
  }
}

.tier--primary {
  .partners-item {
    @include make-col(6);

    @include media-breakpoint-up(sm) {
      @include make-col(4);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(3);
    }
  }
}

.tier--secondary {
  .partners-item {
    @include make-col(6);

    @include media-breakpoint-up(sm) {
      @include make-col(4);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(3);
    }

    // @include media-breakpoint-up(md) {
    //   @include make-col(3);
    // }

    // @include media-breakpoint-up(xl) {
    //   @include make-col(2);
    // }
  }
}
</style>
