<template>
  <div :class="elClass">
    <form ref="form" class="form" @submit="handleSubmit">
      <h3 v-if="title" class="title" v-html="title"></h3>

      <div class="form-group">
        <input type="email" name="email" placeholder="Email" class="form-control" v-model="form.email" required />

        <button type="submit" class="submit-button">
          <i class="icon-arrow-right"></i>
        </button>
      </div>
    </form>

    <div class="success">
      Thank you for<br />signing up
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubscribeForm',
  props: {
    title: String
  },
  data () {
    return {
      isSuccess: false,
      form: {
        email: null
      }
    }
  },
  computed: {
    elClass () {
      return {
        'subscribe-form': true,
        'show-success': this.isSuccess
      }
    }
  },
  methods: {

    handleSubmit (e) {
      e.preventDefault()
      this.submit()
    },

    async submit () {
      if (!this.form.email) {
        return
      }

      const res = await this.$wp.api.post('/gday/v1/newsletter', {
        data: {
          email: this.form.email
        }
      })

      if (!res.success) {
        // eslint-disable-next-line
        console.error('Error saving subscriber')
      }

      this.isSuccess = true
    }
  }
}
</script>

<style lang="scss" scoped>
.subscribe-form {
  position: relative;
  text-align: center;
  background-color: $desert;
  color: $pearl;
}

.title {
  margin-bottom: 2rem;
  font-size: 1.25rem;
  line-height: 1.15;
  font-weight: normal;

  @include media-breakpoint-up(md) {
    font-size: 2.25rem;
  }
}

.form {
  transition: all 0.3s;
}

.form-group {
  position: relative;
}

.form-control {
  transition: color 0.2s;

  &:valid {
    color: $desert;
  }
}

.submit-button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 1.25rem;
  line-height: $input-height;
  border: none;
  font-size: 2.5rem;
  vertical-align: middle;
  background-color: transparent;
  opacity: 0.2;
  transition: all 0.2s;
  cursor: pointer;

  i {
    vertical-align: text-bottom;
  }

  &:hover {
    opacity: 0.5;
  }

  :valid + & {
    opacity: 1;

    i {
      color: $desert;
    }
  }
}

.success {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  line-height: 1.3;
  font-size: 2.25rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s 0.3s;
}

.show-success {

  .form {
    opacity: 0;
  }

  .success {
    opacity: 1;
    visibility: visible;
  }
}
</style>
