<template>
  <article class="champion champion-tile">
    <div class="media">
      <div class="ratio"></div>
      <img class="image" :src="$wp.thumbnail(champion)" :alt="champion.title.rendered" v-preload />
    </div>

    <header class="header">
      <h4 class="name" v-html="champion.title.rendered"></h4>
      <p class="title">
        <span class="position">{{ champion.acf.position_title }}</span>
        <span class="company">{{ champion.acf.company_name }}</span>
      </p>
    </header>

    <section ref="bio" class="bio">
      <div v-show="!isExpanded" class="excerpt" v-html="bioPreview"></div>
      <div v-show="isExpanded" class="full" v-html="champion.content.rendered"></div>
    </section>

    <a href @click.prevent="toggle" class="bio-toggle">
      <span v-if="!isExpanded">Read more</span>
      <span v-if="isExpanded">Read less</span>
    </a>
  </article>
</template>

<script>
import TimelineLite from 'gsap/TimelineLite'
import { truncate } from '../../utils'

export default {
  name: 'ChampionTile',
  props: {
    champion: {
      type: Object,
      required: true
    }
  },
  watch: {
    champion: 'getBioPreview'
  },
  data () {
    return {
      isExpanded: false,
      bioPreview: ''
    }
  },
  methods: {
    getBioPreview () {
      const el = document.createElement('div')
      el.innerHTML = this.champion.content.rendered
      this.bioPreview = truncate(el.innerText, 24)
    },

    toggle () {
      if (this.isExpanded) {
        this.collapse();
      } else {
        this.expand();
      }
    },

    expand () {
      if (this.isExpanded) {
        return
      }

      const { bio } = this.$refs
      const excerpt = bio.querySelector('.excerpt')
      const full = bio.querySelector('.full')
      const height = bio.offsetHeight

      new TimelineLite()
        .set(excerpt, { display: 'none' })
        .set(full, { display: 'block' })
        .from(bio, 0.5, { height, immediateRender: false })
        .set(bio, { clearProps: 'all' })

      this.isExpanded = true
    },

    collapse () {
      if (!this.isExpanded) {
        return
      }

      const { bio } = this.$refs
      const excerpt = bio.querySelector('.excerpt')
      const full = bio.querySelector('.full')
      const height = bio.offsetHeight

      new TimelineLite()
        .set(excerpt, { display: 'block' })
        .set(full, { display: 'none' })
        .from(bio, 0.5, { height, immediateRender: false })
        .set(bio, { clearProps: 'all' })

      this.isExpanded = false
    }
  },
  created () {
    this.getBioPreview()
  }
}
</script>

<style lang="scss" scoped>
.champion-tile {
  @include tile-hover-shadow($desert, '.image', '.media');
  display: block;
  position: relative;
}

.media {
  @include image-ratio(percentage(542/453));
  margin-bottom: 2rem;
  background-color: rgba(#000, 0.1);
}

.name {
  font-size: 1.25rem;
  font-weight: 500;

  @include media-breakpoint-up(md) {
    font-size: 1.625rem;
  }
}

.title {

}

.position,
.company {
  display: block;
}

.bio {
  overflow: hidden;
}

.bio-toggle {
  display: inline-block;
  margin-top: 1rem;
  border-bottom: 1px solid rgba($charcoal, 0.5);
  font-weight: 600;
  color: rgba($charcoal, 0.5);
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    color: rgba($charcoal, 0.75);
    text-decoration: none;
  }
}
</style>
