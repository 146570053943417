<template>
  <a :href="post.acf.post_url" target="_blank" class="social-post social-post-tile">
    <div class="ratio"></div>
    <img
      v-if="$wp.thumbnail(post)"
      :src="$wp.thumbnail(post)"
      :alt="post.title.rendered"
      class="image"
      v-preload />
    <i class="platform" :class="platformIcon"></i>
  </a>
</template>

<script>
export default {
  name: 'SocialPostTile',
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  computed: {
    platformIcon () {
      return {
        'instagram': 'icon-instagram'
      }[this.post.acf.platform]
    }
  }
}
</script>

<style lang="scss" scoped>
.social-post-tile {
  @include tile-hover-shadow($desert, '.image, .platform');
  display: block;

  &, &:hover {
    text-decoration: none;
  }
}

.ratio {
  padding-top: 100%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: rgba(#000, 0.1);
}

.platform {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  line-height: 1;
  font-size: 1.125rem;
  text-shadow: 1px 1px 2px rgba(#000, 0.3);
  color: #fff;
}
</style>
