import VueRouter from 'vue-router'
import Sponsor from './pages/Sponsor'
import Event from './pages/Event'
import templates from './pages/templates'

// Empty component if we don't have a page
const noop = {
  render: () => {}
}

function makeStaticRoutes (rewrites) {
  const routes = [
    {
      path: '/events/:slug',
      name: 'event',
      props: true,
      component: Event
    },
    {
      path: '/sponsors/:slug',
      name: 'sponsor',
      props: true,
      component: Sponsor
    }
  ]

  const rewrittenRoutes = rewrites.map(rule => {
    const match = routes.find(route => route.name === rule.type)
    return {
      ...match,
      path: `/${rule.path}`,
      props: route => ({
        ...route.params,
        ...route.query,
        ...rule.params
      })
    }
  })

  return [...rewrittenRoutes, ...routes]
}

function makeDynamicRoutes (pages) {
  return pages.map(page => {
    const { link_relative: path, slug: name, slug, template } = page
    const component = templates[template] || noop
    return { path, name, props: { slug }, component }
  })
}

function addRewriteRedirects (router, rewrites) {
  router.beforeEach((to, from, next) => {
    const trimmedPath = to.path.replace(/^\/|\/$/g, '')
    const matched = rewrites.find(r => r.original_path === trimmedPath)
    if (matched) {
      next({ path: `/${matched.path}` })
      return
    }
    next()
  })
}

export default function makeRouter (pages, rewrites = []) {
  const router = new VueRouter({
    mode: 'history',
    routes: [
      ...makeDynamicRoutes(pages),
      ...makeStaticRoutes(rewrites)
    ]
  })

  addRewriteRedirects(router, rewrites)

  return router
}
