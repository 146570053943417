/**
 * Polyfills
 * elementsfrompoint.js
 * https://gist.github.com/iddan/54d5d9e58311b0495a91bf06de661380
 */
if (!document.elementsFromPoint) {
  document.elementsFromPoint = elementsFromPoint;
}

function elementsFromPoint(x, y) {
  var parents = [];
  var parent = void 0;
  do {
      if (parent !== document.elementFromPoint(x, y)) {
          parent = document.elementFromPoint(x, y);
          parents.push(parent);
          parent.style.pointerEvents = 'none';
      } else {
          parent = false;
      }
  } while (parent);
  parents.forEach(function (parent) {
      return parent.style.pointerEvents = 'all';
  });
  return parents;
}

/**
 * state the exported content
 */
export function get (obj, path) {
  const hasOwnProp = Object.prototype.hasOwnProperty
  let keys = Array.isArray(path) ? path : path.split('.')
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i]
    if (!obj || !hasOwnProp.call(obj, key)) {
      obj = undefined
      break
    }
    obj = obj[key]
  }
  return obj
}

export function fromPairs (pairs) {
  let index = -1
  let length = pairs == null ? 0 : pairs.length
  let result = {}

  while (++index < length) {
    let pair = pairs[index]
    result[pair[0]] = pair[1]
  }

  return result
}

export function sameRoute (to, from) {
  return to.path.replace(/^\/|\/$/g, '') === from.path.replace(/^\/|\/$/g, '')
}

export function setPageTitle(post) {
  let title

  if (!post || post.slug === 'home') {
    title = `G’Day USA`
  } else {
    // Fix any special chars from wordpress
    const el = document.createElement('div')
    el.innerHTML = post.title.rendered
    title = `${el.innerText} - G’Day USA`
  }

  document.title = title
}

const preloadedImages = []
export function preload (url) {
  if (!url) {
    return Promise.resolve(false)
  }

  if (Array.isArray(url)) {
    return Promise.all(url.map(url => preload(url)))
  }

  if (preloadedImages.indexOf(url) !== -1) {
    return Promise.resolve(true)
  }

  return new Promise(resolve => {
    var img = new Image()
    img.src = url
    img.onload = () => {
      preloadedImages.push(url)
      resolve(true)
    }
    img.onerror = () => {
      resolve(false)
    }
  })
}

export function timelinePromise (timeline) {
  return new Promise((resolve) => {
    timeline.eventCallback('onComplete', () => resolve(true))
  })
}

export function getImageBehind (elem) {
  const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = elem
  const left = offsetLeft + (offsetWidth / 2)
  const top = offsetTop + (offsetHeight / 2)

  return [
    ...document.elementsFromPoint(left, top)
  ]
  .filter(el => el && el !== elem)
  .find(el => el && el.tagName.toLowerCase() === 'img')
}

/**
 * Add unique keys to an array
 */
var keyCounter = 0
export function keyed (items) {
  return [...items].map(i => {
    i.key = i.key || `key-${++keyCounter}`
    return i
  })
}

export function chunkArray (input, size) {
  var index = 0
  var count = input.length
  var output = []

  for (index = 0; index < count; index += size) {
    const chunk = input.slice(index, index + size)
    output.push(chunk)
  }

  return output
}

export function truncate (str, words = 24, ending = '…') {
  const count = str.split(' ').length
  str = str.split(' ').splice(0, words).join(' ')
  return (count > words) ? str + ending : str
}

export function fitVideo (container, video) {
  let containerDimens = container.getBoundingClientRect()

  let videoDimens = {
    width: video.videoWidth,
    height: video.videoHeight
  }

  let videoRatio = videoDimens.width / videoDimens.height
  let videoHeight = containerDimens.height
  let videoWidth = containerDimens.height * videoRatio

  if (videoWidth < containerDimens.width) {
    videoHeight = containerDimens.width * videoRatio
    videoWidth = containerDimens.width
  }

  return {
    width: Math.ceil(videoWidth) + 'px',
    height: Math.ceil(videoHeight) + 'px'
  }
}

/**
 * Taken from the vue-router source for their router-link component
 * Source: https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
 */
export function guardEvent (e) {
  // don't redirect with control keys
  if (e.metaKey || e.ctrlKey || e.shiftKey) return
  // don't redirect when preventDefault called
  if (e.defaultPrevented) return
  // don't redirect on right click
  if (e.button !== undefined && e.button !== 0) return
  // don't redirect if `target="_blank"`
  if (e.currentTarget && e.currentTarget.getAttribute) {
    const target = e.currentTarget.getAttribute('target')
    if (/\b_blank\b/i.test(target)) return
  }
  // this may be a Weex event which doesn't have this method
  if (e.preventDefault) {
    e.preventDefault()
  }
  return true
}


export function findParentLink (el) {
  if (el.tagName === 'A') {
    return el
  }

  while ((el = el.parentElement)) {
    if (el.tagName === 'A') {
      return el
    }
  }

  return null
}


export function linkPath (el) {
  if (!el) {
    return false
  }

  if (!el.getAttribute('href')) {
    return false
  }

  if (el.getAttribute('href').indexOf('#') === 0) {
    return el.getAttribute('href')
  }

  let { href } = el
  let origin = window.location.origin

  // Make the absolute link relative
  if (href.indexOf(origin) === 0) {
    return href.replace(origin, '')
  }

  if (href.indexOf('/') === 0) {
    return href
  }

  return false
}
