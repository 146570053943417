import { render, staticRenderFns } from "./Terms.vue?vue&type=template&id=4669df7d&scoped=true&"
import script from "./Terms.vue?vue&type=script&lang=js&"
export * from "./Terms.vue?vue&type=script&lang=js&"
import style0 from "./Terms.vue?vue&type=style&index=0&id=4669df7d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4669df7d",
  null
  
)

component.options.__file = "Terms.vue"
export default component.exports