<template>
  <div class="session">
    <time class="time">{{ session.time }}</time>

    <div class="content">
      <span class="title" v-html="session.title"></span>

      <div
        v-if="session.description"
        ref="description"
        class="description"
        v-html="session.description" />

      <a v-if="session.description" href @click.prevent="toggle" class="toggle">
        <span v-if="isExpanded">Read less</span>
        <span v-else>Read more</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScheduleSession',
  props: {
    session: {
      type: [Object, Number],
      required: true
    }
  },
  data () {
    return {
      isExpanded: false
    }
  },
  methods: {
    toggle () {
      if (this.isExpanded) {
        this.collapse()
      } else {
        this.expand()
      }
    },

    expand () {
      if (this.isExpanded) {
        return
      }

      const { description } = this.$refs
      if (!description) {
        return
      }

      new TimelineLite()
        .set(description, { display: 'block' })
        .from(description, 0.5, { height: 0, immediateRender: false })
        .from(description, 0.5, { opacity: 0 }, '-=0.25')

      this.isExpanded = true
    },

    collapse () {
      if (!this.isExpanded) {
        return
      }

      const { description } = this.$refs
      if (!description) {
        return
      }

      new TimelineLite()
        .to(description, 0.35, { height: 0, opacity: 0 })
        .set(description, { clearProps: 'all' })

      this.isExpanded = false
    }
  }
}
</script>

<style lang="scss" scoped>
.session {
  display: flex;
  margin-bottom: 1.5rem;
}

.time {
  display: block;
  min-width: 100px;
  padding-right: 1rem;
  font-weight: 600;
  color: $sea;

  @include media-breakpoint-up(sm) {
    min-width: 130px;
  }
}

.title {
  display: block;
  margin-bottom: 0.25rem;
  font-weight: 600;
}

.description {
  padding: 0.5rem 0 1rem;
  display: none;
  overflow: hidden;

  /deep/ p:last-child {
    margin-bottom: 0;
  }
}

.toggle {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  border-bottom: 1px solid rgba($body-color, 0.5);
  color: rgba($body-color, 0.5);
  cursor: pointer;
  transition: all 0.2s;

  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }

  &:hover {
    border-bottom-color: rgba($body-color, 0.9);
    color: rgba($body-color, 0.9);
  }
}
</style>
