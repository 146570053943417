<template>
  <transition name="youtube-modal" mode="out-in" appear>
    <div v-if="isOpen" class="youtube-modal">
      <a @click.prevent="close" href class="close">
        <i class="icon-close"></i>
      </a>

      <transition name="media" mode="out-in" appear>
        <div class="media">
          <YoutubeVideo :videoUrl="videoUrl" :autoplay="true" />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import YoutubeVideo from '../YoutubeVideo'

export default {
  name: 'YoutubeModal',
  components: { YoutubeVideo },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    videoUrl: {
      type: String
    }
  },
  data () {
    return {
      isOpen: this.show
    }
  },
  watch: {
    show () {
      this.isOpen = this.show
    }
  },
  methods: {
    close () {
      const query = { ...this.$route.query }
      delete query.modal
      delete query.videoUrl
      this.$router.push({ ...this.$route, query })
      this.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
.youtube-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba($body-color, 0.95);

  &.youtube-modal-enter-active,
  &.youtube-modal-leave-active {
    transition: all 0.35s;
  }

  &.youtube-modal-enter,
  &.youtube-modal-leave-to {
    transform: scale(0.95);
    opacity: 0;
  }
}

.close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  padding: 2rem;
  font-size: 1.625rem;
  z-index: 10;
  line-height: 1;
  color: #A8A8A8;
  transition: color 0.2s;

  &:hover {
    color: #fff;
  }
}

.media {
  position: absolute;
  top: 64px;
  bottom: 64px;
  left: 30px;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(lg) {
    top: 120px;
    bottom: 120px;
    left: 260px;
    right: 260px;
  }
  &.media-enter-active,
  &.media-leave-active {
    transition: all 0.35s;
  }

  &.media-enter,
  &.media-leave-to {
    transform: scale(0.95);
    opacity: 0;
  }
}
</style>
