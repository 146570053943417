import Vue from 'vue'
import Vuex from 'vuex'
import { Api } from './api'
import { get } from './utils'

Vue.use(Vuex)

const defaultParams = {
  per_page: 100,
  _embed: 1
}

const previewParams = {}
new URLSearchParams(document.location.search).forEach((value, key) => {
  if (key.indexOf('preview') !== -1) {
    previewParams[key] = value
  }
})

function identity (posts) {
  if (!Array.isArray(posts)) {
    posts = [posts]
  }
  const result = {}
  posts.forEach(post => result[post.id] = post)
  return result
}

function first (posts) {
  return posts && posts.length > 0 ? posts[0] : null
}

export default new Vuex.Store({
  state: {
    posts: {},
    global: {},
    menus: {},
    collections: {},
    photosVideos: null
  },

  mutations: {
    receivePosts (state, payload) {
      const result = { ...state.posts }
      for (const type in payload) {
        payload[type].forEach(posts => {
          result[type] = { ...result[type], ...identity(posts) }
        })
      }
      state.posts = result
    },

    receiveCollection (state, payload) {
      state.collections[payload.name] = {
        ...payload,
        posts: payload.posts.map(p => p.id ? p.id : p)
      }
    },

    receiveMenus (state, payload) {
      state.menus = {
        ...state.menus,
        ...payload
      }
    },

    receiveGlobal (state, payload) {
      state.global = {
        ...state.global,
        ...payload
      }
    },

    receivePhotosVideos (state, payload) {
      state.photosVideos = payload
    }
  },

  getters: {

    getPostById: (state) => (type, id) => get(state, `posts.${type}.${id}`),

    getCollection: (state, getters) => (name) => {
      const posts = get(state, `collections.${name}.posts`)
      const type = get(state, `collections.${name}.type`)
      return posts && type ? getters.getAllPostsByIds(type, posts) : false
    },

    getPostBySlug: (state) => (type, slug) => {
      return Object.values(state.posts[type] || {}).find(p => p.slug === slug)
    },

    /**
     * Hydrates a list of ids with posts
     *
     * (Returns false if posts are missing)
     */
    getAllPostsByIds: (state, getters) => (type, ids) => {
      const result = (ids || []).map(id => getters.getPostById(type, id)).filter(p => !!p)
      return result.length === ids.length ? result : false
    }
  },

  actions: {

    async fetchPosts ({ dispatch }, { type, query, collection }) {
      return dispatch('fetchPostsByQuery', { type, query, collection })
    },

    async fetchPostById ({ dispatch, getters }, { type, id }) {
      return (
        getters.getPostById(type, id) ||
        first(await dispatch('fetchPostsByIds', { type, ids: [id] }))
      )
    },

    async fetchPostBySlug ({ dispatch, getters }, { type, slug }) {
      return (
        getters.getPostBySlug(type, slug) ||
        first(await dispatch('fetchPostsByQuery', { type, query: { slug } }))
      )
    },

    async fetchPostsByIds ({ dispatch, getters }, { type, ids, collection }) {
      return (
        getters.getAllPostsByIds(type, ids) ||
        await dispatch('fetchPostsByQuery', {
          type,
          query: {
            include: ids.join(','),
            orderby: 'include'
          },
          collection
        })
      )
    },

    async fetchPostByQuery ({ dispatch }, { type, query }) {
      return first(await dispatch('fetchPostsByQuery', { type, query }))
    },

    async fetchPostsByQuery ({ commit, getters }, { type = 'posts', query = {}, collection = false }) {
      const cached = collection && getters.getCollection(collection)
      if (cached) {
        return cached
      }

      const path = `/wp/v2/${type}`
      const result = await Api.get(`${path}`, {
        params: {
          ...defaultParams,
          ...previewParams,
          ...query
        }
      })

      commit('receivePosts', { [type]: result })

      if (collection) {
        commit('receiveCollection', {
          name: collection,
          type,
          query,
          posts: result
        })
      }

      return result
    },

    /**
     * Hydrate posts
     *
     * Takes and array (or single) of post ids and/or objects
     * and then fetches the missing post objects.
     */
    async hydratePosts ({ dispatch }, { type, ids = [] }) {
      if (Array.isArray(ids)) {
        if (!ids.length || ids[0] === Object(ids[0])) {
          return ids
        }
        return dispatch('fetchPostsByIds', { type, ids })
      } else if (ids === Object(ids)) {
        return ids
      } else if (Number.isInteger(parseInt(ids, 10))) {
        return dispatch('fetchPostById', { type, id: ids })
      }
    },

    async getPhotosAndVideos ({ state, commit }, { page }) {
      if (!page) {
        return null
      }

      if (state.photosVideos) {
        return state.photosVideos
      }

      const data = await Api.get('gday/v1/pages/photos-videos', {
        params: {
          post_id: page.id
        }
      })

      commit('receivePhotosVideos', data)
      return data
    }
  }
})
