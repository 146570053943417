<template>
  <article v-if="page" class="terms-page">
    <header class="header container">
      <div class="row">
        <div class="headline" v-html="page.acf.headline"></div>
      </div>
    </header>

    <section class="content container">
      <div class="row">
        <div class="body" v-html="page.acf.content"></div>
      </div>
    </section>
  </article>
</template>

<script>
import PageMixin from './PageMixin'

export default {
  name: 'TermsPage',
  mixins: [PageMixin]
}
</script>

<style lang="scss" scoped>
.terms-page {
  margin-top: 140px;
  padding-bottom: 140px;

  @include media-breakpoint-up(md) {
    margin-top: $page-margin-top;
    padding-bottom: 340px;
  }
}

.header {
  margin-bottom: 4.5rem;
}

.headline {
  @include make-col-ready();
  @include make-col(10);
  @include headline();

  @include media-breakpoint-up(md) {
    @include make-col-offset(1);
    @include make-col(7);
  }

  @include media-breakpoint-up(lg) {
    @include make-col-offset(2);
    @include make-col(5);
  }
}

.body {
  @include make-col-ready();
  @include make-col-offset(1);
  @include make-col(11);
  font-weight: 300;

  @include media-breakpoint-up(smd) {
    @include make-col-offset(4);
    @include make-col(8);
  }

  @include media-breakpoint-up(md) {
    @include make-col-offset(5);
    @include make-col(7);
  }

  @include media-breakpoint-up(lg) {
    @include make-col-offset(6);
    @include make-col(5);
  }

  @include media-breakpoint-up(xl) {
    @include make-col(4);
  }

  /deep/ > ul {
    margin: 0;
    padding: 0;
    list-style: none;

    ul {
      padding-left: 1.25rem;
      list-style: disc;

      li {
        margin-top: 1.25rem;
        margin-bottom: 0;
      }
    }
  }

  /deep/ > ul > li {
    position: relative;
    margin-bottom: 1.875rem;

    &:before {
      @extend .icon-font;
      content: $icon-arrow-right;
      position: absolute;
      top: -0.15rem;
      right: 100%;
      margin-right: 1.5rem;
      line-height: 1;
      font-size: 1.85rem;
      color: $sun;
    }
  }
}
</style>
