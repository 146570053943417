<template>
  <header class="partner-about">
    <div class="container">
      <div class="inner">
        <div class="media">
          <img
            v-if="block && block.image"
            class="cover-image"
            :src="$wp.image(block.image)"
            :alt="partner.title.rendered" />
          <h3 class="title" v-html="`About<br />${partner.title.rendered}`"></h3>
        </div>

        <div class="main">
          <div class="content">
            <div class="body" v-html="partner.content.rendered"></div>

            <div class="social">
              <strong class="social-label">{{ socialLabel }}</strong>
              <div class="social-links">
                <a v-for="social in socials" :key="social.href" :href="social.url" target="_blank">
                  <i :class="social.icon"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AboutPartner',
  props: {
    partner: {
      type: Object,
      required: true
    },
    block: {
      type: Object
    }
  },
  computed: {
    socialLabel () {
      return `Follow ${this.partner.title.rendered} at`
    },

    socials () {
      return [
        { url: this.partner.acf.instagram, icon: 'icon-instagram' },
        { url: this.partner.acf.twitter, icon: 'icon-twitter' },
        { url: this.partner.acf.facebook, icon: 'icon-facebook' }
      ].filter(s => !!s.url)
    }
  }
}
</script>

<style lang="scss" scoped>
.partner-about {
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.media,
.main {
  position: relative;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 50%;
  }
}

.cover-image {
  width: 100%;
  height: 400px;
  object-fit: cover;

  @include media-breakpoint-up(md) {
    height: 100%;
  }
}

.title {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate(0, -50%);
  padding-left: 60px;
  padding-right: 30px;
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;

  @include media-breakpoint-up(md) {
    padding-left: 60px;
    padding-right: 30px;
    font-size: 3.5rem;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 140px;
    padding-right: 60px;
    font-size: 5.5rem;
  }
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.content {
  width: 100%;

  @include media-breakpoint-up(md) {
    max-width: 400px;
  }
}

.subtitle {
  margin-bottom: 1.75rem;
  font-weight: bold;
  text-transform: uppercase;
  color: rgba($charcoal, 0.4);
}

.logo {
  max-width: 320px;
  max-height: 120px;
  margin-bottom: 1.75rem;
}

.excerpt {
  line-height: 1.2;
  font-size: 1.25rem;
}

.learn-more {
  display: inline-block;
  margin-top: 1rem;
}

.social {
  margin-top: 1.5rem;
}

.social-links {
  margin-left: -0.625rem;

  a {
    display: inline-block;
    padding: 0.625rem;
    font-size: 1.25rem;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
