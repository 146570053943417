import { render, staticRenderFns } from "./GalleryModalCarousel.vue?vue&type=template&id=e45dd436&scoped=true&"
import script from "./GalleryModalCarousel.vue?vue&type=script&lang=js&"
export * from "./GalleryModalCarousel.vue?vue&type=script&lang=js&"
import style0 from "./GalleryModalCarousel.vue?vue&type=style&index=0&id=e45dd436&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e45dd436",
  null
  
)

component.options.__file = "GalleryModalCarousel.vue"
export default component.exports