<template>
  <CarouselFrame
    class="galleries-carousel"
    :title="title"
    :buttonText="buttonText"
    :onButtonClick="onButtonClick"
    :carousel="$refs.carousel">
    <TileCarousel ref="carousel" :items="items" :groupSize="groupSize" :loading="isLoading">
      <GalleryTile slot-scope="item" :gallery="item.item" :aspectRatio="aspectRatio" />
    </TileCarousel>
  </CarouselFrame>
</template>

<script>
import TileCarousel from '../TileCarousel'
import CarouselFrame from '../CarouselFrame'
import GalleryTile from './GalleryTile'

export default {
  name: 'GalleriesCarousel',
  components: { TileCarousel, CarouselFrame, GalleryTile },
  props: {
    title: {
      type: String,
      default: 'Photo galleries'
    },
    galleries: {
      type: Array,
      default: () => []
    },
    aspectRatio: {
      type: Number,
      default: 567/453
    },
    buttonText: String,
    onButtonClick: Function
  },
  data () {
    return {
      isLoading: false,
      groupSize: 3,
      items: []
    }
  },
  watch: {
    galleries: 'fetch'
  },
  methods: {
    async fetch () {
      this.isLoading = true
      this.items = await this.$store.dispatch('hydratePosts', {
        type: 'galleries',
        ids: this.galleries
      })
      this.isLoading = false
    }
  },
  created () {
    this.fetch()
  }
}
</script>
