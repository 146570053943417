<template>
  <section :class="elClass">
    <div class="container">
      <div class="row">
        <div class="content">
          <h4 class="title" v-html="block.title"></h4>
          <div class="body" v-html="block.content"></div>
        </div>

        <div v-if="hasMedia" class="media">
          <PlayableAttachment
            v-if="hasVideo"
            :ratio="9/16"
            :poster="$wp.image(block.poster_image, 'large')"
            :onClick="showVideo" />

          <img
            v-if="hasImage"
            :src="$wp.image(block.image)"
            :alt="block.title"
            class="image" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PlayableAttachment from '../galleries/PlayableAttachment'

export default {
  name: 'PartnerContentBlock',
  components: { PlayableAttachment },
  props: {
    block: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: 'small'
    }
  },
  computed: {
    elClass () {
      return {
        'partner-content': true,
        [`partner-content--${this.size}`]: true
      }
    },

    hasVideo () {
      return !!this.block.video_mp4
    },

    hasImage () {
      return !!this.block.image && !this.hasVideo
    },

    hasMedia () {
      return this.hasImage || this.hasVideo
    }
  },
  methods: {
    showVideo () {
      const { video_source, video_mp4, youtube_url } = this.block
      let query = {}

      if (video_source === 'attachment') {
        query = {
          modal: 'gallery',
          attachmentId: video_mp4.id
        }
      } else if (video_source === 'youtube') {
        query = {
          modal: 'youtube',
          videoUrl: youtube_url
        }
      }

      this.$router.push({ ...this.$route, query })
    }
  }
}
</script>

<style lang="scss" scoped>
.partner-content {}

.content,
.media {
  @include make-col-ready();
}

.title,
.body {
  @include media-breakpoint-up(md) {
    max-width: 400px;
  }
}

.title {
  @include headline('large');
}

.body {
  line-height: 1.3;
}

.image {
  width: 100%;
  height: auto;
}

.partner-content {

  &--small {
    margin: 72px 0;

    @include media-breakpoint-up(md) {
      margin: 90px 0;
    }

    .content {

      @include media-breakpoint-up(md) {
        @include make-col(5);
        @include make-col-offset(1);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(4);
        @include make-col-offset(2);
      }
    }

    .media {
      margin-top: 1rem;

      @include media-breakpoint-up(md) {
        @include make-col(5);
        margin-top: 0;
      }

      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }
    }
  }

  &--large {
    margin: 72px 0;

    @include media-breakpoint-up(md) {
      margin: 140px 0;
    }

    .row {
      align-items: center;
    }

    .content {
      @include media-breakpoint-up(md) {
        @include make-col(5);
      }
    }

    .media {
      margin-top: 1.5rem;

      @include media-breakpoint-up(md) {
        @include make-col(7);
        margin-top: 0;
      }
    }
  }
}
</style>
