<template>
  <div class="galleries-list">
    <div v-for="gallery in items" :key="gallery.id" class="gallery">
      <div class="gallery-header">
        <h4 class="gallery-title" v-html="gallery.title.rendered"></h4>
        <a v-if="buttonText" @click.prevent="onButtonClick(gallery)" class="button" v-html="buttonText"></a>
      </div>

      <div class="gallery-media">
        <div
          v-for="attachment in gallery.acf.media.slice(0, 4)"
          :key="attachment.id"
          class="media-item">
          <PhotoTile :attachment="attachment" :gallery="gallery" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhotoTile from './PhotoTile'

export default {
  name: 'GalleriesList',
  components: { PhotoTile },
  props: {
    galleries: {
      type: Array,
      default: () => []
    },
    buttonText: {
      type: String
    },
    onButtonClick: {
      type: Function,
      default: () => () => {}
    }
  },
  data () {
    return {
      items: []
    }
  },
  watch: {
    galleries: 'fetch'
  },
  methods: {
    async fetch () {
      this.items = await this.$store.dispatch('hydratePosts', {
        type: 'galleries',
        ids: this.galleries
      })
    }
  },
  created () {
    this.fetch()
  }
}
</script>

<style lang="scss" scoped>
.galleries-list {

}

.gallery {
  margin-bottom: 140px;

  &:last-child {
    margin-bottom: 0;
  }
}

.gallery-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
}

.gallery-title {
  @include headline();
  display: inline-block;
  margin-bottom: 0.125rem;
  font-size: 1rem;
}

.button {
  @include arrow-button();
  margin-left: 1.25rem;
  font-size: 0.75rem;

  @include media-breakpoint-up(md) {
    margin-left: 2rem;
    font-size: 1rem;
  }
}

.gallery-media {
  @include make-custom-row($grid-gutter-width * 0.5);
  margin-top: $grid-gutter-width * -0.5;

  @include media-breakpoint-up(md) {
    @include make-row();
    margin-top: 0;
  }
}

.media-item {
  @include make-custom-col-ready($grid-gutter-width * 0.5);
  @include make-col(6);
  margin-top: $grid-gutter-width * 0.5;

  @include media-breakpoint-up(md) {
    @include make-col-ready();
    @include make-col(3);
    margin-top: 0;
  }
}
</style>
