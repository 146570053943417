import { render, staticRenderFns } from "./FeaturedSponsorBlock.vue?vue&type=template&id=5c4f7520&scoped=true&"
import script from "./FeaturedSponsorBlock.vue?vue&type=script&lang=js&"
export * from "./FeaturedSponsorBlock.vue?vue&type=script&lang=js&"
import style0 from "./FeaturedSponsorBlock.vue?vue&type=style&index=0&id=5c4f7520&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c4f7520",
  null
  
)

component.options.__file = "FeaturedSponsorBlock.vue"
export default component.exports