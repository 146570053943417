<template>
  <header class="partner-header">
    <div class="container">
      <div class="row">
        <div class="logo">
          <div class="box">
            <img :src="$wp.partnerThumbnail(partner, 'full')" :alt="partner.title.rendered" />
          </div>
        </div>

        <div class="main">
          <p class="subtitle">Featured Sponsor</p>
          <h1 class="title" v-html="partner.title.rendered"></h1>
          <div class="excerpt" v-html="partner.excerpt.rendered"></div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'PartnerSecondaryHeader',
  props: {
    partner: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.partner-header {
  margin-bottom: 48px;

  @include media-breakpoint-up(md) {
    margin-bottom: 110px;
  }
}

.main {
  @include make-col-ready();

  @include media-breakpoint-up(md) {
    @include make-col(5);
    margin-top: 100px;
  }

  @include media-breakpoint-up(lg) {
    @include make-col(4);
    max-width: 420px;
  }
}

.logo {
  @include make-col-ready();
  @include make-col(8);
  position: relative;
  margin-bottom: 2rem;

  /* .ratio {
    padding-top: 100%;
    background-color: #fff;
  } */

  @include media-breakpoint-up(sm) {
    @include make-col(4);
  }

  @include media-breakpoint-up(md) {
    @include make-col(4);
    @include make-col-offset(1);
    margin-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    @include make-col(3);
    @include make-col-offset(2);
  }

  @include media-breakpoint-up(xl) {
    @include make-col-offset(3);
  }

  .box {
    width: 100%;
    padding-top: 100%;
    background: white;
    position: relative;
  }
  img {
    width: 100%;
    transform: translate(0%, -150%);
    position: absolute;
    /* position: absolute;
    top: 50%;
    left: 50%;
    max-width: 65%;
    max-height: 60%;
    transform: translate(-50%, -50%); */
  }
}

.subtitle {
  @include taxonomy();
  margin-bottom: 1rem;
}

.title {
  @include headline('large');
}

.excerpt {
  line-height: 1.2;
}
</style>
