<template>
  <SocialsCarousel
    class="social-feed"
    :title="block.title"
    :posts="posts"
    :buttonText="block.button_text"
    :onButtonClick="onAllEventsClick"
  />
</template>

<script>
import SocialsCarousel from '../socials/SocialsCarousel'

export default {
  name: 'SocialFeedBlock',
  components: { SocialsCarousel },
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      posts: []
    }
  },
  methods: {
    async fetch () {
      this.posts = await this.$store.dispatch('fetchPosts', {
        type: 'social-posts',
        per_page: 20,
      })
    },

    onAllEventsClick () {
      const { button_link } = this.block
      if (button_link.indexOf('://')) {
        window.open(button_link, '_blank')
      } else {
        this.$router.push(button_link)
      }
    }
  },
  created () {
    this.fetch()
  }
}
</script>

<style lang="scss" scoped>
.social-feed {
  margin: 72px 0;

  @include media-breakpoint-up(md) {
    margin: 180px 0;
  }
}
</style>
