import { render, staticRenderFns } from "./YoutubeVideo.vue?vue&type=template&id=e168375c&scoped=true&"
import script from "./YoutubeVideo.vue?vue&type=script&lang=js&"
export * from "./YoutubeVideo.vue?vue&type=script&lang=js&"
import style0 from "./YoutubeVideo.vue?vue&type=style&index=0&id=e168375c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e168375c",
  null
  
)

component.options.__file = "YoutubeVideo.vue"
export default component.exports