<template lang="html">
  <div class="term-select">
    <span class="label" v-html="label"></span>
    <span class="value">
      {{ valueText }}
      <i class="icon-toggle"></i>
    </span>

    <select v-model="selected" class="input">
      <option value="" v-html="allText" :selected="isSelected('')"></option>
      <option
        v-for="term in terms"
        :key="term.id"
        :value="term.id"
        :selected="isSelected(term.id)">{{ term.name }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'TermSelect',
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    terms: {
      type: Array,
      default: () => {}
    },
    label: {
      type: String,
      default: 'Filter by: '
    },
    emptyText: {
      type: String,
      default: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
    },
    allText: {
      type: String,
      default: 'Show all'
    }
  },
  data () {
    return {
      selected: this.value
    }
  },
  watch: {
    selected () {
      this.$emit('change', this.selectedTerm)
    }
  },
  computed: {
    selectedTerm () {
      return this.terms.find(t => t.id === this.selected)
    },

    valueText () {
      return this.selectedTerm ? this.selectedTerm.name : this.emptyText
    }
  },
  methods: {
    isSelected (id) {
      return this.selected === id
    }
  }
}

</script>

<style lang="scss" scoped>
.term-select {
  position: relative;
  display: inline-block;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  -webkit-appearance: menulist-button;
}

.icon-toggle {
  font-size: 85%;
  vertical-align: baseline;
}
</style>
