<template>
  <header class="hero">
    <div class="media" :style="mediaStyle">
      <slot name="media" />
    </div>
    <div class="container">
      <div class="row">
        <div class="content">
          <slot />
        </div>
      </div>
    </div>
    <slot name="extra" />
  </header>
</template>

<script>
export default {
  name: 'Hero',
  data () {
    return {
      yTranslate: 0
    }
  },
  computed: {
    mediaStyle () {
      return {
        transform: `translate(0, ${this.yTranslate}px)`
      }
    }
  },
  methods: {
    onScroll () {
      if (this.$mq === undefined || !this.$mq.md) {
        return
      }
      const offset = (this.$el.offsetHeight - window.innerHeight) - window.scrollY
      this.yTranslate = Math.max(-window.innerHeight, Math.min(0, offset))
    }
  },
  mounted () {
    window.addEventListener('resize', this.onScroll)
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onScroll)
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style lang="scss" scoped>
.hero {
  position: relative;
  background-color: #fff;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 100vh;
  }
}

.media {
  width: 100%;
  height: 100vh;

  @include media-breakpoint-up(md) {
    position: fixed;
    top: 0;
    left: 0;
    width: 50vw;
    z-index: 2;
  }
}

.container {
  position: relative;
}

.content {
  @include make-col-ready();


  @include media-breakpoint-up(md) {
    @include make-col(6);
    @include make-col-offset(6);
  }
}
</style>
