<template>
  <div class="slideshow">
    <div class="media" :style="mediaStyle">
      <transition name="media">
        <img
          :key="currentImage.id"
          :src="$wp.image(currentImage)"
          :alt="currentImage.alt"
          class="image" />
      </transition>
    </div>
  </div>
</template>

<script>
import { preload } from '../utils'

export default {
  name: 'Slideshow',
  props: {
    images: Array
  },
  data () {
    return {
      isLoaded: false,
      currentIndex: 0
    }
  },
  computed: {
    currentImage () {
      return this.images[this.currentIndex]
    },

    mediaStyle () {
      return {
        opacity: this.isLoaded ? 1 : 0
      }
    }
  },
  methods: {
    start () {
      this.isLoaded = true
      this.interval = setInterval(this.next, 2500)
    },

    next () {
      let index = this.currentIndex + 1
      this.currentIndex = this.images[index] ? index : 0
    }
  },
  mounted () {
    preload(this.images.map(i => this.$wp.image(i)))
      .then(() => this.start())
  },
  beforeDestroy () {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
}
</script>

<style lang="scss" scoped>
.slideshow {
  overflow: hidden;
  background: rgba($body-color, 0.1);
  transform: translate3d(0, 0, 0);
}

.media {
  position: relative;
  width: 100%;
  // height: 100% !important;
  opacity: 0;
  background-color: #000;
  transition: opacity 0.25s;
}

.media-enter-active {
  transition: transform 0.4s ease-in-out;
  z-index: 1;
}

.media-leave-active {
  transition: transform 0.45s ease-in-out;
  z-index: 2;
}

.media-enter {
  transform: translate3d(50%, 0, 0);
}

.media-leave-to {
  transform: translate3d(-100%, 0, 0);
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
