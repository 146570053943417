<template>

  <a :class="elClass" :href="event.link_relative">

    <!-- Image -->
    <div class="media">
      <img class="image" :src="$wp.thumbnail(event)" :alt="event.title.rendered" />
      <time v-if="shortDate" class="short-date">
        <span>{{ shortDate }}</span>
      </time>
    </div>

    <!-- -->
    <p class="themes">
      <span
        class="theme"
        v-for="term in $wp.terms(event, 'theme')"
        :key="term.id"
        v-html="term.name"
      />
    </p>

    <!-- Title -->
    <h4 class="title" v-html="event.title.rendered"></h4>

    <!-- Metadata -->
    <p class="meta">
      <time v-if="fullDate" class="date" :datetime="event.acf.start_time">{{ fullDate }}</time>
      <span v-if="location" class="location">{{ location }}</span>
    </p>

  </a>

</template>

<script>
import { preload } from '../../utils'

export default {
  
  name: 'EventTile',
  props: {
    event: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      imageLoaded: false
    }
  },

  computed: {

    elClass () {
      return {
        'event': true,
        'event-tile': true,
        'loaded': this.imageLoaded
      }
    },

    shortDate () {
      const { display_date, start_time } = this.event.acf
      if (display_date) {
        return false
      } else if (start_time) {
        return this.$wp.displayDate(start_time, 'LLL d')
      }
    },

    fullDate () {
      const { display_date, start_time } = this.event.acf
      if (display_date) {
        return display_date
      } else if (start_time) {
        return this.$wp.displayDate(start_time, 'cccc, LLL d')
      }
    },

    location () {
      return this.$wp.terms(this.event, 'city').map(t => t.name).join(', ')
    }

  },
  
  mounted () {
    preload(this.$wp.thumbnail(this.event))
      .then(() => this.imageLoaded = true)
  }
}
</script>

<style lang="scss" scoped>
.event-tile {
  display: block;
  position: relative;

  &:hover {
    .short-date,
    .media .image {
      transform: translate(-0.5rem, -0.5rem);
    }

    .media,
    .short-date:before {
      background-color: $desert;
    }
  }

  &.loaded {

    .image {
      opacity: 1;
    }

    .media {
      background-color: $desert;
    }
  }
}

.media {
  position: relative;
  margin-bottom: 1rem;
  padding-top: percentage(542/453);
  background-color: rgba(#000, 0.1);
  transition: background 0.3s 0.3s;

  @include media-breakpoint-up(md) {
    margin-bottom: 2rem;
  }
}

.short-date {
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  line-height: 1;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  transition: transform 0.2s linear;

  @include media-breakpoint-up(md) {
    top: 1.5rem;
    left: 1.5rem;
    font-size: 1.625rem;
  }

  span {
    position: relative;
    display: inline-block;
    padding: 0.75rem;
    background-color: #fff;

    @include media-breakpoint-up(md) {
      padding: 1rem;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 100%;
    height: 100%;
    background-color: $body-color;
    transition: background 0.35s;
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: transform 0.2s linear, opacity 0.3s;
}

.title {
  font-size: 1rem;
  font-weight: 500;

  @include media-breakpoint-up(md) {
    font-size: 1.625rem;
  }
}

.themes {
  @include taxonomy();

  .theme {
    display: inline-block;
    margin-right: 0.5rem;
  }
}

.meta {
  font-size: 0.625rem;

  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }
}

.date + .location {
  &:before {
    content: ' — ';
  }
}
</style>
