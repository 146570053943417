<template lang="html">
  <div :class="elClass" :style="elStyle">
    <img v-if="poster" :src="poster" class="poster" />
    <a ref="close" @click.prevent="stop" class="close"><i class="icon-cross"></i></a>
    <video v-if="mp4" ref="video" :src="mp4" @click.prevent="togglePaused" preload class="video"></video>
    <div ref="overlay" class="overlay" @click.prevent="play">
      <a ref="button" href class="floating-button">
        <span class="text">
          <img class="icon" src="../assets/play.svg" alt="Play" />
          {{ buttonText }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import { fitVideo } from '../utils'

export default {
  name: 'VideoPlayer',
  props: {
    poster: String,
    mp4: String,
    buttonText: {
      type: String,
      default: 'WATCH THE VIDEO'
    },
    aspectRatio: {
      type: [Boolean, Number],
      default: 9/16
    }
  },
  data () {
    return {
      videoLoaded: false,
      showVideo: false,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      videoWidth: 0,
      videoHeight: 0
    }
  },
  computed: {
    elClass () {
      return {
        'video-player': true,
        'show-video': this.showVideo,
        'video-loaded': this.videoLoaded,
        'video-fill': !this.aspectRatio
      }
    },

    elStyle () {
      if (this.aspectRatio) {
        return {
          paddingTop: `${this.aspectRatio * 100}%`
        }
      }
    }
  },
  methods: {

    play () {
      this.showVideo = true
      setTimeout(() => this.$refs.video.play(), 300)
    },

    stop () {
      this.showVideo = false
      this.$refs.video.pause()
    },

    togglePaused () {
      let { video } = this.$refs
      if (video.paused) {
        video.play()
      } else {
        video.pause()
      }
    },

    resize () {
      const { video } = this.$refs
      let { width, height, top, left } = this.$el.getBoundingClientRect()

      this.width = width
      this.height = height
      this.top = top
      this.left = left

      if (this.mp4 && this.aspectRatio) {
        let dimens = fitVideo(this.$el, video)
        this.videoWidth = dimens.width
        this.videoHeight = dimens.height
      }
    },

    videoEnd () {
      this.stop()
    },

    handleVideoLoad () {
      this.resize()
    },

    handleMove (e) {
      const { button } = this.$refs
      let left = e.pageX - this.left
      let top = e.pageY - this.top
      button.style.left = Math.min(Math.max(left, 0), this.width) + 'px'
      button.style.top = Math.min(Math.max(top, 0), this.height) + 'px'
    },

    centerVideo () {
      const { button } = this.$refs
      button.style.left = '50%'
      button.style.top = '50%'
    }
  },
  mounted () {
    let { video } = this.$refs

    if (video) {
      video.addEventListener('loadeddata', this.handleVideoLoad)
      video.addEventListener('ended', this.videoEnd, false)
    }

    window.addEventListener('resize', this.resize)
    window.addEventListener('scroll', this.scroll)

    if (this.$mq.md) {
      window.addEventListener('mousemove', this.handleMove)
    } else {
      this.centerVideo()
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resize)
    window.removeEventListener('scroll', this.scroll)
    window.removeEventListener('mousemove', this.handleMove)
  }
}

</script>

<style lang="scss" scoped>
.video-player {
  position: relative;
}

.poster,
.video,
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.35s;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  color: #fff;
  opacity: 0;
  z-index: 20;
  transition: all 0.35s;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 5;
}

.poster {
  z-index: 10;
  object-fit: cover;
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
}

.floating-button {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(-50%, -50%, 0);
  padding: 1rem;
  cursor: url('../assets/no-cursor.png'), none;

  .text {
    position: relative;
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 500;
    white-space: nowrap;
    color: $body-color;
    z-index: 10;
    transform: scale(0.75);
    transition: all 0.15s;
  }

  .icon {
    display: inline-block;
    position: relative;
    top: -2px;
    margin-right: 0.5rem;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(0.75);
    transition: all 0.2s;
  }

  &:before {
    top: 6px;
    left: 6px;
    background-color: $body-color;
  }

  &:after {
    top: 0;
    left: 0;
    background-color: #fff;
  }

  &:hover {
    text-decoration: none;
  }
}

.overlay:hover {
  .floating-button {
    &:before,
    &:after,
    .text {
      transform: scale(1);
    }
  }
}

.show-video {
  .overlay {
    transform: scale(1.05);
    opacity: 0;
    visibility: hidden;
  }

  .poster {
    opacity: 0;
    visibility: hidden;
  }

  .close {
    opacity: 1;
  }
}
</style>
