<template>
  <section class="featured-event">
    <div class="container">
      <a v-if="event" :href="event.link_relative" class="event">
        <div class="content">
          <h5 class="label">{{ block.title }}</h5>
          <h3 class="title" v-html="eventTitle"></h3>
          <span class="date" v-html="eventDate"></span>
        </div>

        <div class="media">
          <img class="image" :src="eventImage" :alt="event.title.rendered" />
        </div>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeaturedEventBlock',
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      event: null
    }
  },
  computed: {
    eventTitle () {
      return (
        this.block.event_title ||
        this.event.title.rendered
      )
      .split('\n')
      .map(t => `<span class="line">${t}</span>`)
      .join('')
    },

    eventDate () {
      return this.block.event_date
    },

    eventImage () {
      if (this.block.image) {
        return this.$wp.image(this.block.image)
      } else {
        return this.$wp.thumbnail(event)
      }
    }
  },
  methods: {
    async fetch () {
      this.event = await this.$store.dispatch('hydratePosts', {
        type: 'events',
        ids: this.block.event
      })
    }
  },
  created () {
    this.fetch()
  }
}
</script>

<style lang="scss" scoped>
.featured-event {
  margin: 72px 0;

  @include media-breakpoint-up(md) {
    margin: 180px 0;
  }
}

.event {
  @include tile-hover-shadow($sun, '.content, .media');
  display: flex;
  flex-direction: column;
  color: #fff;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: row;
  }
}

.content,
.media {
  position: relative;

  @include media-breakpoint-up(md) {
    width: 50%;
    flex: 1;
    flex-shrink: 0;
  }
}

.content {
  padding: 1.75rem 1.5rem;
  text-transform: uppercase;
  background-color: $eucalyptus;
  order: 2;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: column;
    padding: 100px 0;
    order: 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 120px 0;
  }

  @include media-breakpoint-up(xl) {
    padding: 170px 0;
  }
}

.label,
.title,
.date {

  @include media-breakpoint-up(md) {
    margin-left: 64px;
    margin-right: 64px;
  }

  @include media-breakpoint-up(xl) {
    margin-left: 135px;
    margin-right: 135px;
  }
}

.label {
  margin-bottom: 1rem;
  font-size: 0.625rem;
  font-weight: bold;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    margin-bottom: 1.25rem;
    font-size: 0.875rem;
  }
}

.title,
.date {
  margin-bottom: 0;
  line-height: 0.9;
  font-size: 3.125rem;

  @include media-breakpoint-up(md) {
    font-size: 4rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 5.625rem;
  }

  /deep/ .line {
    display: block;
  }

  /deep/ .line:nth-child(odd) {
    font-weight: 600;
  }

  /deep/ .line:nth-child(even) {
    font-weight: normal;
  }
}

.date {
  color: $sun;
}

.image {
  width: 100%;
  height: 260px;
  object-fit: cover;

  @include media-breakpoint-up(sm) {
    height: 320px;
  }

  @include media-breakpoint-up(md) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
</style>
