<template>
  <CarouselFrame class="partners-carousel" :title="title" :carousel="$refs.carousel">
    <TileCarousel ref="carousel" :items="items" :groupSize="groupSize">
      <PartnerTile slot-scope="item" :partner="item.item" />
    </TileCarousel>
  </CarouselFrame>
</template>

<script>
import TileCarousel from '../TileCarousel'
import CarouselFrame from '../CarouselFrame'
import PartnerTile from './PartnerTile'

export default {
  name: 'PartnersCarousel',
  components: { TileCarousel, CarouselFrame, PartnerTile },
  props: {
    title: {
      type: String,
      default: 'Partners'
    },
    partners: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      groupSize: 4,
      items: []
    }
  },
  watch: {
    partners: 'fetch'
  },
  methods: {
    async fetch () {
      this.items = await this.$store.dispatch('hydratePosts', {
        type: 'sponsors',
        ids: this.partners
      })
    }
  },
  created () {
    this.fetch()
  }
}
</script>
