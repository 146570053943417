<template>
  <article v-if="partner" :class="elClass">
    <!-- debug only start -->
    <!-- <PrimaryHeader v-if="true" :partner="partner" /> -->
    <!-- debug only end -->
    <PrimaryHeader v-if="this.isPimrary" :partner="partner" />
    <SecondaryHeader v-else :partner="partner" />

    <template v-for="(block, idx) in partner.acf.blocks">
      <VideoBlock v-if="block.acf_fc_layout === 'video'" :key="idx" :block="block" :size="size" />
      <ContentBlock v-if="block.acf_fc_layout === 'content'" :key="idx" :block="block" :size="size" />
      <ProductsBlock v-if="block.acf_fc_layout === 'products'" :key="idx" :block="block" :size="size" />
      <AboutPartner v-if="block.acf_fc_layout === 'about'" :key="idx" :block="block" :partner="partner" />
    </template>
  </article>
</template>

<script>
import { setPageTitle } from '../utils'
import PrimaryHeader from '../components/partners/PrimaryHeader'
import SecondaryHeader from '../components/partners/SecondaryHeader'
import AboutPartner from '../components/partners/AboutPartner'
import VideoBlock from '../components/partners/PartnerVideoBlock'
import ContentBlock from '../components/partners/PartnerContentBlock'
import ProductsBlock from '../components/partners/PartnerProductsBlock'

export default {
  name: 'SponsorPage',
  components: {
    PrimaryHeader, SecondaryHeader, AboutPartner, VideoBlock, ContentBlock, ProductsBlock
  },
  props: {
    slug: String
  },
  data () {
    return {
      partner: null
    }
  },
  watch: {
    slug: 'fetch'
  },
  computed: {
    isPimrary () {
      return this.partner.template === 'sponsor-primary'
    },

    size () {
      return this.isPimrary ? 'large' : 'small'
    },

    elClass () {
      return {
        'partner-page': true,
        [`partner-page--${this.size}`]: true,
      }
    }
  },
  methods: {
    async fetch () {
      this.partner = await this.$store.dispatch('fetchPostBySlug', {
        type: 'sponsors',
        slug: this.slug
      })

      setPageTitle(this.partner)
    }
  },
  created () {
    this.fetch()
  }
}
</script>

<style lang="scss" scoped>
.partner-page {
  &--small {
    margin-top: 140px;
    margin-bottom: 140px;

    @include media-breakpoint-up(md) {
      margin-top: $page-margin-top;
      margin-bottom: 260px;
    }
  }

  &--large {
    margin-bottom: 140px;
  }
}
</style>
