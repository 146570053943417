<template>
  <CursorButton
    :text="buttonText"
    @click.prevent="handleClick"
    class="playable-attachment"
    :style="elStyle">
    <div class="poster">
      <img v-if="poster" :src="poster" :alt="attachment && attachment.alt" class="image" v-preload />
    </div>
  </CursorButton>
</template>

<script>
import CursorButton from '../CursorButton'

export default {
  name: 'PlayableAttachment',
  components: { CursorButton },
  props: {
    poster: {
      type: String,
      required: true
    },
    attachment: [Object, Number],
    buttonText: {
      type: String,
      default: 'WATCH THE VIDEO'
    },
    ratio: Number,
    onClick: Function
  },
  computed: {
    elStyle () {
      return {
        paddingTop: `${this.ratio * 100}%`
      }
    }
  },
  methods: {
    handleClick () {
      if (this.onClick) {
        this.onClick()
      } else if (this.attachment) {
        this.$router.push({
          ...this.$route,
          query: {
            modal: 'gallery',
            attachmentId: this.attachment.id || this.attachment
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.playable-attachment {
  @include image-ratio(0, '.image', '.poster');
  display: block;
  background-color: rgba(#000, 0.1);
}

.image {
  transition: opacity 0.35s;
}
</style>
