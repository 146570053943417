export default {
  install (Vue) {
    Vue.directive('arrow-button', async function (el) {
      const ps = el.querySelectorAll('p')
      for (const p of ps) {
        const child = p.children[0]

        if (p.children.length === 1 && child && child.tagName === 'A') {
          child.classList.add('arrow-button')
        }
      }
    })
  }
}
