<template>
  <GalleriesCarousel
    class="galleries-carousel"
    v-if="block.galleries"
    :title="block.title"
    :galleries="block.galleries"
    :buttonText="block.button_text"
    :onButtonClick="onAllEventsClick" />
</template>

<script>
import GalleriesCarousel from '../galleries/GalleriesCarousel'

export default {
  name: 'GalleriesCarouselBlock',
  components: { GalleriesCarousel },
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  methods: {
    onAllEventsClick () {
      this.$router.push(this.block.button_link)
    }
  }
}
</script>

<style lang="scss" scoped>
.galleries-carousel {
  margin: 72px 0;

  @include media-breakpoint-up(md) {
    margin: 180px 0;
  }
}
</style>
