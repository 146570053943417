<template>
  <article v-if="event" class="event-page">
    <EventHero :event="event" />

    <section v-if="event.acf.schedule" class="section-schedule">
      <div class="container">
        <Schedule :schedule="event.acf.schedule" />
      </div>
    </section>

    <section v-if="event.acf.speakers" class="section-speakers">
      <SpeakersCarousel :speakers="event.acf.speakers" />
    </section>

    <section v-if="event.acf.video_mp4" class="section-video">
      <div class="container">
        <VideoPlayer
          :poster="$wp.image(event.acf.video_poster)"
          :mp4="$wp.image(event.acf.video_mp4)" />
      </div>
    </section>

    <section v-if="event.acf.youtube_url" class="section-video">
      <div class="container">
        <YoutubeVideo
          :videoUrl="event.acf.youtube_url"
          :autoplay="false" />
      </div>
    </section>

    <section v-if="event.acf.music" class="section-music">
      <MusicCarousel :artists="event.acf.music" />
    </section>

    <section v-if="event.acf.gallery_layout !== 'carousel' && event.acf.gallery" class="section-gallery">
      <div class="container">
        <GalleryGrid :gallery="event.acf.gallery" />
      </div>
    </section>

    <section v-if="event.acf.gallery_layout === 'carousel' && event.acf.gallery" class="section-photos">
      <GalleryCarousel :gallery="event.acf.gallery" :groupSize="8" :groupRows="2" />
    </section>

    <section v-if="pastEvents" class="section-galleries">
      <div class="container">
        <GalleriesList
          :galleries="pastEventsGalleryIds"
          buttonText="See all"
          :onButtonClick="onEventGalleryClick" />
      </div>
    </section>

    <section v-if="event.acf.galleries" class="section-galleries">
      <div class="container">
        <GalleriesList
          :galleries="event.acf.galleries"
          buttonText="See all"
          :onButtonClick="onGalleryClick" />
      </div>
    </section>

    <section v-if="event.acf.partners" class="section-partners">
      <PartnersCarousel :partners="event.acf.partners" />
    </section>

    <section v-if="event.acf.related_events" class="section-related">
      <EventsCarousel title="Related events" :events="event.acf.related_events" />
    </section>
  </article>
</template>

<script>
import { setPageTitle } from '../utils'
import EventHero from '../components/events/EventHero'
import VideoPlayer from '../components/VideoPlayer'
import YoutubeVideo from '../components/YoutubeVideo'
import SpeakersCarousel from '../components/speakers/SpeakersCarousel'
import EventsCarousel from '../components/events/EventsCarousel'
import MusicCarousel from '../components/music/MusicCarousel'
import PartnersCarousel from '../components/partners/PartnersCarousel'
import GalleryCarousel from '../components/galleries/GalleryCarousel'
import GalleryGrid from '../components/galleries/GalleryGrid'
import GalleriesList from '../components/galleries/GalleriesList'
import Schedule from '../components/schedule/Schedule'

export default {
  name: 'EventPage',
  components: {
    EventHero, VideoPlayer, YoutubeVideo, SpeakersCarousel, EventsCarousel,
    MusicCarousel, PartnersCarousel, GalleryCarousel, GalleryGrid, GalleriesList,
    Schedule
  },
  props: {
    slug: String
  },
  data () {
    return {
      event: null,
      pastEvents: null
    }
  },
  watch: {
    slug: 'fetch'
  },
  computed: {
    pastEventsGalleryIds () {
      if (this.pastEvents) {
        return this.pastEvents.map(e => e.acf.gallery).filter(id => !!id)
      }
    }
  },
  methods: {
    async fetch () {
      this.event = await this.$store.dispatch('fetchPostBySlug', {
        type: 'events',
        slug: this.slug
      })

      setPageTitle(this.event)

      this.pastEvents = await this.$store.dispatch('hydratePosts', {
        type: 'events',
        ids: this.event.acf.past_events
      })
    },

    /**
     * User has clicked on a "see all" button for a gallery
     * that is associated with an event (go to event page)
     */
    onEventGalleryClick (gallery) {
      const event = this.pastEvents.find(e => e.acf.gallery === gallery.id)
      if (!event) {
        return this.onGalleryClick(gallery)
      }
      this.$router.push(event.link_relative)
    },

    /**
     * User has click on "see all" on a gallery not associated
     * with an event (just open the gallery modal)
     */
    onGalleryClick (gallery) {
      this.$router.push({
        ...this.$route,
        query: {
          modal: 'gallery',
          galleryId: gallery.id
        }
      })
    }
  },
  created () {
    this.fetch()
  }
}
</script>

<style lang="scss" scoped>
.event-page {
}


.section-speakers,
.section-music,
.section-gallery {
  margin: 72px 0;

  @include media-breakpoint-up(md) {
    margin: 100px 0;
  }
}

section:nth-child(odd) {
  margin: 72px 0;
  padding: 72px 0;
  background-color: $pearl;

  @include media-breakpoint-up(md) {
    margin: 140px 0;
    padding: 140px 0;
  }
}

section:nth-child(even) {
  margin: 72px 0;

  @include media-breakpoint-up(md) {
    margin: 140px 0;
  }
}

section:last-child {
  margin-bottom: 0;
}

.event-hero + .section-related {
  margin-top: 0;
  background-color: $body-bg;
}
</style>
