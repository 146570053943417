<template>

  <div class="carousel-block">

    <div class="header">

      <div v-if="!$slots.header" class="container">
        <h3 v-if="title" class="title" v-html="title"/>
        <a v-if="buttonText" @click.prevent="onButtonClick()" class="button" v-html="buttonText"/>
        <CarouselControls :carousel="carousel" v-if="!hideControls"/>
      </div>

      <slot v-else name="header" />

    </div>

    <div class="container">
      <slot name="default" />
    </div>

  </div>

</template>

<script>
import CarouselControls from './CarouselControls'

export default {
  name: 'CarouselFrame',
  components: { CarouselControls },
  props: {
    title: String,
    buttonText: String,
    hideControls: Boolean,
    onButtonClick: {
      type: Function,
      default: () => () => {}
    },
    carousel: Object
  }
}
</script>

<style lang="scss" scoped>
.carousel-block {

}

.header {
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 40px;
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.title {
  @include headline();
  margin-bottom: 0.125rem;
  font-size: 1rem;
}

.button {
  @include arrow-button();
  margin-left: 1.25rem;
  font-size: 0.75rem;

  @include media-breakpoint-up(md) {
    margin-left: 2rem;
    font-size: 1rem;
  }
}

.carousel-controls {
  display: none;
  margin-left: auto;

  @include media-breakpoint-up(md) {
    display: block;
  }
}
</style>

<style lang="scss">
.carousel-block {

  .tile-carousel {
    @include media-breakpoint-down(sm) {
      margin-left: ($grid-gutter-width * -0.5);
      margin-right: ($grid-gutter-width * -0.5);
    }
  }

  .pane {
    @include media-breakpoint-down(sm) {
      padding: 0 ($grid-gutter-width * 0.5);
    }
  }
}
</style>
