<template>
  <EventsCarousel
    class="events-carousel"
    :title="block.title"
    :events="block.events"
    :buttonText="block.button_text"
    :onButtonClick="onAllEventsClick" />
</template>

<script>
import EventsCarousel from '../events/EventsCarousel'

export default {
  name: 'EventsCarouselBlock',
  components: { EventsCarousel },
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  methods: {
    onAllEventsClick () {
      this.$router.push(this.block.button_link)
    }
  }
}
</script>

<style lang="scss" scoped>
.events-carousel {
  margin: 72px 0;

  @include media-breakpoint-up(md) {
    margin: 180px 0;
  }
}
</style>
