<template>
  <transition name="gallery-modal" mode="out-in" appear>
    <div v-if="isOpen" class="gallery-modal">
      <a @click.prevent="close" href class="close">
        <i class="icon-close"></i>
      </a>

      <transition name="loader">
        <Loader v-if="!media" color="white" />
      </transition>

      <transition :name="mainTransition" mode="out-in">
        <GalleryModalGrid v-if="media && !attachmentId" :gallery="gallery" />
        <GalleryModalCarousel v-else-if="media && current" :gallery="gallery" :attachment="current" />
      </transition>
    </div>
  </transition>
</template>

<script>
import Loader from '../Loader'
import GalleryModalGrid from './GalleryModalGrid'
import GalleryModalCarousel from './GalleryModalCarousel'

export default {
  name: 'GalleryModal',
  components: { Loader, GalleryModalGrid, GalleryModalCarousel },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    galleryId: {
      type: [Number, String, Object]
    },
    attachmentIds: {
      type: [String, Array]
    },
    attachmentId: {
      type: [Number, String]
    }
  },
  data () {
    return {
      isOpen: this.show,
      gallery: null,
      media: null,
      direction: 'none'
    }
  },
  watch: {
    galleryId: 'fetch',
    attachmentIds: 'fetch',
    show () {
      this.isOpen = this.show
      this.fetch()
    },
    isOpen () {
      if (!this.isOpen) {
        this.gallery = null
        this.media = null
      }
    }
  },
  computed: {
    current () {
      const id = parseInt(this.attachmentId, 10)
      const index = Math.max(this.media.map(m => m.id).indexOf(id), 0)
      return (this.media || [])[index]
    },

    mainTransition () {
      return this.attachmentId ? 'carousel' : 'grid'
    }
  },
  methods: {

    async fetch () {
      if (this.galleryId) {
        this.gallery = await this.$store.dispatch('hydratePosts', {
          type: 'galleries',
          ids: parseInt(this.galleryId, 10)
        })
        this.media = this.gallery.acf.media
      } else if (this.attachmentIds) {
        this.media = await this.$store.dispatch('hydratePosts', {
          type: 'media',
          ids: this.attachmentIds.split(',')
        })
      } else if (this.attachmentId) {
        const inGallery = (this.media || []).map(a => a.id).indexOf(this.attachmentId) !== -1
        if (!inGallery) {
          // The attachement is not in the gallery, load it
          this.media = await this.$store.dispatch('hydratePosts', {
            type: 'media',
            ids: [this.attachmentId]
          })
        }
      }
    },

    close () {
      const query = { ...this.$route.query }
      const isGoingBack = this.attachmentId && (this.galleryId || this.attachmentIds)
      let func = 'push'

      if (isGoingBack) {
        // We're going back to the grid view
        delete query.attachmentId
        func = 'replace'
      } else {
        // We're leaving the modal
        delete query.modal
        delete query.galleryId
        delete query.attachmentId
        delete query.attachmentIds
        this.direction = 'none'
        this.isOpen = false
      }

      this.$router[func]({ ...this.$route, query })
    }
  },
  created () {
    this.fetch()
  }
}
</script>

<style lang="scss" scoped>
.gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba($body-color, 0.95);

  &.gallery-modal-enter-active,
  &.gallery-modal-leave-active {
    transition: all 0.35s;
  }

  &.gallery-modal-enter,
  &.gallery-modal-leave-to {
    transform: scale(0.95);
    opacity: 0;
  }
}

.close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 1rem;
  font-size: 1.25rem;
  z-index: 10;
  line-height: 1;
  color: #A8A8A8;
  transition: color 0.2s;

  @include media-breakpoint-up(lg) {
    top: 2rem;
    right: 2rem;
    padding: 2rem;
    font-size: 1.625rem;
  }

  &:hover {
    color: #fff;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-enter-active,
.loader-leave-active {
  transition: all 0.2s ease-out;
}

.carousel-enter,
.carousel-leave-to {
  transform: translate(-50%, -50%) scale(0.5);
  opacity: 0;
}

.carousel-enter-active,
.carousel-leave-active,
.grid-enter-active,
.grid-leave-active {
  transition: all 0.3s ease-out;
}

.carousel-enter,
.grid-leave-to {
  transform: scale(1.05);
  opacity: 0;
}

.carousel-leave-to,
.grid-enter {
  transform: scale(0.95);
  opacity: 0;
}
</style>
