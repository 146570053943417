<template>
  <article v-if="page" class="honourees-page">
    <header class="header">
      <div class="container">
        <div class="headline" v-html="page.acf.headline"></div>
      </div>
    </header>

    <div class="container">
      <div class="honourees">
        <div v-for="(honouree, idx) in page.acf.honourees" :key="idx" class="honouree">
          <div class="media">
            <img
              v-if="honouree.photo"
              :src="$wp.image(honouree.photo)"
              :alt="honouree.name"
              class="photo"
              v-preload />
          </div>
          <h5 class="name" v-html="honouree.name"></h5>
          <p v-if="honouree.award" class="award" v-html="honouree.award"></p>
          <p v-if="presentedLine" class="presented" v-html="presentedLine(honouree)"></p>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import PageMixin from './PageMixin'

export default {
  name: 'HonoureesPage',
  mixins: [PageMixin],
  methods: {
    presentedLine (honouree) {
      if (honouree.presenter && honouree.year) {
        return `Presented by ${honouree.presenter}, ${honouree.year}`
      } else if (honouree.presenter) {
        return `Presented by ${honouree.presenter}`
      } else if (honouree.year) {
        return `Presented ${honouree.year}`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.honourees-page {
  margin-top: 140px;
  margin-bottom: 140px;

  @include media-breakpoint-up(md) {
    margin-top: $page-margin-top;
    margin-bottom: 280px;
  }
}

.header {
  margin-bottom: 72px;

  @include media-breakpoint-up(md) {
    margin-bottom: 220px;
  }
}

.headline {
  @include headline();
  max-width: 840px;
  margin: 0 auto;
  text-align: center;
}

.honourees {
  @include make-row();
  margin-top: -72px;

  @include media-breakpoint-up(md) {
    margin-top: -150px;
  }
}

.honouree {
  @include tile-hover-shadow($desert, '.photo', '.media');
  @include make-col-ready();
  margin-top: 72px;

  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }

  @include media-breakpoint-up(md) {
    margin-top: 150px;
  }

  @include media-breakpoint-up(lg) {
    @include make-col(4);
  }
}

.media {
  @include image-ratio(percentage(500/450), '.photo', '&');
  margin-bottom: 1.5rem;
  background-color: rgba(#000, 0.1);
}

.name {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;

  @include media-breakpoint-up(md) {
    font-size: 1.625rem;
  }
}

.award {
  margin-bottom: 0.25rem;
}

.presented {
  margin-bottom: 0;
}
</style>
