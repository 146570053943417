import { render, staticRenderFns } from "./CarouselControls.vue?vue&type=template&id=335aed44&scoped=true&lang=html&"
import script from "./CarouselControls.vue?vue&type=script&lang=js&"
export * from "./CarouselControls.vue?vue&type=script&lang=js&"
import style0 from "./CarouselControls.vue?vue&type=style&index=0&id=335aed44&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "335aed44",
  null
  
)

component.options.__file = "CarouselControls.vue"
export default component.exports