<template>
  <header id="header" :class="elClass">
    <nav ref="navbar" class="navbar">
      <div class="container">
        <Logo ref="logo" id="logo" :small="!$mq.sm" :color="false" />

        <nav ref="toggles" class="toggles">
          <span @click.prevent="toggleFollow" class="follow">
            <span class="follow-label">Follow</span>
            <div class="follow-links">
              <a
                v-for="item in socials"
                :key="item.ID"
                :href="item.url"
                target="_blank"
                class="follow-link"
                @click.stop="toggleFollow"
                v-html="item.title"></a>
            </div>
          </span>
          <a href class="menu" @click.stop.prevent="$refs.menu.open()">Menu</a>
        </nav>
      </div>
    </nav>

    <HeaderMenu ref="menu" :menu="menu" />
  </header>
</template>

<script>
import TimelineLite from 'gsap/TimelineLite'
import Logo from './Logo'
import HeaderMenu from './HeaderMenu'
import { getImageBehind } from '../utils';

export default {
  name: 'PageHeader',
  components: { Logo, HeaderMenu },
  props: {
    menu: {
      type: Array,
      default: () => []
    },
    socials: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isScrolled: false,
      isOverlay: false,
      showFollow: false
    }
  },
  computed: {

    isHome () {
      return this.$route.path === '/'
    },

    elClass () {
      return {
        'page-header': true,
        'is-scrolled': this.isScrolled,
        'theme-default': !this.isHome && !this.isOverlay,
        'theme-overlay': !this.isHome && this.isOverlay,
        'theme-home': this.isHome
      }
    },

    collapseAt () {
      return this.isHome ? window.innerHeight / 2 : 100
    }
  },
  watch: {
    '$route': 'closeFollow'
  },
  methods: {

    toggleFollow () {
      if (this.showFollow) {
        this.closeFollow()
      } else {
        this.openFollow()
      }
    },

    openFollow () {
      if (this.showFollow) {
        return
      }
      this.getFollowAnimation()
      this.showFollow = true
    },

    closeFollow () {
      if (!this.showFollow) {
        return
      }
      this.getFollowAnimation().seek('-=0', false).reverse()
      this.showFollow = false
    },

    getFollowAnimation () {
      const links = [...this.$el.querySelectorAll('.follow-link')].reverse()
      const label = this.$el.querySelector('.follow-label')

      return new TimelineLite()
        .fromTo(label, 0.25, { autoAlpha: 1, scale: 1 }, { autoAlpha: 0, scale: 0.9 })
        .staggerFromTo(links, 0.35, { x: 10, autoAlpha: 0 }, { x: 0, autoAlpha: 1 }, 0.05, '-=0.1')
    },

    onScroll () {
      this.isScrolled = window.scrollY > this.collapseAt
    },

    checkBackground () {
      const { logo } = this.$refs
      if (logo) {
        this.isOverlay = !!getImageBehind(logo.$el)
      }
    },

    /**
     * Check the logo background constantly
     *
     * (This is because watching for route changes will cause issues
     *  when there are page animations)
     */
    startAutoColor () {
      if (this.autoColorInterval) {
        clearInterval(this.autoColorInterval)
      }
      this.autoColorInterval = setInterval(() => this.checkBackground(), 50)
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
    this.startAutoColor()
    this.checkBackground()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style lang="scss" scoped>
.page-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.navbar {
  position: relative;
  height: 72px;
  background-color: rgba(#fff, 0);
  transition: all 0.5s;

  @include media-breakpoint-up(sm) {
    height: 142px;
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    max-width: 100%;
    padding-left: $grid-gutter-width-sm;
    padding-right: $grid-gutter-width-sm;

    @include media-breakpoint-up(sm) {
      padding-left: 64px;
      padding-right: 64px;
    }
  }
}

.logo {
  width: 24px;
  transition: all 0.5s;

  @include media-breakpoint-up(sm) {
    width: 290px;
  }
}

.toggles {
  margin-left: auto;
  margin-right: -1rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  transform-origin: 100% 50%;
  transition: transform 0.5s;

  @include media-breakpoint-up(sm) {
    font-size: 1.375rem;
  }
}

.follow,
.menu {
  padding: 1rem;
  cursor: pointer;
  transition: color 0.25s;

  @include media-breakpoint-up(sm) {
    margin-left: 2.25rem;
  }
}

.follow {
  position: relative;
  cursor: pointer;
}

.follow-label {
  display: inline-block;
}

.follow-links {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: -1rem;
}

.follow-link {
  display: inline-block;
  padding: 0 0.5rem;
  line-height: 1;
  font-size: 1.25rem;
  opacity: 0;
  visibility: hidden;
  transition: color 0.25s;

  @include media-breakpoint-up(sm) {
    padding: 0 1rem;
    font-size: 1.625rem;
  }
}

.is-scrolled {
  .navbar {
    background-color: #fff;

    @include media-breakpoint-up(sm) {
      height: 77px;
    }
  }

  .logo {
    display: block;

    @include media-breakpoint-up(sm) {
      width: 147px;
      fill: $desert;
    }
  }

  .toggles {
    @include media-breakpoint-up(sm) {
      transform: scale(0.9);
    }
  }
}

.theme-home {

  .logo {
    transform: translate(0, 30%) scale(0.9);
    fill: $desert;
    opacity: 0;
  }

  .follow,
  .menu,
  .follow-link {
    color: #fff;

    @include media-breakpoint-up(sm) {
      color: $desert;
    }

    &:hover {
      @include media-breakpoint-up(sm) {
        color: $charcoal;
      }
    }
  }

  &.is-scrolled {
    .logo {
      transform: translate(0, 0) scale(1);
      fill: $desert;
      opacity: 1;
    }

    .follow,
    .menu,
    .follow-link {
      color: $charcoal;

      @include media-breakpoint-up(sm) {
        color: $desert;
      }

      &:hover {
        @include media-breakpoint-up(sm) {
          color: $charcoal;
        }
      }
    }
  }
}

.theme-default {

  .logo {
    fill: $desert;
  }

  .follow,
  .menu,
  .follow-link {
    color: $charcoal;

    @include media-breakpoint-up(sm) {
      color: $desert;
    }

    &:hover {
      @include media-breakpoint-up(sm) {
        color: $charcoal;
      }
    }
  }

  .follow-link {
    @include media-breakpoint-up(sm) {
      color: $charcoal;
    }
  }

  &.is-scrolled {
    .logo {
      fill: $desert;
    }
  }
}

.theme-overlay {

  .logo {
    // fill: #fff;
    fill: $desert;
    opacity: 0;
  }

  .follow,
  .menu,
  .follow-link {
    color: #fff;

    @include media-breakpoint-up(sm) {
      color: $desert;
    }

    &:hover {
      @include media-breakpoint-up(sm) {
        color: $charcoal;
      }
    }
  }

  &.is-scrolled {
    .logo {
      fill: $desert;
      opacity: 1;
    }

    .follow,
    .menu,
    .follow-link {
      color: $charcoal;
    }
  }
}
</style>
