import { render, staticRenderFns } from "./AboutPartner.vue?vue&type=template&id=1f1b64eb&scoped=true&"
import script from "./AboutPartner.vue?vue&type=script&lang=js&"
export * from "./AboutPartner.vue?vue&type=script&lang=js&"
import style0 from "./AboutPartner.vue?vue&type=style&index=0&id=1f1b64eb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f1b64eb",
  null
  
)

component.options.__file = "AboutPartner.vue"
export default component.exports