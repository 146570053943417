import EventsCarouselBlock from './EventsCarouselBlock'
import FeaturedEventBlock from './FeaturedEventBlock'
import FeaturedSponsorBlock from './FeaturedSponsorBlock'
import SocialFeedBlock from './SocialFeedBlock'
import GalleriesCarouselBlock from './GalleriesCarouselBlock'

export default {
  events_carousel: EventsCarouselBlock,
  featured_event: FeaturedEventBlock,
  featured_sponsor: FeaturedSponsorBlock,
  social_feed: SocialFeedBlock,
  photo_galleries_carousel: GalleriesCarouselBlock
}
