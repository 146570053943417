<template>
  <div class="gallery-grid">
    <div class="header">
      <h3 v-if="title" class="title" v-html="title"></h3>
      <a v-if="hasMore" @click.prevent="toggle" :class="toggleClass">{{ toggleText }}</a>
    </div>

    <div class="grid">
      <div class="primary">
        <div v-for="attachment in primary" :key="attachment.id" class="grid-item">
          <PhotoTile :gallery="galleryObject" :attachment="attachment" :aspectRatio="1" />
        </div>
      </div>

      <div v-if="hasMore" ref="more" class="secondary">
        <div v-for="attachment in secondary" :key="attachment.id" class="grid-item">
          <PhotoTile :gallery="galleryObject" :attachment="attachment" :aspectRatio="1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimelineLite from 'gsap/TimelineLite'
import PhotoTile from './PhotoTile'

export default {
  name: 'GalleryGrid',
  components: { PhotoTile },
  props: {
    title: {
      type: String,
      default: 'Photo gallery'
    },
    gallery: {
      type: [Object, Number],
      required: true
    }
  },
  data () {
    return {
      isExpanded: false,
      galleryObject: null,
      media: []
    }
  },
  watch: {
    gallery: 'fetch'
  },
  computed: {

    primary () {
      return this.media.slice(0, 4)
    },

    secondary () {
      return this.media.slice(4)
    },

    hasMore () {
      return this.secondary.length > 0
    },

    toggleClass () {
      return {
        toggle: true,
        expanded: this.isExpanded
      }
    },

    toggleText () {
      return this.isExpanded ? 'See less' : 'See more'
    }
  },
  methods: {
    async fetch () {
      this.galleryObject = await this.$store.dispatch('hydratePosts', {
        type: 'galleries',
        ids: this.gallery
      })
      this.media = this.galleryObject.acf.media
    },

    toggle () {
      if (this.isExpanded) {
        this.collapse()
      } else {
        this.expand()
      }
    },

    expand () {
      if (this.isExpanded) {
        return
      }

      const { more } = this.$refs
      const items = [...more.querySelectorAll('.grid-item')]

      new TimelineLite()
        .set(more, { display: 'flex' })
        .from(more, 0.75, { height: 0, immediateRender: false })
        .staggerFromTo(items, 0.35, { y: 32, autoAlpha: 0 }, { y: 0, autoAlpha: 1 }, 0.05, '-=0.5')

      this.isExpanded = true
    },

    collapse () {
      if (!this.isExpanded) {
        return
      }

      const { more } = this.$refs

      new TimelineLite()
        .to(more, 0.25, { autoAlpha: 0 })
        .to(more, 0.35, { height: 0 }, '-=0.15')
        .set(more, { clearProps: 'all' })

      this.isExpanded = false
    },

    openAttachment (attachment) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          modal: 'gallery',
          galleryId: this.galleryObject.id,
          attachmentId: attachment.id
        }
      })
    }
  },
  created () {
    this.fetch()
  }
}
</script>

<style lang="scss" scoped>
.gallery-grid {

}

.header {
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 40px;
  }
}

.title {
  @include headline();
  display: inline-block;
  margin-bottom: 0.125rem;
  font-size: 1rem;
}

.toggle {
  @include arrow-button($body-color, $body-color, 'down');
  margin-left: 1.25rem;
  font-size: 0.75rem;

  @include media-breakpoint-up(md) {
    margin-left: 2rem;
    font-size: 1rem;
  }

  &.expanded {
    @include arrow-button($body-color, $body-color, 'up');
  }
}

.grid {
  margin-top: $grid-gutter-width * -0.5;

  @include media-breakpoint-up(md) {
    margin-top: -$grid-gutter-width;
  }
}

.grid-item {
  @include make-custom-col-ready($grid-gutter-width * 0.5);
  @include make-col(6);
  margin-top: $grid-gutter-width * 0.5;

  @include media-breakpoint-up(md) {
    @include make-col-ready();
    @include make-col(3);
    margin-top: $grid-gutter-width;
  }
}

.primary,
.secondary {
  @include make-custom-row($grid-gutter-width * 0.5);

  @include media-breakpoint-up(md) {
    @include make-row();
  }
}

.secondary {
  display: none;
}
</style>
