<template lang="html">

  <!-- Tile Carousel -->
  <div class="tile-carousel">

    <!-- Slides -->
    <div v-if="enabled" class="slides" :class="`groups--${groupSize / groupRows}`">

      <!-- Slide -->
      <div v-for="(rows, idx) in groups" :key="idx" class="slide">

        <!-- Items -->
        <div v-for="(items, idx) in rows" :key="idx" class="items">

          <!-- Item -->
          <div v-for="(item, idx) in items" :key="idx" class="item">

            <slot v-if="!loading" v-bind="itemProps(item)" />
            <div v-else class="placeholder" />
            
          </div>

        </div>

      </div>

    </div>

    <!-- Scrollable -->
    <div v-else class="scrollable">

      <!-- Pane -->
      <div class="pane">

        <!-- Pane Items -->
        <div class="pane-items">

          <div v-for="(item, idx) in items" :key="idx" class="item">

            <!-- Pane -->
            <slot v-if="!loading" v-bind="itemProps(item)" />

            <!-- Placeholder -->
            <div v-else class="placeholder" />

          </div>

        </div>
        
      </div>

    </div>

  </div>

</template>

<script>
import TweenLite from 'gsap/TweenLite'
import TimelineLite from 'gsap/TimelineLite'
import { Power2, Power3, Back } from 'gsap/EasePack'
import { chunkArray } from '../utils'

export default {
  name: 'TileCarousel',
  props: {
    index: {
      type: Number,
      default: 0
    },
    items: {
      type: Array,
      default: () => []
    },
    itemProps: {
      type: Function,
      default: (item) => ({ item })
    },
    subItemSelector: {
      type: String
    },
    groupSize: {
      type: Number,
      default: 4
    },
    groupRows: {
      type: Number,
      default: 1
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {currentIndex: this.index}
  },
  watch: {
    // items () {
    //   this.$nextTick(this.initItems)
    // },
    // index () {
    //   this.goTo(this.index)
    // }
  },
  computed: {

    enabled () {
      if (this.$mq === undefined || !this.$mq.md) return false
      return this.$mq.md
    },

    groups () {
      if (this.loading) return [Array(this.groupSize).fill({})] // Return placeholders if we're loading
      const groups = chunkArray(this.items, this.groupSize)
      return groups.map(group => chunkArray(group, this.groupSize / this.groupRows))
    },

    slideCount () {
      return Math.ceil(this.items.length / this.groupSize)
    },

    // hasPrevious () {
    //   return this.currentIndex > 0
    // },

    // hasNext () {
    //   return this.currentIndex < this.slideCount - 1
    // }
  },
  methods: {

    // async next () {
    //   if (this.hasNext) {
    //     this.goTo(this.currentIndex + 1)
    //     return
    //   } 
    //   this.playEndAnimation()
    // },

    // async previous () {
    //   if (this.hasPrevious) {
    //     this.goTo(this.currentIndex - 1)
    //     return
    //   } 
    //   this.playStartAnimation()
    // },

    // async goTo (index) {

    //   // Disabled.
    //   if (!this.enabled) return

    //   // Make sure we don't go past the first or last slide
    //   let newIndex = Math.max(Math.min(index, this.slideCount - 1), 0)
    //   const isNewIndex = newIndex !== this.currentIndex
    //   if (isNewIndex) await this.playAnimation(this.currentIndex, newIndex)

    // },

    // getAllItems () {
    //   return [...this.$el.querySelectorAll('.item')]
    // },

    // getSlideItems (index) {
    //   let start = index * this.groupSize
    //   return this.getAllItems().slice(start, start + this.groupSize)
    // },

    // getAllAnimatableItems () {
    //   if (!this.subItemSelector) return this.getAllItems()
    //   return [...this.$el.querySelectorAll(this.subItemSelector)]
    // },

    /**
     * Get the elements with the slide that can be used in the stagger animation
     */
    // getAnimatableItems (index) {
    //   if (!this.subItemSelector) return this.getSlideItems(index)
    //   let items = []
    //   for (const item of this.getSlideItems(index)) {
    //     items = [...items, ...item.querySelectorAll(this.subItemSelector)]
    //   }
    //   return items.filter(el => !!el)
    // },

    /**
     * Get width of a slide in the carousel
     */
    // getSlideWidth () {
    //   const slide = this.$el.querySelector('.slide')
    //   return slide ? slide.offsetWidth : 0
    // },

    /**
     * Get the width of each item in the carousel
     */
    // getItemWidth () {
    //   const item = this.$el.querySelector('.item')
    //   return item ? item.offsetWidth : 0
    // },

    /**
     * Play animation between two slide indexes
     */
    // playAnimation (from, to) {
    //   if (this.isAnimating) return

    //   const x = to * this.getSlideWidth() * -1
    //   const dir = from > to ? -1 : 1

    //   let fromItems = this.getAnimatableItems(from)
    //   let toItems = this.getAnimatableItems(to)

    //   let totalTime = 1 // (1 + (toItems.length * 0.1)) * 0.75
    //   let baseTranslate = this.getItemWidth() * 0.75
    //   let staggerTranslate = this.getSlideWidth() / this.groupSize

    //   if (from > to) toItems = toItems.reverse()

    //   this.isAnimating = true

    //   const tl = new TimelineLite({
    //     onComplete: () => {
    //       this.currentIndex = to
    //       this.isAnimating = false
    //     }
    //   })
    //   .to(this.$el, totalTime, { x, ease: Power3.easeInOut })

    //   toItems.forEach((el, idx) => {
    //     const ease = Power3.easeInOut
    //     const x = (baseTranslate + (staggerTranslate * idx)) * dir
    //     tl.fromTo(el, totalTime, { autoAlpha: 0, x }, { autoAlpha: 1, x: 0, ease }, 0)
    //   })

    //   tl.fromTo(fromItems, 0.35, { autoAlpha: 1 }, { autoAlpha: 0 }, '-=0.45')
    // },

    /**
     * Play animation when the user tries to the the
     * previous slide when already on the first slide
     */
    // playStartAnimation () {
    //   if (this.isAnimating) {
    //     return
    //   }

    //   this.isAnimating = true

    //   new TimelineLite({
    //     onComplete: () => this.isAnimating = false
    //   })
    //     .to(this.$el, 0.25, { x: 50, ease: Power2.easeOut })
    //     .to(this.$el, 0.5, { x: 0, ease: Back.easeOut })
    // },

    /**
     * Play animation when the user tries to the the
     * next slide when already on the last slide
     */
    // playEndAnimation () {
    //   if (this.isAnimating) return
    //   const initial = (this.slideCount - 1) * this.getSlideWidth() * -1
    //   const to = initial - 50

    //   this.isAnimating = true

    //   new TimelineLite({
    //     onComplete: () => this.isAnimating = false
    //   })
    //     .to(this.$el, 0.25, { x: to, ease: Power2.easeOut })
    //     .to(this.$el, 0.5, { x: initial, ease: Back.easeOut })
    // },

    // initItems () {
    //   const isDisabled = !this.enabled
    //   if (isDisabled) return
    //   TweenLite.set(this.getAllAnimatableItems(), { autoAlpha: 0 })
    //   TweenLite.set(this.getAnimatableItems(this.currentIndex), { autoAlpha: 1 })
    // }
    
  },
  // mounted () {
  //   this.initItems()
  // }
}

</script>

<style lang="scss" scoped>
.tile-carousel {
  position: relative;
}

.slides {
  @include make-row();
  flex-wrap: wrap;
}

.slide {
  @include make-col-ready();
  @include make-col(12);
  + .slide {
    margin-top: 2rem;
  }
}

.items {
  @include make-row();
  flex-wrap: nowrap;

  + .items {
    margin-top: $grid-gutter-width;
  }
}

.item {
  @include make-col-ready();
}

.groups--2 .item {
  @include make-col(6);
}

.groups--3 .item {
  @include make-col(4);
}

.groups--4 .item {
  @include make-col(3);
}

.placeholder {
  padding-top: 100%;
  background-color: rgba(#000, 0.01);
}

.scrollable {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.pane {

}

.pane-items {
  @include make-custom-row($grid-gutter-width-sm);
  flex-wrap: nowrap;

  .item {
    @include make-custom-col-ready($grid-gutter-width-sm);
    @include make-col(8);
    @include media-breakpoint-up(sm) {
      @include make-col(5);
    }
  }
}
</style>
