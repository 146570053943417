<template>
  <article v-if="page" class="home-page">
    <Hero ref="hero">
      <div slot="media" class="media">
        <Slideshow :images="page.acf.slideshow_images" />
      </div>

      <div slot class="main">
        <Logo color="#CA5421" />
        <div class="headline" v-html="page.acf.headline" />
        <a
          v-if="page.acf.about_page"
          :href="page.acf.about_page"
          class="about">Learn more</a>
      </div>
    </Hero>
    <FlexibleContent :content="page.acf.blocks" />
  </article>
</template>

<script>
import PageMixin from './PageMixin'
import Logo from '../components/Logo'
import Hero from '../components/Hero'
import Slideshow from '../components/Slideshow'
import FlexibleContent from '../components/FlexibleContent'

export default {
  name: 'HomePage',
  mixins: [PageMixin],
  components: { Logo, Hero, Slideshow, FlexibleContent }
}
</script>

<style lang="scss" scoped>
.home-page {

}

.hero /deep/ .media {
  height: 360px;
  position: absolute;

  @include media-breakpoint-up(md) {
    height: 100vh;
  }
}

.media,
.slideshow {
  width: 100%;
  height: 100%;
}

.main {
  margin: 80px 0;
  text-align: center;

  @include media-breakpoint-up(md) {
    max-width: 460px;
    margin: $page-margin-top auto;
    text-align: left;
  }
}

.logo {
  width: 100%;
  max-width: 200px;
  margin-bottom: 2rem;

  @include media-breakpoint-up(md) {
    max-width: 300px;
  }

  @include media-breakpoint-up(md) {
    max-width: 380px;
  }
}

.headline {
  @include headline();
  margin-bottom: 1.5rem;
}

.about {
  @include arrow-button($desert);
}
</style>
