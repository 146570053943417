<template>
  <CarouselFrame class="speakers-carousel" :title="title" :carousel="$refs.carousel">
    <TileCarousel ref="carousel" :items="items" :groupSize="groupSize" :groupRows="groupRows">
      <SpeakerTile slot-scope="item" :speaker="item.item" />
    </TileCarousel>
  </CarouselFrame>
</template>

<script>
import TileCarousel from '../TileCarousel'
import CarouselFrame from '../CarouselFrame'
import SpeakerTile from './SpeakerTile'

export default {
  name: 'SpeakersCarousel',
  components: { TileCarousel, CarouselFrame, SpeakerTile },
  props: {
    title: {
      type: String,
      default: 'Speakers'
    },
    speakers: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      groupSize: 8,
      groupRows: 2,
      items: []
    }
  },
  watch: {
    speakers: 'fetch'
  },
  methods: {
    async fetch () {
      this.items = await this.$store.dispatch('hydratePosts', {
        type: 'speakers',
        ids: this.speakers
      })
    }
  },
  created () {
    this.fetch()
  }
}
</script>
