// import "core-js"
import '@babel/polyfill'

import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import VueAnalytics from 'vue-analytics'
import smoothscroll from 'smoothscroll-polyfill'
import MQ from 'vue-match-media/dist'
import 'whatwg-fetch'
import 'url-search-params-polyfill'
import 'es6-object-assign/auto'
// import "core-js/es"

require('gsap/TimelineLite')
require('gsap/CSSPlugin')
require('gsap/ScrollToPlugin')
require('gsap/ColorPropsPlugin')

import makeRouter from './router'
import store from './store'
import WordpressPlugin from './plugins/Wordpress'
import Preload from './directives/Preload'
import ArrowButton from './directives/ArrowButton'
import App from './App'

// Bootstrapped data
const {
  pages, rewrites, global, primaryMenu, socialMenu, footerMenu
} = window.apiData

const router = makeRouter(pages, rewrites)

smoothscroll.polyfill()

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(WordpressPlugin)
Vue.use(Preload)
Vue.use(ArrowButton)
Vue.use(MQ)

/**
 * google an
 * old code - UA-38492883-1 - no controll over
 * new ID UA-146496506-1 - use production@gdayusa.org to log in - not in 1 pass
 * https://analytics.google.com/analytics/web/?authuser=1#/a146496506w208836318p201219602/admin/tracking/tracking-code/
 */
// setting google
const GOOGLE_CODE = 'UA-146496506-1'

Vue.use(VueAnalytics, {
  id: GOOGLE_CODE, // old
  router
})

store.commit('receivePosts', { pages })
store.commit('receiveGlobal', global)
store.commit('receiveMenus', {
  primary: primaryMenu,
  social: socialMenu,
  footer: footerMenu
})

new Vue({
  store,
  router,
  render: h => h(App),
  mq: {
    xs: '(min-width: 0px)',
    sm: '(min-width: 576px)',
    smd: '(min-width: 720px)',
    md: '(min-width: 1084px)',
    lg: '(min-width: 1400px)',
    xl: '(min-width: 1700px)',
    mobile: '(max-width: 719px)',
    desktop: '(min-width: 720px)'
  },
  methods: {
    async preload () {
      // Preload some common data collections
      const types = ['themes', 'cities', 'tiers', 'sponsors']
      for (const type of types) {
        await store.dispatch('fetchPosts', { type, collection: type })
      }

      // Prime the identy map with other useful data
      await store.dispatch('fetchPosts', { type: 'champions' })
      await store.dispatch('fetchPosts', { type: 'events' })

      // Prime the photos and videos page
      await store.dispatch('getPhotosAndVideos', {
        page: pages.find(p => p.template === 'media')
      })
    }
  },
  async mounted () {
    this.preload()
  }
}).$mount('#app')
