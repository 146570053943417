<template>
  <Hero class="event-hero">
    <div slot="media" class="image">
      <img
        :src="$wp.thumbnail(event, 'large')"
        :alt="event.title.rendered"
        v-preload />

      <div v-if="shortDate" class="short-date">{{ shortDate }}</div>
    </div>

    <div slot class="main">
      <p class="themes">
        <span
          class="theme"
          v-for="term in $wp.terms(event, 'theme')"
          :key="term.id"
          v-html="term.name" />
      </p>

      <h1 class="title" v-html="event.title.rendered"></h1>

      <p class="meta">
        <time v-if="date" class="date" :datetime="date" v-html="displayDate"></time>
        <span v-if="displayLocation" class="location" v-html="displayLocation"></span>
      </p>

      <div v-if="event.acf.buttons" class="buttons">
        <a
          v-for="button in event.acf.buttons"
          :key="button.link"
          :href="button.link"
          v-html="button.text"
          class="event-button" />
      </div>

      <div class="body" v-html="event.content.rendered"></div>

      <div v-if="event.acf.tickets_info" class="info tickets">
        <h4 class="info-title">Tickets</h4>
        <div class="info-body" v-arrow-button v-html="event.acf.tickets_info"></div>
      </div>

      <div v-if="event.acf.accommodation_info" class="info accommodation">
        <h4 class="info-title">Accommodation</h4>
        <div class="info-body" v-arrow-button v-html="event.acf.accommodation_info"></div>
      </div>

      <div v-if="sponsors" class="spnsrs">
        <h4 class="spnsr-title">Event sponsors</h4>
        <div class="spnsr-grid">
          <div
            class="spnsr-item"
            :class="sponsor.acf.logo_orientation"
            v-for="sponsor in sponsors"
            :key="sponsor.id">
            <a
              :href="sponsor.acf.website"
              class="spnsr">
              <!-- <div class="ratio"></div> -->
              <img
                :src="$wp.partnerThumbnail(sponsor)"
                :alt="sponsor.title.rendered"
                class="spnsr-logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </Hero>
</template>

<script>
import Hero from '../Hero'

export default {
  name: 'EventHero',
  components: { Hero },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      sponsors: null
    }
  },
  watch: {
    event: 'fetch'
  },
  computed: {
    date () {
      return this.event.acf.start_time
    },

    displayDate () {
      const { display_date, start_time } = this.event.acf
      if (display_date) {
        return display_date
      } else {
        // TODO: may need multiple formats (eg, multi day, or all day, or set time, etc)
        return this.$wp.displayDate(start_time, 'cccc, LLL d')
      }
    },

    displayLocation () {
      return (this.event.acf.location || '').split('\n').join('<br />')
    },

    shortDate () {
      const { display_date, start_time, end_time } = this.event.acf
      if (display_date) {
        return display_date
      } else if (start_time && end_time) {
        return [
          this.$wp.displayDate(start_time, 'LLL d'),
          this.$wp.displayDate(end_time, 'd')
        ].join('-')
      } else if (start_time) {
        return this.$wp.displayDate(start_time, 'LLL d')
      }
    }
  },
  methods: {

    // get sponsors
    async fetch () {
      const { sponsors } = this.event.acf // grab the sponsors id for order
      // if we have sponsors fetch them
      if (sponsors) {

        // set up vars
        let sponsorsFilledInOrder = [] // all sponsors in order
        let sponsorsFilled = await this.$store.dispatch('hydratePosts', { // get sponsors - these arn't inorder
          type: 'sponsors',
          ids: sponsors
        })

        // filter sponsors
        sponsors.forEach((sponsorId) => {
          let found = sponsorsFilled.find((sponsorFilled) => sponsorFilled.id === sponsorId )
          if (found !== undefined && found !== null) {
            sponsorsFilledInOrder.push(found)
          }
        })

        // assign ordered sponsors into local state
        this.sponsors = sponsorsFilledInOrder
      }
    }
  },

  // on create
  created () {
    this.fetch()
  }
}
</script>

<style lang="scss" scoped>
.event-hero {
  background-color: #fff;

  /deep/ .media {
    height: 500px;

    @include media-breakpoint-up(md) {
      height: 100vh;
    }
  }
}

.image {
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.1);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.short-date {
  position: absolute;
  top: 100%;
  left: ($grid-gutter-width * 0.5);
  transform: translate(0, -50%);
  max-width: 180px;
  padding: 1rem;
  z-index: 10;
  line-height: 1;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  background-color: $pearl;
  box-shadow: 5px 5px 0 $body-color;

  @include media-breakpoint-up(md) {
    display: none;
  }

  @include media-breakpoint-up(xl) {
    display: block;
    top: 0;
    right: 0;
    left: auto;
    transform: translate(50%, 280px);
    font-size: 1.625rem;
  }
}

.main {
  /* width: 100%; */
  /* background: red; */
  margin-top: 64px;
  margin-bottom: 64px;

  @include media-breakpoint-up(md) {
    max-width: 460px;
    margin: $page-margin-top auto;
  }
}

.themes {
  margin-bottom: 1rem;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  color: rgba($body-color, 0.4);

  .theme {
    display: inline-block;
    margin-right: 0.5rem;
  }
}

.title {
  margin-bottom: 1rem;
  line-height: 1;
  font-size: 1.625rem;
  font-weight: 500;

  @include media-breakpoint-up(sm) {
    font-size: 2.875rem;
  }
}

.date,
.location {
  display: block;
}

.event-button {
  @include arrow-button();
  margin-bottom: 0.75rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.body {
  margin-top: 2.5rem;
}

.info {
  margin-top: 2.5rem;
}

.info-title {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
}

.info-body {
  /deep/ .arrow-button {
    @include arrow-button();
    font-size: 0.85rem;
  }
}

/**
 * Note: Don't use "sponsor" in a class name as
 * ad blockers will hide the element.
 */

.spnsrs {
  margin-top: 2.5rem;
  overflow: hidden;
}

.spnsr-title {
  margin-bottom: 0.25rem;
  font-size: 1rem;
  font-weight: 600;
}

.spnsr-grid {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* @include make-custom-row(1rem);
  justify-content: center; */
  .spnsr-item {
    width: 50%;
    overflow: hidden;
    @media (max-width: 1084px) { width: calc(100% / 3); }
    @media (max-width: 800px) { width: calc(100% / 2); }
    @media (max-width: 500px) { width: 100%; }
    img {
      width: 100%;
      transition: transform 0.35s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

/* .spnsr-item { */
  /* @include make-custom-col-ready(1rem);
  @include make-col(6);

  @include media-breakpoint-up(sm) {
    @include make-col(3);
  }

  @include media-breakpoint-up(md) {
    @include make-col(4);
  }

  @include media-breakpoint-up(xl) {
    @include make-col(4);
  }

  &.landscape {
    @include media-breakpoint-up(md) {
      @include make-col(8);

      .spnsr .ratio {
        padding-top: 50%;
      }
    }
  }
} */

/* .spnsr {
  display: block;
  position: relative;
  transition: transform 0.35s;

  .ratio {
    padding-top: 100%;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.spnsr-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 90%;
  max-height: 60%;
  transform: translate(-50%, -50%);
} */
</style>
